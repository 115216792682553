import React, { useEffect } from "react"
import { navigate } from "gatsby"
import app from "gatsby-plugin-firebase-v9.0"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const PublicRoute = ({ component: Component, location, ...rest }) => {
  const auth = getAuth(app)
  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    if (isBrowser) {
      onAuthStateChanged(auth, user => {
        if (!user) {
          navigate("/auth/signin")
          return null
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])

  return <Component {...rest} />
}

export default PublicRoute
