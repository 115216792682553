import React, { useEffect, useRef } from "react"
import app from "gatsby-plugin-firebase-v9.0"
import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
} from "firebase/firestore"
import { navigate } from "gatsby"
import ProTable from "@ant-design/pro-table"
import { Button, Modal, notification, Popconfirm, Table } from "antd"
import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons"
import moment from "moment"
import styled from "styled-components"

const InfoWrapper = styled.div`
  p {
    font-family: "Montserrat", "IBM Plex Sans Thai", sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 0;
    span {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
    }
  }
`

const LogsPage = () => {
  const db = getFirestore(app)
  const isBrowser = typeof window !== "undefined"
  const tableRef = useRef(null)

  useEffect(() => {
    if (isBrowser) {
      const storageUser = JSON.parse(
        localStorage.getItem("firebaseLocalStorage")
      )
      if (storageUser?.role !== "system_admin") {
        navigate("/backend/dashboard")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleModal = async (e, record) => {
    e.preventDefault()
    let uid = 0
    if (record?.uid && record?.by === "System") {
      uid = record.uid
    } else {
      uid = record.by
    }
    const docRef = doc(db, "users", uid)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      const data = docSnap.data()
      const fullName = data?.firstName + " " + data?.lastName
      Modal.info({
        title: "Log Details",
        content: (
          <InfoWrapper>
            <p>
              <span>By:</span> {fullName}
            </p>
            <p>
              <span>Department:</span>{" "}
              {data?.department ? data?.department : ""}
            </p>
            <p>
              <span>Type:</span> {record?.type}
            </p>
            <p>
              <span>Action:</span> {record?.action}
            </p>
            {record?.file ? (
              <p>
                <span>File:</span> {record?.file}
              </p>
            ) : null}
            <p>
              <span>When:</span> {record?.createdAt}
            </p>
          </InfoWrapper>
        ),
        centered: true,
        closable: true,
      })
    }
  }
  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      filters: true,
      onFilter: true,
      ellipsis: true,
      valueType: "select",
      valueEnum: {
        Authentication: {
          text: "Authentication",
        },
        Profile: {
          text: "Profile",
        },
        Users: {
          text: "Users",
        },
        Projects: {
          text: "Projects",
        },
        Documents: {
          text: "Documents",
        },
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      filters: true,
      onFilter: true,
      ellipsis: true,
      valueType: "select",
      valueEnum: {
        Create: {
          text: "Create",
        },
        Update: {
          text: "Update",
        },
        Upload: {
          text: "Upload",
        },
        Done: {
          text: "Done",
        },
        Extend: {
          text: "Extend",
        },
        Delete: {
          text: "Delete",
        },
        SignIn: {
          text: "SignIn",
        },
      },
    },
    {
      title: "When",
      dataIndex: "createdAt",
      hideInSearch: true,
      align: "right",
    },
    {
      title: "Details",
      valueType: "option",
      width: 80,
      align: "center",
      fixed: "right",
      render: (_, record) => {
        return (
          <a key="modal" href="#modal" onClick={e => handleModal(e, record)}>
            <InfoCircleOutlined />
          </a>
        )
      },
    },
  ]

  async function fetchCollection(params) {
    let result = []
    try {
      if (isBrowser) {
        let q = null
        if (
          params?.type &&
          params?.type?.length > 0 &&
          params?.action &&
          params?.action?.length > 0
        ) {
          q = query(
            collection(db, "logs"),
            where("type", "==", params.type),
            where("action", "==", params.action)
          )
        } else if (params?.type && params?.type?.length > 0) {
          q = query(collection(db, "logs"), where("type", "==", params.type))
        } else if (params?.action && params?.action?.length > 0) {
          q = query(
            collection(db, "logs"),
            where("action", "==", params.action)
          )
        } else {
          q = query(collection(db, "logs"), orderBy("createdAt", "desc"))
        }
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach(doc => {
          const data = doc.data()
          const createdAt = data?.createdAt?.toDate()
          const displayDateTime = moment(createdAt).format(
            "ddd DD MMM YYYY, HH:mm"
          )
          const append = {
            id: doc.id,
            uid: data?.uid,
            by: data?.by,
            type: data?.type,
            action: data?.action,
            file: data?.file,
            createdAt: displayDateTime,
          }
          result.push(append)
        })
      }
    } catch (error) {
      console.log("error ", error)
      notification["error"]({
        message: error.code || error.name || error.status,
        description: error.error_description || error.message,
        duration: 0,
      })
      result = []
    }
    return result
  }

  const handleDelete = async rows => {
    for (let index = 0; index < rows.length; index++) {
      const data = rows[index]
      if (data) {
        const id = data.id
        await deleteDoc(doc(db, "logs", id))
      }
    }
    tableRef.current.reload()
  }

  return (
    <ProTable
      actionRef={tableRef}
      columns={columns}
      rowSelection={{
        selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
      }}
      tableAlertRender={({ selectedRows }) => {
        if (
          selectedRows &&
          selectedRows.length > 0 &&
          selectedRows[0] !== undefined
        ) {
          let rowHtml = "Record"
          if (selectedRows.length > 1) {
            rowHtml = "Records"
          }

          return (
            <Popconfirm
              key="popconfirm"
              placement="topRight"
              title={`Are you sure to delete record? This action cannot undo!`}
              okText="Ok"
              cancelText="Cancel"
              onConfirm={() => handleDelete(selectedRows)}
            >
              <Button type="primary" danger>
                Delete {selectedRows.length} {rowHtml}
                &nbsp;&nbsp;
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          )
        } else {
          return null
        }
      }}
      request={async params => {
        const dataSource = await fetchCollection(params)
        return {
          data: dataSource,
          success: true,
        }
      }}
      rowKey="id"
      search={{
        labelWidth: "auto",
      }}
      toolbar={{
        title: "Logs",
      }}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dateFormatter="string"
    />
  )
}

export default LogsPage
