import React from "react"
import { navigate } from "gatsby"
import { Bar } from "@ant-design/plots"
import { numberWithCommas } from "../../utils/helper"

const FinishedMainChargedBarChart = ({ data }) => {
  const config = {
    theme: {
      styleSheet: {
        fontFamily: "'IBM Plex Sans Thai', sans-serif",
      },
    },
    data,
    isGroup: true,
    xField: "value",
    xAxis: {
      label: {
        formatter: o => {
          return numberWithCommas(o)
        },
      },
    },
    yField: "label",
    color: ["#6B145D", "#C09339", "#597ef7"],
    seriesField: "type",
    marginRatio: 0,
    label: {
      position: "middle",
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
      formatter: o => {
        return numberWithCommas(o.value)
      },
    },
    tooltip: {
      customContent: (title, items) => {
        const data1 = items[0]?.data || {}
        const data2 = items[1]?.data || {}
        const data3 = items[2]?.data || {}
        if (data1 || data2 || data3) {
          const project = `<p style="margin-bottom: 10px;">${data1?.projectName}</p>`
          let html1 = ""
          if (data1?.type) {
            const name1 = data1?.type
            const value1 = numberWithCommas(data1?.value)
            html1 = `<p style="margin-bottom: 5px;">${name1}: <strong>${value1}</strong></p>`
          }
          let html2 = ""
          if (data2?.type) {
            const name2 = data2?.type
            const value2 = numberWithCommas(data2?.value)
            html2 = `<p style="margin-bottom: 5px;">${name2}: <strong>${value2}</strong></p>`
          }
          let html3 = ""
          if (data3?.type) {
            const name3 = data3?.type
            const value3 = numberWithCommas(data3?.value)
            html3 = `<p style="margin-bottom: 5px;">${name3}: <strong>${value3}</strong></p>`
          }
          return `<div style="padding: 20px 10px;">${project}${html1}${html2}${html3}</div>`
        }
        return ""
      },
    },
    legend: {
      custom: true,
      position: "top",
      items: [
        {
          value: "period",
          name: "วงเงินทั้งหมด",
          marker: {
            symbol: "square",
            style: {
              fill: "#6B145D",
              r: 5,
            },
          },
        },
        {
          value: "charged",
          name: "เรียกเก็บแล้ว",
          marker: {
            symbol: "square",
            style: {
              fill: "#C09339",
              r: 5,
            },
          },
        },
        {
          value: "balance",
          name: "คงเหลือ",
          marker: {
            symbol: "square",
            style: {
              fill: "#597ef7",
              r: 5,
            },
          },
        },
      ],
    },
    onReady: plot => {
      plot.on("element:click", (...args) => {
        if (args) {
          const data = args[0]?.data?.data
          navigate(`/backend/projects/view/${data.projectId}`)
        }
      })
    },
  }
  return <Bar {...config} />
}

export default FinishedMainChargedBarChart
