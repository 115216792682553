import React, { useEffect } from "react"
import { navigate } from "gatsby"
import app from "gatsby-plugin-firebase-v9.0"
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth"
import { getFirestore, doc, getDoc } from "firebase/firestore"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const auth = getAuth(app)
  const db = getFirestore(app)
  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    if (isBrowser) {
      onAuthStateChanged(auth, user => {
        if (user) {
          if (user?.disabled || user?.disabled === `true`) {
            logoutUser()
            return null
          } else {
            fetchUser(user.uid)
          }
        } else {
          localStorage.removeItem("firebaseLocalStorage")
          navigate("/auth/signin")
          return null
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])

  const logoutUser = async () => {
    if (isBrowser) {
      signOut(auth)
        .then(() => {
          localStorage.removeItem("firebaseLocalStorage")
          navigate("/auth/signin")
        })
        .catch(error => {
          console.log("Failed to logout user: %o", error)
        })
    }
  }

  async function fetchUser(uid) {
    const docRef = doc(db, "users", uid)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      const data = docSnap.data()
      const role = data?.role ? data?.role : null
      const department = data?.department ? data?.department : null
      const storageUser = {
        uid: uid,
        email: data?.email,
        firstName: data?.firstName,
        lastName: data?.lastName,
        role: role,
        department: department,
      }
      localStorage.setItem("firebaseLocalStorage", JSON.stringify(storageUser))
      if (role && role !== "subscriber") {
        if (role !== "system_admin") {
          navigate("/backend/dashboard")
          return null
        }
      } else {
        await logoutUser()
        return null
      }
    } else {
      await logoutUser()
      return null
    }
  }

  return <Component {...rest} />
}

export default PrivateRoute
