import React from "react"
import { navigate } from "gatsby"
import { Bar } from "@ant-design/plots"

const ProjectProgressingStatusBarChart = ({ data }) => {
  const config = {
    theme: {
      styleSheet: {
        fontFamily: "'IBM Plex Sans Thai', sans-serif",
      },
    },
    data,
    isGroup: true,
    xField: "value",
    yField: "label",
    color: ["#6B145D", "#C09339"],
    seriesField: "type",
    marginRatio: 0,
    label: {
      position: "middle",
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
      formatter: o => {
        return `${o.value}%`
      },
    },
    meta: {
      value: {
        min: 0,
        max: 100,
      },
    },
    tooltip: {
      customContent: (title, items) => {
        const data1 = items[0]?.data || {}
        const data2 = items[1]?.data || {}
        if (data1 || data2) {
          const project = `<p style="margin-bottom: 10px;">${data1.projectName}</p>`
          let html1 = ""
          if (data1?.type) {
            const name1 = data1.type
            const value1 = data1.value
            html1 = `<p style="margin-bottom: 5px;">${name1}: <strong>${value1}</strong>%</p>`
          }
          let html2 = ""
          if (data2?.type) {
            const name2 = data2.type
            const value2 = data2.value
            html2 = `<p style="margin-bottom: 5px;">${name2}: <strong>${value2}</strong>%</p>`
          }
          return `<div style="padding: 20px 10px;">${project}${html1}${html2}</div>`
        }
        return ""
      },
    },
    legend: {
      custom: true,
      position: "top",
      items: [
        {
          value: "progress",
          name: "สถานะความก้าวหน้างานติดตั้ง",
          marker: {
            symbol: "square",
            style: {
              fill: "#6B145D",
              r: 5,
            },
          },
        },
        {
          value: "status",
          name: "สถานะการจัดเก็บเอกสาร",
          marker: {
            symbol: "square",
            style: {
              fill: "#C09339",
              r: 5,
            },
          },
        },
      ],
    },
    onReady: plot => {
      plot.on("element:click", (...args) => {
        if (args) {
          const data = args[0]?.data?.data
          navigate(`/backend/projects/view/${data.projectId}`)
        }
      })
    },
  }
  return <Bar {...config} />
}

export default ProjectProgressingStatusBarChart
