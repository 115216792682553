import React, { useEffect, useState } from "react"
import app from "gatsby-plugin-firebase-v9.0"
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth"
import {
  getFirestore,
  collection,
  doc,
  addDoc,
  getDoc,
  setDoc,
  Timestamp,
} from "firebase/firestore"
import { Link, navigate } from "gatsby"
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  notification,
  Popconfirm,
  Row,
  Select,
} from "antd"
import { DeleteOutlined } from "@ant-design/icons"
import styled from "styled-components"
const config = require("../../../../config/site")

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-item: center;
  justify-content: center;
  margin-top: 30px;
  > a {
    margin-right: 20px;
  }
`

const UserUID = ({ uid }) => {
  const auth = getAuth(app)
  const db = getFirestore(app)
  const isBrowser = typeof window !== "undefined"
  const [form] = Form.useForm()
  const [processing, setProcessing] = useState(false)
  const [showDept, setShowDept] = useState(false)
  const [deptOptions, setDeptOptions] = useState([])
  const [canProcess, setCanProcess] = useState(false)

  useEffect(() => {
    if (isBrowser && uid) {
      onAuthStateChanged(auth, user => {
        if (user) {
          if (user?.disabled || user?.disabled === `true`) {
            logoutUser()
            return null
          } else {
            fetchUser(user.uid)
          }
        } else {
          localStorage.removeItem("firebaseLocalStorage")
          navigate("/auth/signin")
          return null
        }
      })
    } else {
      navigate("/backend/dashboard")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])

  async function fetchUser(uid) {
    const docRef = doc(db, "users", uid)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      const data = docSnap.data()
      const role = data?.role ? data?.role : null
      const department = data?.department ? data?.department : null
      const storageUser = {
        uid: uid,
        email: data?.email,
        firstName: data?.firstName,
        lastName: data?.lastName,
        role: role,
        department: department,
      }
      localStorage.setItem("firebaseLocalStorage", JSON.stringify(storageUser))
      if (role && role !== "subscriber") {
        if (role === "system_admin") {
          setCanProcess(true)
          fetchDocument()
        } else {
          setCanProcess(false)
          navigate("/backend/dashboard")
        }
      } else {
        await logoutUser()
        return null
      }
    } else {
      await logoutUser()
      return null
    }
  }

  const logoutUser = async () => {
    if (isBrowser) {
      signOut(auth)
        .then(() => {
          localStorage.removeItem("firebaseLocalStorage")
          navigate("/auth/signin")
        })
        .catch(error => {
          console.log("Failed to logout user: %o", error)
        })
    }
  }

  async function fetchDocument() {
    const docRef = doc(db, "users", uid)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      const data = docSnap.data()
      if (
        data?.role === "general_user" ||
        data?.role === "dept_user" ||
        data?.role === "system_admin"
      ) {
        const defaultOptions = [
          {
            value: "",
            label: "Please Choose",
          },
          {
            value: "อก.",
            label: "อก.",
          },
          {
            value: "รก.",
            label: "รก.",
          },
          {
            value: "ชก.",
            label: "ชก.",
          },
          {
            value: "RA",
            label: "Renewable Energy Management Section 1(RA)",
          },
          {
            value: "RB",
            label: "Renewable Energy Management Section 2(RB)",
          },
          {
            value: "RC",
            label:
              "Renewable Energy and Energy Conservation Business Service Section(RC)",
          },
          {
            value: "EC",
            label: "Energy Conservation Management Section(EC)",
          },
          {
            value: "BF",
            label: "Business Contract and Finance Administration Section(BF)",
          },
        ]
        setDeptOptions(defaultOptions)
        setShowDept(true)
        form.setFieldValue(
          "department",
          data?.department ? data?.department : ""
        )
      }
      form.setFieldsValue({
        first_name: data?.firstName || ``,
        last_name: data?.lastName || ``,
        email: data?.email || ``,
        line: data?.line || ``,
        role: data?.role || `general_user`,
        createdAt: data?.createdAt || ``,
      })
    }
  }

  const handleFinish = async values => {
    if (isBrowser && canProcess) {
      setProcessing(true)
      try {
        const { first_name, last_name, line, role, department, email } = values
        const dataUser = {
          firstName: first_name,
          lastName: last_name,
          line: line,
          role: role,
          department:
            role === "general_user" ||
            role === "dept_user" ||
            role === "system_admin"
              ? department
                ? department
                : ""
              : "",
        }
        const docRef = doc(db, "users", uid)
        await setDoc(docRef, dataUser, { merge: true })
        const storageUser = JSON.parse(
          localStorage.getItem("firebaseLocalStorage")
        )
        const dataLog = {
          uid: uid,
          type: "Users",
          action: "Update",
          by: storageUser?.uid,
          createdAt: Timestamp.now(),
        }
        await addDoc(collection(db, "logs"), dataLog)
        // Send mail to user
        const dataMail = {
          to: email,
          message: {
            subject: "Your user was updated from Solar e-Doc",
            html: `Sign in to use Solar e-Doc Web application, by <a href="${config.siteUrl}/auth/signin" target="_blank"><strong>Click Here</strong></a>`,
          },
        }
        await addDoc(collection(db, "mail"), dataMail)
        message.success("Success")
        setProcessing(false)
      } catch (error) {
        console.log("error ", error)
        notification["error"]({
          message: error.code || error.name || error.status,
          description: error.error_description || error.message,
          duration: 0,
        })
        setProcessing(false)
      }
    }
  }

  const handleChangeRole = value => {
    if (
      value === "general_user" ||
      value === "dept_user" ||
      value === "system_admin"
    ) {
      const defaultOptions = [
        {
          value: "",
          label: "Please Choose",
        },
        {
          value: "อก.",
          label: "อก.",
        },
        {
          value: "รก.",
          label: "รก.",
        },
        {
          value: "ชก.",
          label: "ชก.",
        },
        {
          value: "RA",
          label: "Renewable Energy Management Section 1(RA)",
        },
        {
          value: "RB",
          label: "Renewable Energy Management Section 2(RB)",
        },
        {
          value: "RC",
          label:
            "Renewable Energy and Energy Conservation Business Service Section(RC)",
        },
        {
          value: "EC",
          label: "Energy Conservation Management Section(EC)",
        },
        {
          value: "BF",
          label: "Business Contract and Finance Administration Section(BF)",
        },
      ]
      setDeptOptions(defaultOptions)
      setShowDept(true)
    } else {
      setDeptOptions([])
      setShowDept(false)
    }
  }

  const confirmRemove = async () => {
    if (isBrowser && canProcess && uid) {
      try {
        const dataUser = {
          deleted: true,
        }
        const docRef = doc(db, "users", uid)
        await setDoc(docRef, dataUser, { merge: true })
        const storageUser = JSON.parse(
          localStorage.getItem("firebaseLocalStorage")
        )
        const dataLog = {
          uid: uid,
          type: "Users",
          action: "Delete",
          by: storageUser?.uid,
          createdAt: Timestamp.now(),
        }
        await addDoc(collection(db, "logs"), dataLog)
        message.success("The user was deleted.")
        navigate("/backend/users")
      } catch (error) {
        console.log("error ", error)
        notification["error"]({
          message: error.code || error.name || error.status,
          description: error.error_description || error.message,
          duration: 0,
        })
      }
    }
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleFinish}
      initialValues={{ role: "general_user" }}
    >
      <Card bordered={false}>
        <Row gutter={24}>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item name="first_name" label="First Name">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item name="last_name" label="Last Name">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item name="line" label="Line">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              name="role"
              label="Role"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                options={[
                  {
                    value: "subscriber",
                    label: "Subscriber",
                  },
                  {
                    value: "general_user",
                    label: "General User",
                  },
                  {
                    value: "dept_user",
                    label: "Dept. User",
                  },
                  {
                    value: "system_admin",
                    label: "System Admin",
                  },
                ]}
                onChange={handleChangeRole}
              />
            </Form.Item>
          </Col>
          {showDept ? (
            <Col xs={24} sm={12} lg={8}>
              <Form.Item name="department" label="Department">
                <Select style={{ width: "100%" }} options={deptOptions} />
              </Form.Item>
            </Col>
          ) : null}
        </Row>
        <Row gutter={[24, 36]}>
          <Col span={24}>
            <Form.Item>
              <ButtonWrapper>
                <Link to="/backend/users">
                  <Button>Cancel</Button>
                </Link>
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={processing}
                  disabled={processing}
                >
                  Submit
                </Button>
                <Popconfirm
                  key="popconfirm"
                  placement="topRight"
                  title={`Are you sure to remove?`}
                  okText="Ok"
                  cancelText="Cancel"
                  onConfirm={confirmRemove}
                >
                  <a
                    href="#void"
                    style={{
                      position: "absolute",
                      right: 0,
                      bottom: 0,
                      marginRight: 0,
                    }}
                  >
                    <DeleteOutlined />
                  </a>
                </Popconfirm>
              </ButtonWrapper>
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </Form>
  )
}

export default UserUID
