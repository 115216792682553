import React, { useEffect, useState } from "react"
import app from "gatsby-plugin-firebase-v9.0"
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth"
import {
  getFirestore,
  getCountFromServer,
  doc,
  getDoc,
  addDoc,
  collection,
  query,
  Timestamp,
  where,
} from "firebase/firestore"
import { Link, navigate } from "gatsby"
import { dateFormat } from "../../../utils/helper"
import moment from "moment"
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  notification,
  Row,
  Select,
} from "antd"
import styled from "styled-components"

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-item: center;
  justify-content: center;
  margin-top: 30px;
  > a {
    margin-right: 20px;
  }
`

const CreateProject = () => {
  const auth = getAuth(app)
  const db = getFirestore(app)
  const isBrowser = typeof window !== "undefined"
  const storageUser = JSON.parse(localStorage.getItem("firebaseLocalStorage"))
  const [form] = Form.useForm()
  const [canProcess, setCanProcess] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [niy, setNiy] = useState("00")
  const currentYear = moment().format("YYYY")

  useEffect(() => {
    if (isBrowser) {
      onAuthStateChanged(auth, user => {
        if (user) {
          if (user?.disabled || user?.disabled === `true`) {
            logoutUser()
            return null
          } else {
            fetchUser(user.uid)
          }
        } else {
          localStorage.removeItem("firebaseLocalStorage")
          navigate("/auth/signin")
          return null
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])

  const logoutUser = async () => {
    if (isBrowser) {
      signOut(auth)
        .then(() => {
          localStorage.removeItem("firebaseLocalStorage")
          navigate("/auth/signin")
        })
        .catch(error => {
          console.log("Failed to logout user: %o", error)
        })
    }
  }

  async function fetchUser(uid) {
    const docRef = doc(db, "users", uid)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      const data = docSnap.data()
      const role = data?.role ? data?.role : null
      const department = data?.department ? data?.department : null
      const storageUser = {
        uid: uid,
        email: data?.email,
        firstName: data?.firstName,
        lastName: data?.lastName,
        role: role,
        department: department,
      }
      localStorage.setItem("firebaseLocalStorage", JSON.stringify(storageUser))
      if (role && role !== "subscriber") {
        if (role === "system_admin" || role === "dept_user") {
          setCanProcess(true)
          fetchCollection()
        } else {
          setCanProcess(false)
          navigate("/backend/dashboard")
        }
      } else {
        await logoutUser()
        return null
      }
    } else {
      await logoutUser()
      return null
    }
  }

  async function fetchCollection() {
    try {
      if (isBrowser) {
        const q = query(
          collection(db, "projects"),
          where("year", "==", currentYear)
        )
        const snapshot = await getCountFromServer(q)
        const count = snapshot.data().count
        if (count) {
          const order = count + 1
          if (order < 10) {
            setNiy(`0${order}`)
          } else {
            setNiy(order)
          }
        }
      }
    } catch (error) {
      console.log("error ", error)
      notification["error"]({
        message: error.code || error.name || error.status,
        description: error.error_description || error.message,
        duration: 0,
      })
    }
  }

  const handleFinish = async values => {
    if (isBrowser && canProcess) {
      setProcessing(true)
      try {
        const {
          project_name,
          short_name,
          project_code,
          kw,
          job_number,
          address,
          province,
          approval_date,
          main_date,
          customer,
          main_budget,
          main_period,
          main_current_period,
          main_amounts,
          main_charged_amounts,
          main_balance,
          property_ownership,
          subcontract_date,
          vender,
          subcontract_budget,
          subcontract_period,
          subcontract_current_period,
          subcontract_amounts,
          subcontract_charged_amounts,
          subcontract_balance,
          project_details,
        } = values

        let dataProject = {
          projectName: project_name,
          shortName: short_name,
          projectCode: project_code,
          kw: kw,
          jobNumber: job_number,
          address: address,
          province: province,
          customer: customer,
          mainBudget: main_budget,
          mainPeriod: main_period,
          mainCurrentPeriod: main_current_period ? main_current_period : "",
          mainAmounts: main_amounts ? main_amounts : "",
          mainChargedAmounts: main_charged_amounts ? main_charged_amounts : "",
          mainBalance: main_balance ? main_balance : "",
          propertyOwnership: property_ownership ? property_ownership : "",
          vender: vender ? vender : "",
          subcontractBudget: subcontract_budget ? subcontract_budget : "",
          subcontractPeriod: subcontract_period ? subcontract_period : "",
          subcontractCurrentPeriod: subcontract_current_period
            ? subcontract_current_period
            : "",
          subcontractAmounts: subcontract_amounts ? subcontract_amounts : "",
          subcontractChargedAmounts: subcontract_charged_amounts
            ? subcontract_charged_amounts
            : "",
          subcontractBalance: subcontract_balance ? subcontract_balance : "",
          projectDetails: project_details ? project_details : "",
          year: currentYear,
          finished: false,
        }

        if (approval_date) {
          dataProject.approvalDate = moment(approval_date).toDate()
        }

        if (main_date && main_date.length === 2) {
          const mainStartDate = moment(main_date[0]).toDate()
          const mainEndDate = moment(main_date[1]).toDate()
          dataProject.mainStartDate = mainStartDate
          dataProject.mainEndDate = mainEndDate
        }
        if (subcontract_date && subcontract_date.length === 2) {
          const subcontractStartDate = moment(subcontract_date[0]).toDate()
          const subcontractEndDate = moment(subcontract_date[1]).toDate()
          dataProject.subcontractStartDate = subcontractStartDate
          dataProject.subcontractEndDate = subcontractEndDate
        }
        const docRef = await addDoc(collection(db, "projects"), dataProject)
        const dataLog = {
          pid: docRef?.id,
          type: "Projects",
          action: "Create",
          by: storageUser?.uid,
          createdAt: Timestamp.now(),
        }
        await addDoc(collection(db, "logs"), dataLog)
        message.success("Success")
        setProcessing(false)
        navigate(`/backend/projects/${docRef?.id}`)
      } catch (error) {
        console.log("error ", error)
        notification["error"]({
          message: error.code || error.name || error.status,
          description: error.error_description || error.message,
          duration: 0,
        })
        setProcessing(false)
      }
    }
  }
  const genProjectCode = e => {
    const name = e.target.value
    if (name && name.length === 4) {
      const second = moment().format("YY")
      const code = `${name}-${second}-${niy}`
      form.setFieldValue("project_code", code)
    }
  }

  return (
    <Form form={form} layout="vertical" onFinish={handleFinish}>
      <Card bordered={false}>
        <Row gutter={24}>
          <Col xs={24} sm={12} lg={16}>
            <Form.Item
              name="project_name"
              label="ชื่อโครงการ"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={4}>
            <Form.Item
              name="short_name"
              label="รหัสย่อ"
              tooltip="ใส่ได้เฉพาะพยัญชนะ a-z, A-Z และตัวเลข 0-9 เท่านั้น"
              rules={[
                {
                  required: true,
                  message: "Required [a-zA-Z0-9]",
                  pattern: new RegExp(/^[a-zA-Z0-9]+$/i),
                },
                {
                  len: 4,
                  message: "Must be exactly 4 characters",
                },
              ]}
            >
              <Input maxLength={4} onChange={genProjectCode} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={4}>
            <Form.Item
              name="project_code"
              label="รหัสโครงการ"
              tooltip="Auto generate from Short Name with format XXXX-yy-00"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
                {
                  len: 10,
                  message: "Must be exactly 10 characters",
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              name="kw"
              label="kW ตามสัญญา (ตามอนุมัติให้บริการ)"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <InputNumber
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={value => value.replace(/\$\s?|(,*)/g, "")}
                style={{
                  width: "100%",
                }}
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item name="job_number" label="หมายเลขงาน">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              name="address"
              label="ที่อยู่"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Input.TextArea allowClear rows={2} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              name="province"
              label="จังหวัด"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                options={[
                  {
                    value: "",
                    label: "Please Choose",
                  },
                  {
                    value: "กรุงเทพมหานคร",
                    label: "กรุงเทพมหานคร",
                  },
                  {
                    value: "กระบี่",
                    label: "กระบี่",
                  },
                  {
                    value: "กาญจนบุรี",
                    label: "กาญจนบุรี",
                  },
                  {
                    value: "กาฬสินธุ์",
                    label: "กาฬสินธุ์",
                  },
                  {
                    value: "กำแพงเพชร",
                    label: "กำแพงเพชร",
                  },
                  {
                    value: "ขอนแก่น",
                    label: "ขอนแก่น",
                  },
                  {
                    value: "จันทบุรี",
                    label: "จันทบุรี",
                  },
                  {
                    value: "ฉะเชิงเทรา",
                    label: "ฉะเชิงเทรา",
                  },
                  {
                    value: "ชัยนาท",
                    label: "ชัยนาท",
                  },
                  {
                    value: "ชัยภูมิ",
                    label: "ชัยภูมิ",
                  },
                  {
                    value: "ชุมพร",
                    label: "ชุมพร",
                  },
                  {
                    value: "ชลบุรี",
                    label: "ชลบุรี",
                  },
                  {
                    value: "เชียงใหม่",
                    label: "เชียงใหม่",
                  },
                  {
                    value: "เชียงราย",
                    label: "เชียงราย",
                  },
                  {
                    value: "ตรัง",
                    label: "ตรัง",
                  },
                  {
                    value: "ตราด",
                    label: "ตราด",
                  },
                  {
                    value: "ตาก",
                    label: "ตาก",
                  },
                  {
                    value: "นครนายก",
                    label: "นครนายก",
                  },
                  {
                    value: "นครปฐม",
                    label: "นครปฐม",
                  },
                  {
                    value: "นครพนม",
                    label: "นครพนม",
                  },
                  {
                    value: "นครราชสีมา",
                    label: "นครราชสีมา",
                  },
                  {
                    value: "นครศรีธรรมราช",
                    label: "นครศรีธรรมราช",
                  },
                  {
                    value: "นครสวรรค์",
                    label: "นครสวรรค์",
                  },
                  {
                    value: "นราธิวาส",
                    label: "นราธิวาส",
                  },
                  {
                    value: "น่าน",
                    label: "น่าน",
                  },
                  {
                    value: "นนทบุรี",
                    label: "นนทบุรี",
                  },
                  {
                    value: "บึงกาฬ",
                    label: "บึงกาฬ",
                  },
                  {
                    value: "บุรีรัมย์",
                    label: "บุรีรัมย์",
                  },
                  {
                    value: "ประจวบคีรีขันธ์",
                    label: "ประจวบคีรีขันธ์",
                  },
                  {
                    value: "ปทุมธานี",
                    label: "ปทุมธานี",
                  },
                  {
                    value: "ปราจีนบุรี",
                    label: "ปราจีนบุรี",
                  },
                  {
                    value: "ปัตตานี",
                    label: "ปัตตานี",
                  },
                  {
                    value: "พะเยา",
                    label: "พะเยา",
                  },
                  {
                    value: "พระนครศรีอยุธยา",
                    label: "พระนครศรีอยุธยา",
                  },
                  {
                    value: "พังงา",
                    label: "พังงา",
                  },
                  {
                    value: "พิจิตร",
                    label: "พิจิตร",
                  },
                  {
                    value: "พิษณุโลก",
                    label: "พิษณุโลก",
                  },
                  {
                    value: "เพชรบุรี",
                    label: "เพชรบุรี",
                  },
                  {
                    value: "เพชรบูรณ์",
                    label: "เพชรบูรณ์",
                  },
                  {
                    value: "แพร่",
                    label: "แพร่",
                  },
                  {
                    value: "พัทลุง",
                    label: "พัทลุง",
                  },
                  {
                    value: "ภูเก็ต",
                    label: "ภูเก็ต",
                  },
                  {
                    value: "มหาสารคาม",
                    label: "มหาสารคาม",
                  },
                  {
                    value: "มุกดาหาร",
                    label: "มุกดาหาร",
                  },
                  {
                    value: "แม่ฮ่องสอน",
                    label: "แม่ฮ่องสอน",
                  },
                  {
                    value: "ยโสธร",
                    label: "ยโสธร",
                  },
                  {
                    value: "ยะลา",
                    label: "ยะลา",
                  },
                  {
                    value: "ร้อยเอ็ด",
                    label: "ร้อยเอ็ด",
                  },
                  {
                    value: "ระนอง",
                    label: "ระนอง",
                  },
                  {
                    value: "ระยอง",
                    label: "ระยอง",
                  },
                  {
                    value: "ราชบุรี",
                    label: "ราชบุรี",
                  },
                  {
                    value: "ลพบุรี",
                    label: "ลพบุรี",
                  },
                  {
                    value: "ลำปาง",
                    label: "ลำปาง",
                  },
                  {
                    value: "ลำพูน",
                    label: "ลำพูน",
                  },
                  {
                    value: "เลย",
                    label: "เลย",
                  },
                  {
                    value: "ศรีสะเกษ",
                    label: "ศรีสะเกษ",
                  },
                  {
                    value: "สกลนคร",
                    label: "สกลนคร",
                  },
                  {
                    value: "สงขลา",
                    label: "สงขลา",
                  },
                  {
                    value: "สมุทรสาคร",
                    label: "สมุทรสาคร",
                  },
                  {
                    value: "สมุทรปราการ",
                    label: "สมุทรปราการ",
                  },
                  {
                    value: "สมุทรสงคราม",
                    label: "สมุทรสงคราม",
                  },
                  {
                    value: "สระแก้ว",
                    label: "สระแก้ว",
                  },
                  {
                    value: "สระบุรี",
                    label: "สระบุรี",
                  },
                  {
                    value: "สิงห์บุรี",
                    label: "สิงห์บุรี",
                  },
                  {
                    value: "สุโขทัย",
                    label: "สุโขทัย",
                  },
                  {
                    value: "สุพรรณบุรี",
                    label: "สุพรรณบุรี",
                  },
                  {
                    value: "สุราษฎร์ธานี",
                    label: "สุราษฎร์ธานี",
                  },
                  {
                    value: "สุรินทร์",
                    label: "สุรินทร์",
                  },
                  {
                    value: "สตูล",
                    label: "สตูล",
                  },
                  {
                    value: "หนองคาย",
                    label: "หนองคาย",
                  },
                  {
                    value: "หนองบัวลำภู",
                    label: "หนองบัวลำภู",
                  },
                  {
                    value: "อำนาจเจริญ",
                    label: "อำนาจเจริญ",
                  },
                  {
                    value: "อุดรธานี",
                    label: "อุดรธานี",
                  },
                  {
                    value: "อุตรดิตถ์",
                    label: "อุตรดิตถ์",
                  },
                  {
                    value: "อุทัยธานี",
                    label: "อุทัยธานี",
                  },
                  {
                    value: "อุบลราชธานี",
                    label: "อุบลราชธานี",
                  },
                  {
                    value: "อ่างทอง",
                    label: "อ่างทอง",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item name="approval_date" label="วันอนุมัติให้บริการ">
              <DatePicker
                format={dateFormat}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              name="customer"
              label="ลูกค้า"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Divider orientation="left">สัญญาหลัก</Divider>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} lg={16}>
            <Form.Item name="main_date" label="เริ่ม-สิ้นสุด สัญญาหลัก">
              <DatePicker.RangePicker
                format={dateFormat}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              name="property_ownership"
              label="กรรมสิทธิ์ทรัพย์สินเมื่อครบกำหนดสัญญา"
            >
              <Select
                style={{ width: "100%" }}
                options={[
                  {
                    value: "",
                    label: "Please Choose",
                  },
                  {
                    value: "โอนกรรมสิทธิ์",
                    label: "โอนกรรมสิทธิ์",
                  },
                  {
                    value: "รื้อถอน",
                    label: "รื้อถอน",
                  },
                  {
                    value: "แล้วแต่ตกลง",
                    label: "แล้วแต่ตกลง",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              name="main_budget"
              label="วงเงินตามสัญญาหลัก (ไม่รวมภาษีมูลค่าเพิ่ม)"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <InputNumber
                addonAfter="฿"
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={value => value.replace(/\$\s?|(,*)/g, "")}
                style={{
                  width: "100%",
                }}
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              name="main_period"
              label="จำนวนงวด"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <InputNumber
                parser={value => value.replace(/\$\s?|(,*)/g, "")}
                style={{
                  width: "100%",
                }}
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item name="main_current_period" label="เก็บเงินงวดที่">
              <InputNumber
                parser={value => value.replace(/\$\s?|(,*)/g, "")}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item name="main_amounts" label="จำนวนเงิน">
              <InputNumber
                addonAfter="฿"
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={value => value.replace(/\$\s?|(,*)/g, "")}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item name="main_charged_amounts" label="รวมเก็บเงินแล้ว">
              <InputNumber
                addonAfter="฿"
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={value => value.replace(/\$\s?|(,*)/g, "")}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item name="main_balance" label="ยอดคงเหลือ">
              <InputNumber
                addonAfter="฿"
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={value => value.replace(/\$\s?|(,*)/g, "")}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Divider orientation="left">สัญญาช่วง</Divider>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} lg={16}>
            <Form.Item name="subcontract_date" label="เริ่ม-สิ้นสุด สัญญาช่วง">
              <DatePicker.RangePicker
                format={dateFormat}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item name="vender" label="บริษัทผู้รับจ้าง">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item name="subcontract_budget" label="วงเงินตามสัญญาช่วง">
              <InputNumber
                addonAfter="฿"
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={value => value.replace(/\$\s?|(,*)/g, "")}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item name="subcontract_period" label="จำนวนงวด">
              <InputNumber
                parser={value => value.replace(/\$\s?|(,*)/g, "")}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item name="subcontract_current_period" label="จ่ายเงินงวดที่">
              <InputNumber
                parser={value => value.replace(/\$\s?|(,*)/g, "")}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item name="subcontract_amounts" label="จำนวนเงิน">
              <InputNumber
                addonAfter="฿"
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={value => value.replace(/\$\s?|(,*)/g, "")}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              name="subcontract_charged_amounts"
              label="รวมจ่ายเงินแล้ว"
            >
              <InputNumber
                addonAfter="฿"
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={value => value.replace(/\$\s?|(,*)/g, "")}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item name="subcontract_balance" label="ยอดคงเหลือ">
              <InputNumber
                addonAfter="฿"
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={value => value.replace(/\$\s?|(,*)/g, "")}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="project_details" label="รายละเอียดโครงการ">
              <Input.TextArea allowClear rows={5} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 36]}>
          <Col span={24}>
            <Form.Item>
              <ButtonWrapper>
                <Link to="/backend/projects/all">
                  <Button type="secondary">Cancel</Button>
                </Link>
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={processing}
                  disabled={processing}
                >
                  Submit
                </Button>
              </ButtonWrapper>
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </Form>
  )
}

export default CreateProject
