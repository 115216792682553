import React, { useEffect, useState } from "react"
import { Router } from "@reach/router"
import { Link, navigate } from "gatsby"
import app from "gatsby-plugin-firebase-v9.0"
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth"
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  addDoc,
  getDocs,
  query,
  where,
  Timestamp,
} from "firebase/firestore"
import moment from "moment"
import ThemeContext from "../context/ThemeContext"
import ProLayout, { PageContainer } from "@ant-design/pro-layout"
import FooterDefault from "../components/Footer"
import PublicRoute from "../components/Backend/PublicRoute"
import PrivateRoute from "../components/Backend/PrivateRoute"
import Dashboard from "../components/Backend/Dashboard"
import ProjectsPage from "../components/Backend/projects/all"
import ProjectsProgressing from "../components/Backend/projects/progressing"
import ProjectsFinished from "../components/Backend/projects/finished"
import CreateProject from "../components/Backend/projects/create"
import ProjectID from "../components/Backend/projects/[id]"
import ProjectViewID from "../components/Backend/projects/view/[id]"
import Profile from "../components/Backend/Profile"
import LogsPage from "../components/Backend/Logs"
import UsersPage from "../components/Backend/users"
import UserUID from "../components/Backend/users/[uid]"
import logoBackend from "../../static/logo/logo-backend.svg"
import NoticeIconView from "../components/Backend/Notice"
import { Avatar, ConfigProvider, Dropdown, Menu, notification } from "antd"
import enUS from "antd/es/locale/en_US"
import options from "../../config/options"
import {
  DownOutlined,
  DashboardOutlined,
  LogoutOutlined,
  MonitorOutlined,
  ProjectOutlined,
  SettingOutlined,
  TeamOutlined,
  UserOutlined,
  ReadOutlined,
} from "@ant-design/icons"
import "antd/dist/antd.less"
import "../theme/backend.less"
const config = require("../../config/site")
const CronJob = require("cron").CronJob

const BackendLayout = () => {
  const auth = getAuth(app)
  const db = getFirestore(app)
  const [locale, setLocale] = useState(enUS)
  const isBrowser = typeof window !== "undefined"
  const [isAdmin, setIsAdmin] = useState(false)
  const [isDept, setIsDept] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)
  const [rightMenu, setRightMenu] = useState(null)
  const [defaultProps, setDefaultProps] = useState(null)
  let isMaad = false
  let lineNotify = []

  useEffect(() => {
    let isMounted = true
    if (isBrowser) {
      onAuthStateChanged(auth, user => {
        if (isMounted && user) {
          if (user?.disabled || user?.disabled === `true`) {
            notification["warning"]({
              message: "Warning",
              description: "Your account has been disabled!",
              duration: 0,
            })
            logoutUser()
          }
          fetchUser(user.uid)
          setRightMenu(
            <Menu>
              <Menu.Item key="profile" icon={<SettingOutlined />}>
                <Link to="/backend/profile">Profile</Link>
              </Menu.Item>
              <Menu.Divider key="divider" />
              <Menu.Item key="logout" danger icon={<LogoutOutlined />}>
                <a
                  href="#logout"
                  onClick={async e => {
                    e.preventDefault()
                    await logoutUser()
                  }}
                >
                  Sign Out
                </a>
              </Menu.Item>
            </Menu>
          )
        } else {
          localStorage.removeItem("firebaseLocalStorage")
          navigate("/auth/signin")
          return null
        }
      })
    } else {
      notification["error"]({
        message: "Error",
        description: "Undefined window!",
        duration: 0,
      })
      navigate("/")
      return null
    }
    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, setRightMenu, setDefaultProps])

  async function fetchUserDepartmentIds(dept) {
    try {
      lineNotify = []
      const result = []
      const q = query(collection(db, "users"), where("department", "in", dept))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach(doc => {
        const data = doc.data()
        if (data?.uid) {
          result.push(data.uid)
          if (data?.lineToken) {
            lineNotify.push(data.lineToken)
          }
        }
      })
      return result
    } catch (error) {
      console.log("error fetchUserDepartmentIds ", error)
      lineNotify = []
      return []
    }
  }
  const postLineNotify = async data => {
    try {
      return new Promise(function (resolve, reject) {
        const req = new XMLHttpRequest()
        req.open("POST", options.workerLineNotify)
        req.setRequestHeader("Content-Type", "text/plain")
        req.onload = function () {
          if (req.status === 200) {
            resolve(req.response)
          } else {
            reject(0)
          }
        }
        req.send(`${JSON.stringify(data)}`)
      })
    } catch (error) {
      console.log("postLineNotify error ", error)
      return new Promise(function (reject) {
        reject(0)
      })
    }
  }
  const notificationSchedule = async () => {
    if (isMaad) {
      const currentDate = moment().startOf("day")
      try {
        const q = query(collection(db, "projects"))
        const querySnapshot = await getDocs(q)
        const tmpCollection = []
        querySnapshot.forEach(doc => {
          tmpCollection.push(doc.data())
        })
        if (tmpCollection.length > 0) {
          for (let index = 0; index < tmpCollection.length; index++) {
            const project = tmpCollection[index]
            // AC
            if (
              project.acDocument?.notification2 &&
              project.acDocument?.notification2.length > 0 &&
              project.acDocument?.calendar2?.start &&
              project.acDocument?.calendar2?.end
            ) {
              const notiDiff = currentDate.diff(
                moment(project.acDocument?.calendar2?.start.toDate()).startOf(
                  "day"
                ),
                "days"
              )
              if (notiDiff >= -3 && notiDiff < 0) {
                const uids = await fetchUserDepartmentIds(
                  project.acDocument?.notification2
                )
                if (uids && uids.length > 0) {
                  const noticeTitle = `Please prepare to upload ACyy00-02 การทำสัญญาให้บริการ(จ้างหลัก) for ${project?.projectCode}`
                  // Email
                  const dataMail = {
                    toUids: uids,
                    message: {
                      subject: noticeTitle,
                      html: `Sign in to use Solar e-Doc Web application, by <a href="${config.siteUrl}/auth/signin" target="_blank"><strong>Click Here</strong></a>`,
                    },
                  }
                  await addDoc(collection(db, "mail"), dataMail)
                  // Line
                  if (lineNotify && lineNotify.length > 0) {
                    for (let i = 0; i < lineNotify.length; i++) {
                      const token = lineNotify[i]
                      if (token) {
                        const formData = {
                          token: token,
                          message: noticeTitle,
                        }
                        await postLineNotify(formData)
                      }
                    }
                  }
                  // Inbox
                  for (let j = 0; j < uids.length; j++) {
                    const userId = uids[j]
                    if (userId) {
                      const dataNotice = {
                        uid: userId,
                        title: noticeTitle,
                        read: false,
                        createdAt: Timestamp.now(),
                      }
                      await addDoc(collection(db, "notifications"), dataNotice)
                    }
                  }
                }
              }
            }
            if (
              project.acDocument?.notification3 &&
              project.acDocument?.notification3.length > 0 &&
              project.acDocument?.calendar3?.start &&
              project.acDocument?.calendar3?.end
            ) {
              const notiDiff = currentDate.diff(
                moment(project.acDocument?.calendar3?.start.toDate()).startOf(
                  "day"
                ),
                "days"
              )
              if (notiDiff >= -3 && notiDiff < 0) {
                const uids = await fetchUserDepartmentIds(
                  project.acDocument?.notification3
                )
                if (uids && uids.length > 0) {
                  const noticeTitle = `Please prepare to upload ACyy00-03 Owner ส่งมอบพื้นที่ให้ PEA for ${project?.projectCode}`
                  // Email
                  const dataMail = {
                    toUids: uids,
                    message: {
                      subject: noticeTitle,
                      html: `Sign in to use Solar e-Doc Web application, by <a href="${config.siteUrl}/auth/signin" target="_blank"><strong>Click Here</strong></a>`,
                    },
                  }
                  await addDoc(collection(db, "mail"), dataMail)
                  // Line
                  if (lineNotify && lineNotify.length > 0) {
                    for (let i = 0; i < lineNotify.length; i++) {
                      const token = lineNotify[i]
                      if (token) {
                        const formData = {
                          token: token,
                          message: noticeTitle,
                        }
                        await postLineNotify(formData)
                      }
                    }
                  }
                  // Inbox
                  for (let j = 0; j < uids.length; j++) {
                    const userId = uids[j]
                    if (userId) {
                      const dataNotice = {
                        uid: userId,
                        title: noticeTitle,
                        read: false,
                        createdAt: Timestamp.now(),
                      }
                      await addDoc(collection(db, "notifications"), dataNotice)
                    }
                  }
                }
              }
            }
            if (
              project.acDocument?.notification6 &&
              project.acDocument?.notification6.length > 0 &&
              project.acDocument?.calendar6?.start &&
              project.acDocument?.calendar6?.end
            ) {
              const notiDiff = currentDate.diff(
                moment(project.acDocument?.calendar6?.start.toDate()).startOf(
                  "day"
                ),
                "days"
              )
              if (notiDiff >= -3 && notiDiff < 0) {
                const uids = await fetchUserDepartmentIds(
                  project.acDocument?.notification6
                )
                if (uids && uids.length > 0) {
                  const noticeTitle = `Please prepare to upload ACyy00-06 จัดทำและขออนุมัติ TOR แต่งตั้งผู้ควบคุมงาน (ภายในกองและคนที่กฟข.) for ${project?.projectCode}`
                  // Email
                  const dataMail = {
                    toUids: uids,
                    message: {
                      subject: noticeTitle,
                      html: `Sign in to use Solar e-Doc Web application, by <a href="${config.siteUrl}/auth/signin" target="_blank"><strong>Click Here</strong></a>`,
                    },
                  }
                  await addDoc(collection(db, "mail"), dataMail)
                  // Line
                  if (lineNotify && lineNotify.length > 0) {
                    for (let i = 0; i < lineNotify.length; i++) {
                      const token = lineNotify[i]
                      if (token) {
                        const formData = {
                          token: token,
                          message: noticeTitle,
                        }
                        await postLineNotify(formData)
                      }
                    }
                  }
                  // Inbox
                  for (let j = 0; j < uids.length; j++) {
                    const userId = uids[j]
                    if (userId) {
                      const dataNotice = {
                        uid: userId,
                        title: noticeTitle,
                        read: false,
                        createdAt: Timestamp.now(),
                      }
                      await addDoc(collection(db, "notifications"), dataNotice)
                    }
                  }
                }
              }
            }
            // VP
            // notify before 30 days
            if (
              project.vpDocument?.notification5 &&
              project.vpDocument?.notification5.length > 0 &&
              project.vpDocument?.calendar5?.start &&
              project.vpDocument?.calendar5?.end
            ) {
              const notiDiff = currentDate.diff(
                moment(project.vpDocument?.calendar5?.start.toDate()).startOf(
                  "day"
                ),
                "days"
              )
              if (notiDiff >= -30 && notiDiff < 0) {
                const uids = await fetchUserDepartmentIds(
                  project.vpDocument?.notification5
                )
                if (uids && uids.length > 0) {
                  const noticeTitle = `Please prepare to upload VPyy00-05 e-LG for ${project?.projectCode}`
                  // Email
                  const dataMail = {
                    toUids: uids,
                    message: {
                      subject: noticeTitle,
                      html: `Sign in to use Solar e-Doc Web application, by <a href="${config.siteUrl}/auth/signin" target="_blank"><strong>Click Here</strong></a>`,
                    },
                  }
                  await addDoc(collection(db, "mail"), dataMail)
                  // Line
                  if (lineNotify && lineNotify.length > 0) {
                    for (let i = 0; i < lineNotify.length; i++) {
                      const token = lineNotify[i]
                      if (token) {
                        const formData = {
                          token: token,
                          message: noticeTitle,
                        }
                        await postLineNotify(formData)
                      }
                    }
                  }
                  // Inbox
                  for (let j = 0; j < uids.length; j++) {
                    const userId = uids[j]
                    if (userId) {
                      const dataNotice = {
                        uid: userId,
                        title: noticeTitle,
                        read: false,
                        createdAt: Timestamp.now(),
                      }
                      await addDoc(collection(db, "notifications"), dataNotice)
                    }
                  }
                }
              }
            }
            // notify before 30 days
            if (
              project.vpDocument?.notification6 &&
              project.vpDocument?.notification6.length > 0 &&
              project.vpDocument?.calendar6?.start &&
              project.vpDocument?.calendar6?.end
            ) {
              const notiDiff = currentDate.diff(
                moment(project.vpDocument?.calendar6?.start.toDate()).startOf(
                  "day"
                ),
                "days"
              )
              if (notiDiff >= -30 && notiDiff < 0) {
                const uids = await fetchUserDepartmentIds(
                  project.vpDocument?.notification6
                )
                if (uids && uids.length > 0) {
                  const noticeTitle = `Please prepare to upload VPyy00-06 กรมธรรม์ for ${project?.projectCode}`
                  // Email
                  const dataMail = {
                    toUids: uids,
                    message: {
                      subject: noticeTitle,
                      html: `Sign in to use Solar e-Doc Web application, by <a href="${config.siteUrl}/auth/signin" target="_blank"><strong>Click Here</strong></a>`,
                    },
                  }
                  await addDoc(collection(db, "mail"), dataMail)
                  // Line
                  if (lineNotify && lineNotify.length > 0) {
                    for (let i = 0; i < lineNotify.length; i++) {
                      const token = lineNotify[i]
                      if (token) {
                        const formData = {
                          token: token,
                          message: noticeTitle,
                        }
                        await postLineNotify(formData)
                      }
                    }
                  }
                  // Inbox
                  for (let j = 0; j < uids.length; j++) {
                    const userId = uids[j]
                    if (userId) {
                      const dataNotice = {
                        uid: userId,
                        title: noticeTitle,
                        read: false,
                        createdAt: Timestamp.now(),
                      }
                      await addDoc(collection(db, "notifications"), dataNotice)
                    }
                  }
                }
              }
            }
            if (
              project.vpDocument?.notification7 &&
              project.vpDocument?.notification7.length > 0 &&
              project.vpDocument?.calendar7?.start &&
              project.vpDocument?.calendar7?.end
            ) {
              const notiDiff = currentDate.diff(
                moment(project.vpDocument?.calendar7?.start.toDate()).startOf(
                  "day"
                ),
                "days"
              )
              if (notiDiff >= -3 && notiDiff < 0) {
                const uids = await fetchUserDepartmentIds(
                  project.vpDocument?.notification7
                )
                if (uids && uids.length > 0) {
                  const noticeTitle = `Please prepare to upload VPyy00-07 ทำสัญญาจ้างกับ Vender for ${project?.projectCode}`
                  // Email
                  const dataMail = {
                    toUids: uids,
                    message: {
                      subject: noticeTitle,
                      html: `Sign in to use Solar e-Doc Web application, by <a href="${config.siteUrl}/auth/signin" target="_blank"><strong>Click Here</strong></a>`,
                    },
                  }
                  await addDoc(collection(db, "mail"), dataMail)
                  // Line
                  if (lineNotify && lineNotify.length > 0) {
                    for (let i = 0; i < lineNotify.length; i++) {
                      const token = lineNotify[i]
                      if (token) {
                        const formData = {
                          token: token,
                          message: noticeTitle,
                        }
                        await postLineNotify(formData)
                      }
                    }
                  }
                  // Inbox
                  for (let j = 0; j < uids.length; j++) {
                    const userId = uids[j]
                    if (userId) {
                      const dataNotice = {
                        uid: userId,
                        title: noticeTitle,
                        read: false,
                        createdAt: Timestamp.now(),
                      }
                      await addDoc(collection(db, "notifications"), dataNotice)
                    }
                  }
                }
              }
            }
            // EC
            if (
              project.ecDocument?.notification1 &&
              project.ecDocument?.notification1.length > 0 &&
              project.ecDocument?.calendar1?.start &&
              project.ecDocument?.calendar1?.end
            ) {
              const notiDiff = currentDate.diff(
                moment(project.ecDocument?.calendar1?.start.toDate()).startOf(
                  "day"
                ),
                "days"
              )
              if (notiDiff >= -3 && notiDiff < 0) {
                const uids = await fetchUserDepartmentIds(
                  project.ecDocument?.notification1
                )
                if (uids && uids.length > 0) {
                  const noticeTitle = `Please prepare to upload ECyy00-01 การส่งมอบพื้นที่ให้ Vender for ${project?.projectCode}`
                  // Email
                  const dataMail = {
                    toUids: uids,
                    message: {
                      subject: noticeTitle,
                      html: `Sign in to use Solar e-Doc Web application, by <a href="${config.siteUrl}/auth/signin" target="_blank"><strong>Click Here</strong></a>`,
                    },
                  }
                  await addDoc(collection(db, "mail"), dataMail)
                  // Line
                  if (lineNotify && lineNotify.length > 0) {
                    for (let i = 0; i < lineNotify.length; i++) {
                      const token = lineNotify[i]
                      if (token) {
                        const formData = {
                          token: token,
                          message: noticeTitle,
                        }
                        await postLineNotify(formData)
                      }
                    }
                  }
                  // Inbox
                  for (let j = 0; j < uids.length; j++) {
                    const userId = uids[j]
                    if (userId) {
                      const dataNotice = {
                        uid: userId,
                        title: noticeTitle,
                        read: false,
                        createdAt: Timestamp.now(),
                      }
                      await addDoc(collection(db, "notifications"), dataNotice)
                    }
                  }
                }
              }
            }
            if (
              project.ecDocument?.notification3 &&
              project.ecDocument?.notification3.length > 0 &&
              project.ecDocument?.calendar3?.start &&
              project.ecDocument?.calendar3?.end
            ) {
              const notiDiff = currentDate.diff(
                moment(project.ecDocument?.calendar3?.start.toDate()).startOf(
                  "day"
                ),
                "days"
              )
              if (notiDiff >= -3 && notiDiff < 0) {
                const uids = await fetchUserDepartmentIds(
                  project.ecDocument?.notification3
                )
                if (uids && uids.length > 0) {
                  const noticeTitle = `Please prepare to upload ECyy00-03 Vender ส่งรายการวัสดุอุปกรณ์ และ shop drawing ให้ กฟภ. อนุมัติ (ตามลิสต์ตอนเสนอราคา) for ${project?.projectCode}`
                  // Email
                  const dataMail = {
                    toUids: uids,
                    message: {
                      subject: noticeTitle,
                      html: `Sign in to use Solar e-Doc Web application, by <a href="${config.siteUrl}/auth/signin" target="_blank"><strong>Click Here</strong></a>`,
                    },
                  }
                  await addDoc(collection(db, "mail"), dataMail)
                  // Line
                  if (lineNotify && lineNotify.length > 0) {
                    for (let i = 0; i < lineNotify.length; i++) {
                      const token = lineNotify[i]
                      if (token) {
                        const formData = {
                          token: token,
                          message: noticeTitle,
                        }
                        await postLineNotify(formData)
                      }
                    }
                  }
                  // Inbox
                  for (let j = 0; j < uids.length; j++) {
                    const userId = uids[j]
                    if (userId) {
                      const dataNotice = {
                        uid: userId,
                        title: noticeTitle,
                        read: false,
                        createdAt: Timestamp.now(),
                      }
                      await addDoc(collection(db, "notifications"), dataNotice)
                    }
                  }
                }
              }
            }
            if (
              project.ecDocument?.notification11 &&
              project.ecDocument?.notification11.length > 0 &&
              project.ecDocument?.calendar11?.start &&
              project.ecDocument?.calendar11?.end
            ) {
              const notiDiff = currentDate.diff(
                moment(project.ecDocument?.calendar11?.start.toDate()).startOf(
                  "day"
                ),
                "days"
              )
              if (notiDiff >= -30 && notiDiff < 0) {
                const uids = await fetchUserDepartmentIds(
                  project.ecDocument?.notification11
                )
                if (uids && uids.length > 0) {
                  const noticeTitle = `Please prepare to upload ECyy00-11 ใบอนุญาต for ${project?.projectCode}`
                  // Email
                  const dataMail = {
                    toUids: uids,
                    message: {
                      subject: noticeTitle,
                      html: `Sign in to use Solar e-Doc Web application, by <a href="${config.siteUrl}/auth/signin" target="_blank"><strong>Click Here</strong></a>`,
                    },
                  }
                  await addDoc(collection(db, "mail"), dataMail)
                  // Line
                  if (lineNotify && lineNotify.length > 0) {
                    for (let i = 0; i < lineNotify.length; i++) {
                      const token = lineNotify[i]
                      if (token) {
                        const formData = {
                          token: token,
                          message: noticeTitle,
                        }
                        await postLineNotify(formData)
                      }
                    }
                  }
                  // Inbox
                  for (let j = 0; j < uids.length; j++) {
                    const userId = uids[j]
                    if (userId) {
                      const dataNotice = {
                        uid: userId,
                        title: noticeTitle,
                        read: false,
                        createdAt: Timestamp.now(),
                      }
                      await addDoc(collection(db, "notifications"), dataNotice)
                    }
                  }
                }
              }
            }
            // TC
            if (
              project.tcDocument?.notification4 &&
              project.tcDocument?.notification4.length > 0 &&
              project.tcDocument?.calendar4?.start &&
              project.tcDocument?.calendar4?.end
            ) {
              const notiDiff = currentDate.diff(
                moment(project.tcDocument?.calendar4?.start.toDate()).startOf(
                  "day"
                ),
                "days"
              )
              if (notiDiff >= -3 && notiDiff < 0) {
                const uids = await fetchUserDepartmentIds(
                  project.tcDocument?.notification4
                )
                if (uids && uids.length > 0) {
                  const noticeTitle = `Please prepare to upload TCyy00-04 กฟภ. ตรวจสอบหน่วย for ${project?.projectCode}`
                  // Email
                  const dataMail = {
                    toUids: uids,
                    message: {
                      subject: noticeTitle,
                      html: `Sign in to use Solar e-Doc Web application, by <a href="${config.siteUrl}/auth/signin" target="_blank"><strong>Click Here</strong></a>`,
                    },
                  }
                  await addDoc(collection(db, "mail"), dataMail)
                  // Line
                  if (lineNotify && lineNotify.length > 0) {
                    for (let i = 0; i < lineNotify.length; i++) {
                      const token = lineNotify[i]
                      if (token) {
                        const formData = {
                          token: token,
                          message: noticeTitle,
                        }
                        await postLineNotify(formData)
                      }
                    }
                  }
                  // Inbox
                  for (let j = 0; j < uids.length; j++) {
                    const userId = uids[j]
                    if (userId) {
                      const dataNotice = {
                        uid: userId,
                        title: noticeTitle,
                        read: false,
                        createdAt: Timestamp.now(),
                      }
                      await addDoc(collection(db, "notifications"), dataNotice)
                    }
                  }
                }
              }
            }
            // EB
            const ebDocumentPeriod1 = project.ebDocument?.period1
              ? project.ebDocument?.period1
              : []
            if (ebDocumentPeriod1 && ebDocumentPeriod1?.length > 0) {
              for (let p = 0; p < ebDocumentPeriod1.length; p++) {
                const item = ebDocumentPeriod1[p]
                const d = item.period_date
                const notiDiff = currentDate.diff(
                  moment(d.toDate()).startOf("day"),
                  "days"
                )
                if (notiDiff >= -3 && notiDiff < 0) {
                  const n = item.period_noti
                  const uids = await fetchUserDepartmentIds(n)
                  if (uids && uids.length > 0) {
                    const periodNo = p + 1
                    const noticeTitle = `Please prepare EByy00-01 กฟภ. ส่งใบแจ้งหนี้ให้ Owner (งวดที่ ${periodNo}) for ${project?.projectCode}`
                    // Email
                    const dataMail = {
                      toUids: uids,
                      message: {
                        subject: noticeTitle,
                        html: `Sign in to use Solar e-Doc Web application, by <a href="${config.siteUrl}/auth/signin" target="_blank"><strong>Click Here</strong></a>`,
                      },
                    }
                    await addDoc(collection(db, "mail"), dataMail)
                    // Line
                    if (lineNotify && lineNotify.length > 0) {
                      for (let i = 0; i < lineNotify.length; i++) {
                        const token = lineNotify[i]
                        if (token) {
                          const formData = {
                            token: token,
                            message: noticeTitle,
                          }
                          await postLineNotify(formData)
                        }
                      }
                    }
                    // Inbox
                    for (let j = 0; j < uids.length; j++) {
                      const userId = uids[j]
                      if (userId) {
                        const dataNotice = {
                          uid: userId,
                          title: noticeTitle,
                          read: false,
                          createdAt: Timestamp.now(),
                        }
                        await addDoc(
                          collection(db, "notifications"),
                          dataNotice
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } catch (error) {
        console.log("error noti ", error)
        notification["error"]({
          message: error.code || error.name || error.status,
          description: error.error_description || error.message,
          duration: 0,
        })
      }
    }
  }

  async function fetchUser(uid) {
    const docRef = doc(db, "users", uid)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      const data = docSnap.data()
      if (data?.deleted) {
        notification["warning"]({
          message: "Access Denied",
          description: "Your account was deleted!",
          duration: 0,
        })
        await logoutUser()
      } else {
        setCurrentUser(data)
        const role = data?.role ? data?.role : null
        const department = data?.department ? data?.department : null
        const storageUser = {
          uid: uid,
          email: data?.email,
          firstName: data?.firstName,
          lastName: data?.lastName,
          role: role,
          department: department,
        }
        localStorage.setItem(
          "firebaseLocalStorage",
          JSON.stringify(storageUser)
        )
        if (role && role !== "subscriber") {
          if (role === "system_admin") {
            setIsAdmin(true)
            setDefaultProps({
              route: {
                path: "/backend",
                routes: [
                  {
                    path: "/backend/dashboard",
                    name: "Dashboard",
                    icon: <DashboardOutlined />,
                  },
                  {
                    path: "/backend/projects",
                    name: "Projects",
                    icon: <ProjectOutlined />,
                    children: [
                      {
                        path: "/backend/projects/all",
                        name: "All Projects",
                        exact: true,
                      },
                      {
                        path: "/backend/projects/progressing",
                        name: "Progressing",
                        exact: true,
                      },
                      {
                        path: "/backend/projects/finished",
                        name: "Finished",
                        exact: true,
                      },
                      {
                        path: "/backend/projects/create",
                        name: "Create",
                        exact: true,
                      },
                    ],
                  },
                  {
                    name: "Users",
                    icon: <TeamOutlined />,
                    children: [
                      {
                        path: "/backend/users",
                        name: "All Users",
                        exact: true,
                      },
                    ],
                  },
                  {
                    path: "/backend/profile",
                    name: "Profile",
                    icon: <UserOutlined />,
                  },
                  {
                    path: "/backend/logs",
                    name: "Logs",
                    icon: <MonitorOutlined />,
                  },
                  {
                    path: "https://www.eqs.co.th/user-manual-pea-solar-e-doc/",
                    name: "User Manual",
                    icon: <ReadOutlined />,
                  },
                ],
              },
            })
            // Check is Maad for Remote server (Cron)
            if (data?.email === "maad@deeboon.com") {
              isMaad = true
              // Project notification schedule
              new CronJob(
                "00 00 08 * * *",
                notificationSchedule,
                null,
                true,
                "Asia/Bangkok"
              )
            } else {
              isMaad = false
            }
          } else {
            setIsAdmin(false)
            if (role === "dept_user") {
              setIsDept(true)
              setDefaultProps({
                route: {
                  path: "/backend",
                  routes: [
                    {
                      path: "/backend/dashboard",
                      name: "Dashboard",
                      icon: <DashboardOutlined />,
                    },
                    {
                      path: "/backend/projects",
                      name: "Projects",
                      icon: <ProjectOutlined />,
                      children: [
                        {
                          path: "/backend/projects/all",
                          name: "All Projects",
                          exact: true,
                        },
                        {
                          path: "/backend/projects/progressing",
                          name: "Progressing",
                          exact: true,
                        },
                        {
                          path: "/backend/projects/finished",
                          name: "Finished",
                          exact: true,
                        },
                        {
                          path: "/backend/projects/create",
                          name: "Create",
                          exact: true,
                        },
                      ],
                    },
                    {
                      path: "/backend/profile",
                      name: "Profile",
                      icon: <UserOutlined />,
                    },
                    {
                      path:
                        "https://www.eqs.co.th/user-manual-pea-solar-e-doc/",
                      name: "User Manual",
                      icon: <ReadOutlined />,
                    },
                  ],
                },
              })
            } else {
              setIsDept(false)
              setDefaultProps({
                route: {
                  path: "/backend",
                  routes: [
                    {
                      path: "/backend/dashboard",
                      name: "Dashboard",
                      icon: <DashboardOutlined />,
                    },
                    {
                      path: "/backend/projects",
                      name: "Projects",
                      icon: <ProjectOutlined />,
                      children: [
                        {
                          path: "/backend/projects/all",
                          name: "All Projects",
                          exact: true,
                        },
                        {
                          path: "/backend/projects/progressing",
                          name: "Progressing",
                          exact: true,
                        },
                        {
                          path: "/backend/projects/finished",
                          name: "Finished",
                          exact: true,
                        },
                      ],
                    },
                    {
                      path: "/backend/profile",
                      name: "Profile",
                      icon: <UserOutlined />,
                    },
                    {
                      path:
                        "https://www.eqs.co.th/user-manual-pea-solar-e-doc/",
                      name: "User Manual",
                      icon: <ReadOutlined />,
                    },
                  ],
                },
              })
            }
          }
        } else {
          notification["warning"]({
            message: "Access Denied",
            description: "Your account is unauthorized!",
            duration: 0,
          })
          await logoutUser()
        }
      }
    } else {
      notification["warning"]({
        message: "Access Denied",
        description: "Your account is unauthorized!",
        duration: 0,
      })
      await logoutUser()
    }
  }

  const logoutUser = async () => {
    if (isBrowser) {
      signOut(auth)
        .then(() => {
          localStorage.removeItem("firebaseLocalStorage")
          navigate("/auth/signin")
        })
        .catch(error => {
          notification["error"]({
            message: error.code || error.name || error.status,
            description: error.error_description || error.message,
            duration: 0,
          })
        })
    }
  }

  return (
    <ThemeContext.Consumer>
      {() => {
        const homePath = `/`

        return (
          <ConfigProvider locale={locale}>
            <ProLayout
              logo={logoBackend}
              title="EMD e-Doc"
              loading={false}
              siderWidth={240}
              {...defaultProps}
              onMenuHeaderClick={() => navigate(homePath)}
              menuItemRender={(menuItemProps, defaultDom) => {
                if (menuItemProps.isUrl || !menuItemProps.path) {
                  return defaultDom
                }

                return (
                  <Link key={menuItemProps.path} to={menuItemProps.path}>
                    {defaultDom}
                  </Link>
                )
              }}
              breadcrumbRender={(routers = []) => [
                {
                  path: homePath,
                  breadcrumbName: "Home",
                },
                ...routers,
              ]}
              itemRender={(route, params, routes, paths) => {
                const first = routes.indexOf(route) === 0
                return first ? (
                  <Link key={`home`} to={homePath}>
                    {route.breadcrumbName}
                  </Link>
                ) : (
                  <span key={route.path}>{route.breadcrumbName}</span>
                )
              }}
              footerRender={() => <FooterDefault />}
              rightContentRender={() => {
                return (
                  <>
                    <NoticeIconView />
                    <Dropdown
                      overlay={rightMenu}
                      trigger={["click"]}
                      placement="bottomRight"
                    >
                      <a
                        key="user"
                        className="userLinkWrapper"
                        href="#none"
                        onClick={e => e.preventDefault()}
                      >
                        <Avatar
                          size={22}
                          icon={<UserOutlined />}
                          style={{
                            color: "#fff",
                            backgroundColor: "#6B145D",
                          }}
                        />
                        <span className="nameOrEmail">
                          {currentUser?.firstName || currentUser?.email}
                        </span>
                        <DownOutlined />
                      </a>
                    </Dropdown>
                  </>
                )
              }}
            >
              <PageContainer>
                <Router basepath="/backend">
                  <PublicRoute path="/dashboard" component={Dashboard} />
                  <PublicRoute path="/projects/all" component={ProjectsPage} />
                  <PublicRoute
                    path="/projects/progressing"
                    component={ProjectsProgressing}
                  />
                  <PublicRoute
                    path="/projects/finished"
                    component={ProjectsFinished}
                  />
                  {isAdmin || isDept ? (
                    <>
                      <PublicRoute
                        path="/projects/create"
                        component={CreateProject}
                      />
                      <PublicRoute path="/projects/:id" component={ProjectID} />
                    </>
                  ) : null}
                  <PublicRoute
                    path="/projects/view/:id"
                    component={ProjectViewID}
                  />
                  <PublicRoute path="/profile" component={Profile} />
                  {isAdmin ? (
                    <>
                      <PrivateRoute path="/users" component={UsersPage} />
                      <PrivateRoute path="/users/:uid" component={UserUID} />
                      <PrivateRoute path="/logs" component={LogsPage} />
                    </>
                  ) : null}
                </Router>
              </PageContainer>
            </ProLayout>
          </ConfigProvider>
        )
      }}
    </ThemeContext.Consumer>
  )
}

export default BackendLayout
