export const getParameterByName = (name, url) => {
  if (typeof window !== "undefined") {
    url = window.location.href
    name = name.replace(/[\[\]]/g, "\\$&")
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ""

    return decodeURIComponent(results[2].replace(/\+/g, " "))
  } else {
    return false
  }
}
export const numberWithCommas = x => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  } else {
    return x
  }
}
export const numberToPay = x => {
  let returnObj = ""
  let removeReg = x.toString().replace(/[.,\s]/g, "")
  if (x.toString().indexOf(".") === -1) {
    returnObj = parseInt(removeReg) * 100
  } else {
    returnObj = removeReg
  }
  return returnObj
}
export const dateFormat = "YYYY-MM-DD"
export const dateFormatDisplay = "LL"
export const monthsThai = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
]
