import React from "react"
import { Bar } from "@ant-design/plots"
import { numberWithCommas } from "../../utils/helper"

const ProjectBudgetStacked = ({ data }) => {
  const config = {
    theme: {
      styleSheet: {
        fontFamily: "'Montserrat', 'IBM Plex Sans Thai', sans-serif",
      },
    },
    data,
    isStack: true,
    xField: "value",
    xAxis: {
      label: {
        formatter: o => {
          return numberWithCommas(o)
        },
      },
    },
    yField: "label",
    color: ["#6B145D", "#C09339"],
    seriesField: "type",
    marginRatio: 0,
    label: {
      position: "middle",
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
      formatter: o => {
        return numberWithCommas(o.value)
      },
    },
    tooltip: {
      customContent: (title, items) => {
        const data1 = items[0]?.data || {}
        const data2 = items[1]?.data || {}
        if (data1 || data2) {
          const project = `<p style="margin-bottom: 10px;">${title}</p>`
          let html1 = ""
          if (data1?.type) {
            const name1 = data1.type
            const value1 = numberWithCommas(data1.value)
            html1 = `<p style="margin-bottom: 5px;">${name1}: <strong>${value1}</strong></p>`
          }
          let html2 = ""
          if (data2?.type) {
            const name2 = data2.type
            const value2 = numberWithCommas(data2.value)
            html2 = `<p style="margin-bottom: 5px;">${name2}: <strong>${value2}</strong></p>`
          }
          return `<div style="padding: 20px 10px;">${project}${html1}${html2}</div>`
        }
        return ""
      },
    },
    legend: {
      custom: true,
      position: "bottom",
      items: [
        {
          value: "progress",
          name: "ระหว่างติดตั้ง",
          marker: {
            symbol: "square",
            style: {
              fill: "#6B145D",
              r: 5,
            },
          },
        },
        {
          value: "finished",
          name: "ระหว่างเรียกเก็บ",
          marker: {
            symbol: "square",
            style: {
              fill: "#C09339",
              r: 5,
            },
          },
        },
      ],
    },
  }
  return <Bar {...config} />
}

export default ProjectBudgetStacked
