import React, { useEffect, useState } from "react"
import app from "gatsby-plugin-firebase-v9.0"
import {
  getFirestore,
  collection,
  query,
  getDocs,
  orderBy,
  where,
} from "firebase/firestore"
import ProTable from "@ant-design/pro-table"
import { Link } from "gatsby"
import {
  Card,
  Col,
  DatePicker,
  Form,
  notification,
  Row,
  Tabs,
  Table,
  Tooltip,
} from "antd"
import {
  DownloadOutlined,
  ContainerOutlined,
  EditOutlined,
} from "@ant-design/icons"
import ProjectsProgressingBarChart from "./ProjectsProgressingBarChart"
import ProjectProgressingStatusBarChart from "./ProjectProgressingStatusBarChart"
import FinishedMainPeriodBarChart from "./FinishedMainPeriodBarChart"
import FinishedMainChargedBarChart from "./FinishedMainChargedBarChart"
import ProjectsPieChart from "./ProjectsPieChart"
import ProjectBudgetStacked from "./ProjectBudgetStacked"
import { dateFormat, numberWithCommas } from "../../utils/helper"
import moment from "moment"
import { CSVLink } from "react-csv"
import styled from "styled-components"

const { RangePicker } = DatePicker

const TabsWrapper = styled.div`
  .ant-tabs-nav {
    &::before {
      border-bottom: none !important;
    }
    .ant-tabs-tab {
      padding: 10px;
      color: #262626;
      background: #f0f0f0;
      border: 1px solid #f0f0f0;
      border-radius: 7px;
      .ant-tabs-tab-btn {
        color: #262626;
      }
      & + .ant-tabs-tab {
        margin-left: 20px !important;
      }
    }
    .ant-tabs-tab-active {
      color: #fff;
      background: #6b145d;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
      .ant-tabs-tab-btn {
        color: #fff;
      }
    }
    .ant-tabs-ink-bar {
      display: none !important;
    }
  }
`
const CardWrapper = styled(Card)`
  height: 100%;
  .title {
    font-family: "Montserrat", "IBM Plex Sans Thai", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 0;
  }
  .total {
    font-family: "Montserrat", "IBM Plex Sans Thai", sans-serif;
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .heading {
    font-family: "Montserrat", "IBM Plex Sans Thai", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #434343;
  }
`
const DatePickerWrapper = styled.div`
  position: relative;
  top: auto;
  right: auto;
  margin: 30px 0 20px 0;
  z-index: 1;
  .text {
    font-family: "Montserrat", "IBM Plex Sans Thai", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 5px;
    text-align: left;
  }

  @media only screen and (min-width: 768px) {
    position: absolute;
    top: -10px;
    right: 0;
    margin: 0;
    .text {
      text-align: right;
    }
  }
`

const Dashboard = () => {
  const db = getFirestore(app)
  const isBrowser = typeof window !== "undefined"
  const storageUser = JSON.parse(localStorage.getItem("firebaseLocalStorage"))
  const [totalProgressing, setTotalProgressing] = useState(0)
  const [totalFinished, setTotalFinished] = useState(0)
  const [totalMainBudget, setTotalMainBudget] = useState(0)
  const [barProgressData, setBarProgressData] = useState(null)
  const [barStatusData, setBarStatusData] = useState(null)
  const [progressSource, setProgressSource] = useState(null)
  const [totalProjects, setTotalProjects] = useState(0)
  const [pieProjectsData, setPieProjectsData] = useState(null)
  const [stackedProjectsData, setStackedProjectsData] = useState(null)
  const [form] = Form.useForm()

  const [mainBudgetFinished, setMainBudgetFinished] = useState(0)
  const [mainChargedAmountsFinished, setMainChargedAmountsFinished] = useState(
    0
  )
  const [subcontractBudgetFinished, setSubcontractBudgetFinished] = useState(0)
  const [
    subcontractChargedAmountsFinished,
    setSubcontractChargedAmountsFinished,
  ] = useState(0)

  const [barFinishedMainPeriodData, setBarFinishedMainPeriodData] = useState(
    null
  )
  const [barFinishedMainChargedData, setBarFinishedMainChargedData] = useState(
    null
  )
  const [
    barFinishedSubcontractPeriodData,
    setBarFinishedSubcontractPeriodData,
  ] = useState(null)
  const [
    barFinishedSubcontractChargedData,
    setBarFinishedSubcontractChargedData,
  ] = useState(null)
  const [finishedSource, setFinishedSource] = useState(null)
  const [tabActive, setTabActive] = useState("progressing")

  useEffect(() => {
    if (isBrowser) {
      fetchProgressingCollection()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const progressingColumns = [
    {
      title: "รหัสโครงการ",
      dataIndex: "projectCode",
      filters: true,
      sorter: false,
    },
    {
      title: "ชื่อโครงการ",
      dataIndex: "projectName",
      filters: true,
      sorter: false,
    },
    {
      title: "ความก้าวหน้างานติดตั้ง",
      dataIndex: "projectStatus",
    },
    {
      title: "สถานะการจัดเก็บเอกสาร",
      dataIndex: "projectProgress",
    },
    {
      title: "สัญญาหลัก",
      dataIndex: "mainDate",
    },
    {
      title: "สัญญาช่วง",
      dataIndex: "subcontractDate",
    },
    {
      title: "Manage",
      valueType: "option",
      width: 80,
      align: "center",
      fixed: "right",
      render: (_, record) => {
        if (
          storageUser?.role === "system_admin" ||
          storageUser?.role === "dept_user"
        ) {
          return [
            <Link to={`/backend/projects/view/${record.id}`} key="1">
              <Tooltip title="รายละเอียด">
                <ContainerOutlined />
              </Tooltip>
            </Link>,
            <Link to={`/backend/projects/${record.id}`} key="2">
              <Tooltip title="แก้ไข">
                <EditOutlined />
              </Tooltip>
            </Link>,
          ]
        } else {
          return (
            <Link to={`/backend/projects/view/${record.id}`} key="view">
              <Tooltip title="รายละเอียด">
                <ContainerOutlined />
              </Tooltip>
            </Link>
          )
        }
      },
    },
  ]
  const finishedColumns = [
    {
      title: "รหัสโครงการ",
      dataIndex: "projectCode",
      filters: true,
      sorter: false,
    },
    {
      title: "ชื่อโครงการ",
      dataIndex: "projectName",
      filters: true,
      sorter: false,
    },
    {
      title: "ปี",
      dataIndex: "year",
    },
    {
      title: "วงเงินตามสัญญาหลัก",
      dataIndex: "mainBudget",
    },
    {
      title: "วงเงินตามสัญญาช่วง",
      dataIndex: "subcontractBudget",
    },
    {
      title: "Manage",
      valueType: "option",
      width: 80,
      align: "center",
      fixed: "right",
      render: (_, record) => {
        if (
          storageUser?.role === "system_admin" ||
          storageUser?.role === "dept_user"
        ) {
          return [
            <Link to={`/backend/projects/view/${record.id}`} key="1">
              <Tooltip title="รายละเอียด">
                <ContainerOutlined />
              </Tooltip>
            </Link>,
            <Link to={`/backend/projects/${record.id}`} key="2">
              <Tooltip title="แก้ไข">
                <EditOutlined />
              </Tooltip>
            </Link>,
          ]
        } else {
          return (
            <Link to={`/backend/projects/view/${record.id}`} key="view">
              <Tooltip title="รายละเอียด">
                <ContainerOutlined />
              </Tooltip>
            </Link>
          )
        }
      },
    },
  ]

  async function fetchProgressingCollection(start = null, end = null) {
    try {
      let q = null
      if (start && end) {
        q = query(
          collection(db, "projects"),
          where("approvalDate", ">=", start),
          where("approvalDate", "<=", end),
          orderBy("approvalDate", "asc")
        )
      } else {
        //q = query(collection(db, "projects"), orderBy("approvalDate", "asc"))
        q = query(collection(db, "projects"))
      }
      const querySnapshot = await getDocs(q)
      let isProgress = 0
      let mainBudget = 0
      let barProgressAppend = []
      let barStatusAppend = []
      let sourceProgress = []
      if (querySnapshot.size > 0) {
        querySnapshot.forEach(doc => {
          const data = doc.data()
          if (!data?.finished || data.finished !== true) {
            const projectStatus = data?.projectStatus ? data?.projectStatus : 0
            const projectProgress = data?.projectProgress
              ? data?.projectProgress
              : 0
            const appendProgress = {
              id: doc?.id,
              projectCode: data?.projectCode,
              projectName: data?.projectName,
              projectProgress: projectProgress + "%",
              projectStatus: projectStatus + "%",
              mainDate:
                data?.mainStartDate && data?.mainEndDate
                  ? moment(data?.mainStartDate.toDate()).format(dateFormat) +
                    " - " +
                    moment(data?.mainEndDate.toDate()).format(dateFormat)
                  : "",
              subcontractDate:
                data?.subcontractStartDate && data?.subcontractEndDate
                  ? moment(data?.subcontractStartDate.toDate()).format(
                      dateFormat
                    ) +
                    " - " +
                    moment(data?.subcontractEndDate.toDate()).format(dateFormat)
                  : "",
              finished: data?.finished,
            }
            sourceProgress.push(appendProgress)
            // Bar Progress
            if (barProgressAppend.length < 10) {
              if (data?.mainEndDate && data?.subcontractEndDate) {
                barProgressAppend.push({
                  label: data.projectCode,
                  type: "สัญญาหลัก",
                  value: data?.mainEndDate
                    ? moment(data?.mainEndDate?.toDate()).unix()
                    : "",
                  extendDate: data?.mainExtendDate
                    ? moment(data?.mainExtendDate?.toDate()).unix()
                    : "",
                  projectName: data.projectName,
                  projectId: doc?.id,
                })
                barProgressAppend.push({
                  label: data.projectCode,
                  type: "สัญญาช่วง",
                  value: data?.subcontractEndDate
                    ? moment(data?.subcontractEndDate?.toDate()).unix()
                    : "",
                  extendDate: data?.subcontractExtendDate
                    ? moment(data?.subcontractExtendDate?.toDate()).unix()
                    : "",
                  projectName: data.projectName,
                  projectId: doc?.id,
                })
              }
            }
            // Bar Status
            if (barStatusAppend.length < 10) {
              if (
                (data?.projectStatus && data?.projectStatus !== "0") ||
                (data?.projectProgress && data?.projectProgress !== 0)
              ) {
                barStatusAppend.push({
                  label: data.projectCode,
                  type: "สถานะความก้าวหน้างานติดตั้ง",
                  value: data?.projectStatus ? data?.projectStatus : 0,
                  projectName: data.projectName,
                  projectId: doc?.id,
                })
                barStatusAppend.push({
                  label: data.projectCode,
                  type: "สถานะการจัดเก็บเอกสาร",
                  value: data?.projectProgress ? data?.projectProgress : 0,
                  projectName: data.projectName,
                  projectId: doc?.id,
                })
              }
            }
            isProgress++
          }
          if (data?.mainBudget) {
            mainBudget += parseFloat(data?.mainBudget)
          }
        })
        setTotalProgressing(isProgress)
        setTotalMainBudget(numberWithCommas(parseFloat(mainBudget).toFixed(2)))
        setBarProgressData(barProgressAppend)
        setBarStatusData(barStatusAppend)
        setProgressSource(sourceProgress)
      } else {
        setTotalProgressing(0)
        setTotalMainBudget(0)
        setBarProgressData(null)
        setBarStatusData(null)
        setProgressSource(null)
      }
    } catch (error) {
      console.log("error ", error)
      notification["error"]({
        message: error.code || error.name || error.status,
        description: error.error_description || error.message,
        duration: 0,
      })
    }
  }
  async function fetchFinishedCollection(start = null, end = null) {
    try {
      let q = null
      if (start && end) {
        q = query(
          collection(db, "projects"),
          where("ebDocumentDate0", ">=", start),
          where("ebDocumentDate0", "<=", end),
          orderBy("ebDocumentDate0", "asc")
        )
      } else {
        q = query(collection(db, "projects"), where("finished", "==", true))
      }
      const querySnapshot = await getDocs(q)
      let isFinished = 0
      let barFinishedMainPeroidAppend = []
      let barFinishedSubcontractPeroidAppend = []
      let barFinishedMainChargedAppend = []
      let barFinishedSubcontractChargedAppend = []
      let finishedMainBudget = 0
      let finishedMainChargedAmounts = 0
      let finishedSubcontractBudget = 0
      let finishedSubcontractChargedAmounts = 0
      let sourceFinished = []
      if (querySnapshot.size > 0) {
        querySnapshot.forEach(doc => {
          const data = doc.data()
          if (data?.finished && data.finished === true) {
            if (data?.mainBudget) {
              finishedMainBudget += parseFloat(data?.mainBudget)
            }
            if (data?.mainChargedAmounts) {
              finishedMainChargedAmounts += parseFloat(data?.mainChargedAmounts)
            }
            if (data?.subcontractBudget) {
              finishedSubcontractBudget += parseFloat(data?.subcontractBudget)
            }
            if (data?.subcontractChargedAmounts) {
              finishedSubcontractChargedAmounts += parseFloat(
                data?.subcontractChargedAmounts
              )
            }
            const appendFinished = {
              id: doc?.id,
              projectCode: data?.projectCode,
              projectName: data?.projectName,
              year: data?.year,
              mainBudget: data?.mainBudget
                ? numberWithCommas(data?.mainBudget)
                : "",
              subcontractBudget: data?.subcontractBudget
                ? numberWithCommas(data?.subcontractBudget)
                : "",
            }
            sourceFinished.push(appendFinished)
            // Bar
            if (barFinishedMainPeroidAppend.length < 10) {
              if (
                (data?.mainPeriod && data?.mainPeriod !== "0") ||
                (data?.mainCurrentPeriod && data?.mainCurrentPeriod !== "0")
              ) {
                barFinishedMainPeroidAppend.push({
                  label: data?.projectCode,
                  type: "ระยะเวลาให้บริการ",
                  value: data?.mainPeriod ? data?.mainPeriod : 0,
                  projectName: data.projectName,
                  projectId: doc?.id,
                })
                barFinishedMainPeroidAppend.push({
                  label: data?.projectCode,
                  type: "เรียกเก็บแล้ว",
                  value: data?.mainCurrentPeriod ? data?.mainCurrentPeriod : 0,
                  projectName: data.projectName,
                  projectId: doc?.id,
                })
              }
            }
            if (barFinishedSubcontractPeroidAppend.length < 10) {
              if (
                (data?.subcontractPeriod && data?.subcontractPeriod !== "0") ||
                (data?.subcontractCurrentPeriod &&
                  data?.subcontractCurrentPeriod !== "0")
              ) {
                barFinishedSubcontractPeroidAppend.push({
                  label: data?.projectCode,
                  type: "ระยะเวลาให้บริการ",
                  value: data?.subcontractPeriod ? data?.subcontractPeriod : 0,
                  projectName: data.projectName,
                  projectId: doc?.id,
                })
                barFinishedSubcontractPeroidAppend.push({
                  label: data?.projectCode,
                  type: "เรียกเก็บแล้ว",
                  value: data?.subcontractCurrentPeriod
                    ? data?.subcontractCurrentPeriod
                    : 0,
                  projectName: data.projectName,
                  projectId: doc?.id,
                })
              }
            }
            // Bar
            if (barFinishedMainChargedAppend.length < 15) {
              if (
                data?.mainChargedAmounts &&
                data?.mainChargedAmounts !== "0" &&
                data?.mainBalance &&
                data?.mainBalance !== "0"
              ) {
                barFinishedMainChargedAppend.push({
                  label: data?.projectCode,
                  type: "วงเงินทั้งหมด",
                  value: data?.mainBudget ? data?.mainBudget : 0,
                  projectName: data.projectName,
                  projectId: doc?.id,
                })
                barFinishedMainChargedAppend.push({
                  label: data?.projectCode,
                  type: "เรียกเก็บแล้ว",
                  value: data?.mainChargedAmounts
                    ? data?.mainChargedAmounts
                    : 0,
                  projectName: data.projectName,
                  projectId: doc?.id,
                })
                barFinishedMainChargedAppend.push({
                  label: data?.projectCode,
                  type: "คงเหลือ",
                  value: data?.mainBalance ? data?.mainBalance : 0,
                  projectName: data.projectName,
                  projectId: doc?.id,
                })
              }
            }
            if (barFinishedSubcontractChargedAppend.length < 15) {
              if (
                data?.subcontractChargedAmounts &&
                data?.subcontractChargedAmounts !== "0" &&
                data?.subcontractBalance &&
                data?.subcontractBalance !== "0"
              ) {
                barFinishedSubcontractChargedAppend.push({
                  label: data?.projectCode,
                  type: "วงเงินทั้งหมด",
                  value: data?.subcontractBudget ? data?.subcontractBudget : 0,
                  projectName: data.projectName,
                  projectId: doc?.id,
                })
                barFinishedSubcontractChargedAppend.push({
                  label: data?.projectCode,
                  type: "เรียกเก็บแล้ว",
                  value: data?.subcontractChargedAmounts
                    ? data?.subcontractChargedAmounts
                    : 0,
                  projectName: data.projectName,
                  projectId: doc?.id,
                })
                barFinishedSubcontractChargedAppend.push({
                  label: data?.projectCode,
                  type: "คงเหลือ",
                  value: data?.subcontractBalance
                    ? data?.subcontractBalance
                    : 0,
                  projectName: data.projectName,
                  projectId: doc?.id,
                })
              }
            }
            isFinished++
          }
        })
        setTotalFinished(isFinished)
        setMainBudgetFinished(
          numberWithCommas(parseFloat(finishedMainBudget).toFixed(2))
        )
        setMainChargedAmountsFinished(
          numberWithCommas(parseFloat(finishedMainChargedAmounts).toFixed(2))
        )
        setSubcontractBudgetFinished(
          numberWithCommas(parseFloat(finishedSubcontractBudget).toFixed(2))
        )
        setSubcontractChargedAmountsFinished(
          numberWithCommas(
            parseFloat(finishedSubcontractChargedAmounts).toFixed(2)
          )
        )
        setBarFinishedMainPeriodData(barFinishedMainPeroidAppend)
        setBarFinishedSubcontractPeriodData(barFinishedSubcontractPeroidAppend)
        setBarFinishedMainChargedData(barFinishedMainChargedAppend)
        setBarFinishedSubcontractChargedData(
          barFinishedSubcontractChargedAppend
        )
        setFinishedSource(sourceFinished)
      } else {
        setTotalFinished(0)
        setMainBudgetFinished(0)
        setMainChargedAmountsFinished(0)
        setSubcontractBudgetFinished(0)
        setSubcontractChargedAmountsFinished(0)
        setBarFinishedMainPeriodData(null)
        setBarFinishedSubcontractPeriodData(null)
        setBarFinishedMainChargedData(null)
        setBarFinishedSubcontractChargedData(null)
        setFinishedSource(null)
      }
    } catch (error) {
      console.log("error ", error)
      notification["error"]({
        message: error.code || error.name || error.status,
        description: error.error_description || error.message,
        duration: 0,
      })
    }
  }
  async function fetchAllCollection() {
    try {
      const q = query(collection(db, "projects"))
      const querySnapshot = await getDocs(q)
      let isFinished = 0
      let isProgress = 0
      let finishedMainBudget = 0
      let progressMainBudget = 0
      let finishedSubcontractBudget = 0
      let progressSubcontractBudget = 0
      let stackedBudgetAppend = []
      if (querySnapshot.size > 0) {
        querySnapshot.forEach(doc => {
          const data = doc.data()
          if (data?.finished && data.finished === true) {
            if (data?.mainBudget) {
              finishedMainBudget += parseFloat(data?.mainBudget)
            }
            if (data?.subcontractBudget) {
              finishedSubcontractBudget += parseFloat(data?.subcontractBudget)
            }
            isFinished++
          } else {
            if (data?.mainBudget) {
              progressMainBudget += parseFloat(data?.mainBudget)
            }
            if (data?.subcontractBudget) {
              progressSubcontractBudget += parseFloat(data?.subcontractBudget)
            }
            isProgress++
          }
        })
        setTotalFinished(isFinished)
        setTotalProgressing(isProgress)
        setTotalProjects(isFinished + isProgress)
        // Pie
        setPieProjectsData([
          {
            type: "ระหว่างติดตั้ง",
            value: isProgress,
          },
          {
            type: "ระหว่างเรียกเก็บ",
            value: isFinished,
          },
        ])
        // Stacked
        stackedBudgetAppend.push({
          label: "เงินตามสัญญาหลัก",
          type: "ระหว่างติดตั้ง",
          value: progressMainBudget,
        })
        stackedBudgetAppend.push({
          label: "เงินตามสัญญาหลัก",
          type: "ระหว่างเรียกเก็บ",
          value: finishedMainBudget,
        })
        //
        stackedBudgetAppend.push({
          label: "เงินตามสัญญาช่วง",
          type: "ระหว่างติดตั้ง",
          value: progressSubcontractBudget,
        })
        stackedBudgetAppend.push({
          label: "เงินตามสัญญาช่วง",
          type: "ระหว่างเรียกเก็บ",
          value: finishedSubcontractBudget,
        })
        setStackedProjectsData(stackedBudgetAppend)
      } else {
        setTotalFinished(0)
        setTotalProgressing(0)
        setTotalProjects(0)
        setPieProjectsData(null)
        setStackedProjectsData(null)
      }
    } catch (error) {
      console.log("error ", error)
      notification["error"]({
        message: error.code || error.name || error.status,
        description: error.error_description || error.message,
        duration: 0,
      })
    }
  }

  const handleApprovalDateChange = date => {
    if (date && date.length === 2) {
      const start = moment(date[0]).toDate()
      const end = moment(date[1]).toDate()
      fetchProgressingCollection(start, end)
    } else {
      fetchProgressingCollection()
    }
  }
  const handlePeriodDateChange = date => {
    if (date && date.length === 2) {
      const start = moment(date[0]).toDate()
      const end = moment(date[1]).toDate()
      fetchFinishedCollection(start, end)
    } else {
      fetchFinishedCollection()
    }
  }

  async function fetchFinishedByYear(year = null) {
    let sourceFinished = []
    try {
      let q = null
      if (year) {
        q = query(
          collection(db, "projects"),
          where("year", "==", year),
          where("finished", "==", true)
        )
      } else {
        q = query(collection(db, "projects"), where("finished", "==", true))
      }
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach(doc => {
        const data = doc.data()
        const appendFinished = {
          id: doc?.id,
          projectCode: data?.projectCode,
          projectName: data?.projectName,
          year: data?.year,
          mainBudget: data?.mainBudget
            ? numberWithCommas(data?.mainBudget)
            : "",
        }
        sourceFinished.push(appendFinished)
      })
    } catch (error) {
      console.log("error ", error)
      notification["error"]({
        message: error.code || error.name || error.status,
        description: error.error_description || error.message,
        duration: 0,
      })
      sourceFinished = []
    }
    setFinishedSource(sourceFinished)
  }
  const handleYearChange = date => {
    if (date) {
      const year = moment(date).format("YYYY")
      setFinishedSource(null)
      fetchFinishedByYear(year)
    } else {
      setFinishedSource(null)
      fetchFinishedByYear()
    }
  }
  const handleTabsChange = activeKey => {
    setTabActive(activeKey)
    if (activeKey === "progressing") {
      fetchProgressingCollection()
      form.setFieldValue("period__date", null)
    } else if (activeKey === "finished") {
      fetchFinishedCollection()
      form.setFieldValue("approval__date", null)
    } else if (activeKey === "all") {
      fetchAllCollection()
      form.setFieldValue("approval__date", null)
      form.setFieldValue("period__date", null)
    }
  }

  return (
    <div style={{ position: "relative" }}>
      <Form form={form}>
        {tabActive === "progressing" ? (
          <DatePickerWrapper>
            <p className="text">วันที่อนุมัติให้บริการ</p>
            <Form.Item name="approval__date">
              <RangePicker onChange={handleApprovalDateChange} />
            </Form.Item>
          </DatePickerWrapper>
        ) : tabActive === "finished" ? (
          <DatePickerWrapper>
            <p className="text">วันที่เริ่มต้นให้บริการ</p>
            <Form.Item name="period__date">
              <RangePicker onChange={handlePeriodDateChange} />
            </Form.Item>
          </DatePickerWrapper>
        ) : null}
      </Form>
      <TabsWrapper>
        <Tabs defaultActiveKey={tabActive} onChange={handleTabsChange}>
          <Tabs.TabPane tab="Progressing" key="progressing">
            <Row gutter={[24, 24]}>
              <Col xs={24} md={12}>
                <CardWrapper bordered={false}>
                  <p className="title">โครงการที่อยู่ระหว่างติดตั้ง</p>
                  <p className="total">{numberWithCommas(totalProgressing)}</p>
                </CardWrapper>
              </Col>
              <Col xs={24} md={12}>
                <CardWrapper bordered={false}>
                  <p className="title">วงเงินตามสัญญาหลัก</p>
                  <p className="total">{totalMainBudget}฿</p>
                </CardWrapper>
              </Col>
              <Col xs={24} xl={14}>
                <CardWrapper bordered={false}>
                  <h4 className="heading">ระยะเวลาบริหารโครงการ</h4>
                  {barProgressData ? (
                    <ProjectsProgressingBarChart data={barProgressData} />
                  ) : null}
                </CardWrapper>
              </Col>
              <Col xs={24} xl={10}>
                <CardWrapper bordered={false}>
                  <h4 className="heading">สถานะโครงการ</h4>
                  {barStatusData ? (
                    <ProjectProgressingStatusBarChart data={barStatusData} />
                  ) : null}
                </CardWrapper>
              </Col>
              <Col span={24}>
                <CardWrapper bordered={false}>
                  <ProTable
                    columns={progressingColumns}
                    rowSelection={{
                      selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
                    }}
                    tableAlertRender={({ selectedRows }) => {
                      if (
                        selectedRows &&
                        selectedRows.length > 0 &&
                        selectedRows[0] !== undefined
                      ) {
                        let rowHtml = "Project"
                        if (selectedRows.length > 1) {
                          rowHtml = "Projects"
                        }
                        let exportData = []
                        for (
                          let index = 0;
                          index < selectedRows.length;
                          index++
                        ) {
                          const data = selectedRows[index]
                          const append = {
                            "Project Code": data.projectCode,
                            "Project Name": data.projectName,
                            Progress: data.projectProgress,
                            Status: data.projectStatus,
                            "Main Date": data.mainDate,
                            "Subcontract Date": data.subcontractDate,
                          }
                          exportData.push(append)
                        }

                        return (
                          <CSVLink
                            data={exportData}
                            filename={`Solar e-Doc Progressing ${rowHtml}`}
                          >
                            CSV Report {selectedRows.length} {rowHtml}
                            &nbsp;&nbsp;
                            <DownloadOutlined />
                          </CSVLink>
                        )
                      } else {
                        return null
                      }
                    }}
                    dataSource={progressSource}
                    search={false}
                    toolbar={{
                      title: "โครงการที่อยู่ระหว่างติดตั้ง",
                    }}
                    rowKey="id"
                    pagination={{
                      pageSize: 5,
                      showSizeChanger: false,
                    }}
                    dateFormatter="string"
                  />
                </CardWrapper>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Finished" key="finished">
            <Row gutter={[24, 24]}>
              <Col xs={24} md={24} lg={24} xxl={4}>
                <CardWrapper bordered={false}>
                  <p className="title">โครงการติดตั้งแล้วเสร็จ</p>
                  <p className="total">{numberWithCommas(totalFinished)}</p>
                </CardWrapper>
              </Col>
              <Col xs={24} md={12} lg={6} xxl={5}>
                <CardWrapper bordered={false}>
                  <p className="title">วงเงินตามสัญญาหลัก</p>
                  <p className="total">{mainBudgetFinished}฿</p>
                </CardWrapper>
              </Col>
              <Col xs={24} md={12} lg={6} xxl={5}>
                <CardWrapper bordered={false}>
                  <p className="title">วงเงินตามสัญญาช่วง</p>
                  <p className="total">{subcontractBudgetFinished}฿</p>
                </CardWrapper>
              </Col>
              <Col xs={24} md={12} lg={6} xxl={5}>
                <CardWrapper bordered={false}>
                  <p className="title">ยอดเงินที่เก็บได้จาก Owner</p>
                  <p className="total">{mainChargedAmountsFinished}฿</p>
                </CardWrapper>
              </Col>
              <Col xs={24} md={12} lg={6} xxl={5}>
                <CardWrapper bordered={false}>
                  <p className="title">ยอดเงินที่จ่ายให้ Vender</p>
                  <p className="total">{subcontractChargedAmountsFinished}฿</p>
                </CardWrapper>
              </Col>
              <Col xs={24} xl={12}>
                <CardWrapper bordered={false}>
                  <h4 className="heading">ระยะเวลาให้บริการลูกค้า (งวด)</h4>
                  {barFinishedMainPeriodData ? (
                    <FinishedMainPeriodBarChart
                      data={barFinishedMainPeriodData}
                    />
                  ) : null}
                </CardWrapper>
              </Col>
              <Col xs={24} xl={12}>
                <CardWrapper bordered={false}>
                  <h4 className="heading">เรียกเก็บเงินลูกค้า</h4>
                  {barFinishedMainChargedData ? (
                    <FinishedMainChargedBarChart
                      data={barFinishedMainChargedData}
                    />
                  ) : null}
                </CardWrapper>
              </Col>
              <Col xs={24} xl={12}>
                <CardWrapper bordered={false}>
                  <h4 className="heading">
                    ระยะเวลาให้บริการบริษัทผู้รับจ้าง (งวด)
                  </h4>
                  {barFinishedSubcontractPeriodData ? (
                    <FinishedMainPeriodBarChart
                      data={barFinishedSubcontractPeriodData}
                    />
                  ) : null}
                </CardWrapper>
              </Col>
              <Col xs={24} xl={12}>
                <CardWrapper bordered={false}>
                  <h4 className="heading">เรียกเก็บเงินบริษัทผู้รับจ้าง</h4>
                  {barFinishedSubcontractChargedData ? (
                    <FinishedMainChargedBarChart
                      data={barFinishedSubcontractChargedData}
                    />
                  ) : null}
                </CardWrapper>
              </Col>
              <Col span={24}>
                <CardWrapper bordered={false}>
                  <ProTable
                    columns={finishedColumns}
                    rowSelection={{
                      selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
                    }}
                    tableAlertRender={({ selectedRows }) => {
                      if (
                        selectedRows &&
                        selectedRows.length > 0 &&
                        selectedRows[0] !== undefined
                      ) {
                        let rowHtml = "Project"
                        if (selectedRows.length > 1) {
                          rowHtml = "Projects"
                        }
                        let exportData = []
                        for (
                          let index = 0;
                          index < selectedRows.length;
                          index++
                        ) {
                          const data = selectedRows[index]
                          const append = {
                            "Project Code": data.projectCode,
                            "Project Name": data.projectName,
                            Year: data.year,
                            "Main Budget(฿)": data.mainBudget,
                            "Subcontract Budget(฿)": data.subcontractBudget,
                          }
                          exportData.push(append)
                        }

                        return (
                          <CSVLink
                            data={exportData}
                            filename={`Solar e-Doc Finished ${rowHtml}`}
                          >
                            CSV Report {selectedRows.length} {rowHtml}
                            &nbsp;&nbsp;
                            <DownloadOutlined />
                          </CSVLink>
                        )
                      } else {
                        return null
                      }
                    }}
                    dataSource={finishedSource}
                    search={false}
                    toolbar={{
                      title: "โครงการติดตั้งแล้วเสร็จ",
                      actions: [
                        <DatePicker
                          onChange={handleYearChange}
                          picker="year"
                        />,
                      ],
                    }}
                    rowKey="id"
                    pagination={{
                      pageSize: 5,
                      showSizeChanger: false,
                    }}
                    dateFormatter="string"
                  />
                </CardWrapper>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="All Projects" key="all">
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <CardWrapper bordered={false}>
                  <p className="title">จำนวนโครงการทั้งหมด</p>
                  <p className="total">{numberWithCommas(totalProjects)}</p>
                </CardWrapper>
              </Col>
              <Col xs={24} lg={8}>
                <CardWrapper bordered={false}>
                  {pieProjectsData ? (
                    <ProjectsPieChart
                      data={pieProjectsData}
                      content={numberWithCommas(totalProjects)}
                    />
                  ) : null}
                </CardWrapper>
              </Col>
              <Col xs={24} lg={16}>
                <CardWrapper bordered={false}>
                  {stackedProjectsData ? (
                    <ProjectBudgetStacked data={stackedProjectsData} />
                  ) : null}
                </CardWrapper>
              </Col>
            </Row>
          </Tabs.TabPane>
        </Tabs>
      </TabsWrapper>
    </div>
  )
}

export default Dashboard
