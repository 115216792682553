export const options = {
  workerLineToken: "https://wandering-recipe-cabd.info4197.workers.dev/",
  workerLineNotify: "https://solitary-tree-1b7e.info4197.workers.dev/",
  mailText: "info@websitecafe.info",
  mailCTA: "mailto:info@websitecafe.info",
  telText: "091-7656679",
  telCTA: "tel:0917656679",
  facebookUrl: "https://web.facebook.com/websitecafe2003",
  lineText: "@websitecafe",
  lineCTA: "https://line.me/R/ti/p/%40websitecafe",
}

export default options
