import React, { useEffect, useState } from "react"
import app from "gatsby-plugin-firebase-v9.0"
import {
  getFirestore,
  collection,
  doc,
  addDoc,
  getDoc,
  setDoc,
  Timestamp,
} from "firebase/firestore"
import { Link, navigate } from "gatsby"
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  notification,
  Row,
  Switch,
} from "antd"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import styled from "styled-components"

const config = require("../../../config/site")

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-item: center;
  justify-content: center;
  margin-top: 30px;
  > a {
    margin-right: 20px;
  }
`
const SwitchWrapper = styled.div`
  width: 100%;
  display: flex;
  align-item: center;
`

const Profile = () => {
  const db = getFirestore(app)
  const isBrowser = typeof window !== "undefined"
  const [form] = Form.useForm()
  const [processing, setProcessing] = useState(false)
  const [uid, setUID] = useState(null)
  const [lineToken, setLineToken] = useState(null)

  useEffect(() => {
    if (isBrowser) {
      const storageUser = JSON.parse(
        localStorage.getItem("firebaseLocalStorage")
      )
      if (storageUser && storageUser?.uid) {
        const _uid = storageUser?.uid
        setUID(_uid)
        fetchDocument(_uid)
      } else {
        navigate("/backend/dashboard")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function fetchDocument(uid) {
    const docRef = doc(db, "users", uid)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      const data = docSnap.data()
      form.setFieldsValue({
        first_name: data?.firstName || ``,
        last_name: data?.lastName || ``,
        email: data?.email || ``,
        line: data?.line || ``,
      })
      if (data?.lineToken) {
        setLineToken(data?.lineToken)
      }
    }
  }

  const handleFinish = async values => {
    if (isBrowser && uid) {
      setProcessing(true)
      try {
        const { first_name, last_name, line } = values
        const dataUser = {
          firstName: first_name,
          lastName: last_name,
          line: line,
        }
        const docRef = doc(db, "users", uid)
        await setDoc(docRef, dataUser, { merge: true })
        const dataLog = {
          uid: uid,
          type: "Profile",
          action: "Update",
          by: uid,
          createdAt: Timestamp.now(),
        }
        await addDoc(collection(db, "logs"), dataLog)
        message.success("Success")
        setProcessing(false)
      } catch (error) {
        console.log("error ", error)
        notification["error"]({
          message: error.code || error.name || error.status,
          description: error.error_description || error.message,
          duration: 0,
        })
        setProcessing(false)
      }
    }
  }

  return (
    <Form form={form} layout="vertical" onFinish={handleFinish}>
      <Card bordered={false}>
        <Row gutter={24}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="first_name"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="last_name"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name="line" label="Line">
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="line_notify" label="Line Notify">
              <SwitchWrapper>
                {lineToken ? (
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked
                  />
                ) : (
                  <a
                    href={`https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=${process.env.GATSBY_LINE_CLIENT_ID}&redirect_uri=${config.siteUrl}auth/line&scope=notify&state=${uid}`}
                  >
                    <Button
                      shape="round"
                      style={{
                        background: "#16c464",
                        borderColor: "#16c464",
                        color: "#fff",
                      }}
                    >
                      Connect Line Notify
                    </Button>
                  </a>
                )}
              </SwitchWrapper>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 36]}>
          <Col span={24}>
            <Form.Item>
              <ButtonWrapper>
                <Link to="/backend/dashboard">
                  <Button type="secondary">Cancel</Button>
                </Link>
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={processing}
                  disabled={processing}
                >
                  Submit
                </Button>
              </ButtonWrapper>
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </Form>
  )
}

export default Profile
