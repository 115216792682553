import React from "react"
import { navigate } from "gatsby"
import { Bar } from "@ant-design/plots"
import moment from "moment"

const ProjectsProgressingBarChart = ({ data }) => {
  const config = {
    theme: {
      styleSheet: {
        fontFamily: "'IBM Plex Sans Thai', sans-serif",
      },
    },
    data,
    isGroup: true,
    isStack: true,
    xField: "value",
    yField: "label",
    color: ["#6B145D", "#C09339"],
    seriesField: "type",
    marginRatio: 0,
    xAxis: false,
    /*xAxis: {
      label: {
        formatter: o => {
          return moment.unix(o).utc().format("LL").toString()
        },
      },
    },*/
    label: {
      position: "middle",
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
      formatter: o => {
        return moment.unix(o.value).utc().format("LL").toString()
      },
    },
    tooltip: {
      /*formatter: o => {
        return {
          name: o.type,
          value: moment.unix(o.value).utc().format("LL").toString(),
        }
      },*/
      customContent: (title, items) => {
        const data1 = items[0]?.data || {}
        const data2 = items[1]?.data || {}
        if (data1 || data2) {
          const project = `<p style="margin-bottom: 10px;">${data1.projectName}</p>`
          let html1 = ""
          if (data1?.type) {
            const name1 = data1.type
            const value1 = moment
              .unix(data1.value)
              .utc()
              .format("LL")
              .toString()
            html1 = `<p style="margin-bottom: 5px;">${name1}: <strong>${value1}</strong></p>`
            if (data1?.extendDate) {
              const extend1 = moment
                .unix(data1.extendDate)
                .utc()
                .format("LL")
                .toString()
              html1 += `<p style="margin-bottom: 5px; color: #f5222d;">ขยายหลัก: <strong>${extend1}</strong></p>`
            }
          }
          let html2 = ""
          if (data2?.type) {
            const name2 = data2.type
            const value2 = moment
              .unix(data2.value)
              .utc()
              .format("LL")
              .toString()
            html2 = `<p style="margin-bottom: 5px;">${name2}: <strong>${value2}</strong></p>`
            if (data2?.extendDate) {
              const extend2 = moment
                .unix(data2.extendDate)
                .utc()
                .format("LL")
                .toString()
              html2 += `<p style="margin-bottom: 5px; color: #f5222d;">ขยายช่วง: <strong>${extend2}</strong></p>`
            }
          }
          return `<div style="padding: 20px 10px;">${project}${html1}${html2}</div>`
        }
        return ""
      },
    },
    legend: {
      custom: true,
      position: "top",
      items: [
        {
          value: "main",
          name: "สัญญาหลัก",
          marker: {
            symbol: "square",
            style: {
              fill: "#6B145D",
              r: 5,
            },
          },
        },
        {
          value: "sub",
          name: "สัญญาช่วง",
          marker: {
            symbol: "square",
            style: {
              fill: "#C09339",
              r: 5,
            },
          },
        },
      ],
    },
    onReady: plot => {
      plot.on("element:click", (...args) => {
        if (args) {
          const data = args[0]?.data?.data
          navigate(`/backend/projects/view/${data.projectId}`)
        }
      })
    },
  }
  return <Bar {...config} />
}

export default ProjectsProgressingBarChart
