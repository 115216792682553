import React from "react"
import { Pie } from "@ant-design/plots"

const ProjectsPieChart = ({ data, content }) => {
  const config = {
    theme: {
      styleSheet: {
        fontFamily: "'Montserrat', 'IBM Plex Sans Thai', sans-serif",
      },
    },
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: "inner",
      offset: "-50%",
      content: "{value}",
      style: {
        fontSize: 16,
        textAlign: "center",
        whiteSpace: "pre-wrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          fontSize: 24,
          textAlign: "center",
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: content,
      },
    },
    legend: {
      position: "bottom",
    },
  }

  return <Pie {...config} color={["#6B145D", "#C09339"]} />
}

export default ProjectsPieChart
