import React, { useEffect, useState } from "react"
import app from "gatsby-plugin-firebase-v9.0"
import {
  getFirestore,
  getDocs,
  collection,
  doc,
  deleteDoc,
  setDoc,
  query,
  where,
} from "firebase/firestore"
import moment from "moment"
import { Badge, Button, Dropdown, List } from "antd"
import { BellOutlined, DeleteOutlined } from "@ant-design/icons"
import styled from "styled-components"

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 100%;
  margin-right: 15px;
  cursor: pointer;
`
const TitleWrapper = styled.h3`
  font-family: "Montserrat", "IBM Plex Sans Thai", sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.85);
  margin: 0 auto 15px 0;
  text-align: center;
  padding: 0 24px;
`
const ListWrapper = styled(List)`
  .ant-list-pagination {
    margin: 20px auto 0 auto;
    text-align: center;
  }
  .ant-list-items {
    border-top: 1px solid #f0f0f0;
    .ant-list-item {
      background: #fff;
      cursor: pointer;
      &.read {
        opacity: 0.4;
      }
      &:hover {
        background: #f0f2f5;
      }
    }
    .ant-list-item-meta {
      padding: 0 24px;
    }
    .ant-list-item-meta-title {
      font-family: "Montserrat", "IBM Plex Sans Thai", sans-serif;
      font-weight: 500;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.65);
    }
    .ant-list-item-meta-description {
      font-family: "Montserrat", "IBM Plex Sans Thai", sans-serif;
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 0;
    }
  }
`

const NoticeIconView = () => {
  const db = getFirestore(app)
  const isBrowser = typeof window !== "undefined"
  const [count, setCount] = useState(0)
  const [notices, setNotices] = useState([])

  useEffect(() => {
    if (isBrowser) {
      fetchCollection()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleReadNotice = async (id, read) => {
    if (id && read === false) {
      // Update read to true and Reload count
      const dataNotice = {
        read: true,
      }
      const docRef = doc(db, "notifications", id)
      await setDoc(docRef, dataNotice, { merge: true })
      fetchCollection()
    }
  }
  const handleRemoveNotice = async id => {
    await deleteDoc(doc(db, "notifications", id))
    fetchCollection()
  }

  async function fetchCollection() {
    let result = []
    let unRead = 0
    const storageUser = JSON.parse(localStorage.getItem("firebaseLocalStorage"))
    if (storageUser?.uid) {
      const q = query(
        collection(db, "notifications"),
        where("uid", "==", storageUser?.uid)
      )
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach(doc => {
        const data = doc.data()
        if (data) {
          const { title, read, createdAt } = data
          const displayDateTime = moment(createdAt.toDate()).format(
            "DD MMM YYYY, HH:mm"
          )
          if (!read) {
            unRead++
          }
          const append = {
            uid: storageUser.uid,
            id: doc.id,
            title: title,
            read: read,
            date: moment(createdAt.toDate()),
            display: displayDateTime,
          }
          result.push(append)
        }
      })
      result.sort(function (a, b) {
        return b.date - a.date
      })
      result.sort(function (a, b) {
        // true values first
        //return (x === y)? 0 : x? -1 : 1;
        // false values first
        return a.read === b.read ? 0 : a.read ? 1 : -1
      })
      setCount(unRead)
      setNotices(
        <>
          <TitleWrapper>Notitications</TitleWrapper>
          <ListWrapper
            itemLayout="horizontal"
            dataSource={result}
            renderItem={item => {
              return (
                <List.Item
                  key={item?.id}
                  actions={[
                    <Button
                      key="list-remove"
                      icon={<DeleteOutlined />}
                      type="primary"
                      onClick={() => handleRemoveNotice(item?.id)}
                    />,
                  ]}
                  onClick={() => handleReadNotice(item?.id, item?.read)}
                  className={item?.read ? "read" : "unread"}
                >
                  <List.Item.Meta
                    title={item.title}
                    description={item?.display}
                  />
                </List.Item>
              )
            }}
            pagination={
              result?.length > 3
                ? {
                    size: "small",
                    pageSize: 3,
                  }
                : false
            }
          ></ListWrapper>
        </>
      )
    }
  }

  return (
    <Dropdown
      overlay={notices}
      overlayStyle={{
        minWidth: "320px",
        maxWidth: "320px",
        padding: "20px 0",
        background: "#fff",
        borderRadius: "2px",
        boxShadow:
          "0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)",
      }}
      trigger={["click"]}
      placement="bottomRight"
      className="noticeDropdown"
    >
      <IconWrapper>
        <Badge count={count} showZero>
          <BellOutlined />
        </Badge>
      </IconWrapper>
    </Dropdown>
  )
}

export default NoticeIconView
