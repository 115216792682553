import React, { useState } from "react"
import app from "gatsby-plugin-firebase-v9.0"
import { getFirestore, collection, query, getDocs } from "firebase/firestore"
import { Link, navigate } from "gatsby"
import Fuse from "fuse.js"
import ProTable from "@ant-design/pro-table"
import { dateFormat } from "../../../utils/helper"
import moment from "moment"
import { Button, notification, Tooltip } from "antd"
import {
  ContainerOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons"

const ProjectsProgressing = () => {
  const db = getFirestore(app)
  const isBrowser = typeof window !== "undefined"
  const storageUser = JSON.parse(localStorage.getItem("firebaseLocalStorage"))
  const [activeKey, setActiveKey] = useState("progressing")

  const fuseOptions = {
    useExtendedSearch: true,
    keys: ["projectCode", "projectName"],
  }

  const columns = [
    {
      title: "รหัสโครงการ",
      dataIndex: "projectCode",
      filters: true,
      sorter: false,
    },
    {
      title: "ชื่อโครงการ",
      dataIndex: "projectName",
      filters: true,
      sorter: false,
    },
    {
      title: "ความก้าวหน้างานติดตั้ง",
      dataIndex: "projectStatus",
      hideInSearch: true,
    },
    {
      title: "สถานะการจัดเก็บเอกสาร",
      dataIndex: "projectProgress",
      hideInSearch: true,
    },
    {
      title: "สัญญาหลัก",
      dataIndex: "mainDate",
      hideInSearch: true,
    },
    {
      title: "สัญญาช่วง",
      dataIndex: "subcontractDate",
      hideInSearch: true,
    },
    {
      title: "Manage",
      valueType: "option",
      width: 80,
      align: "center",
      fixed: "right",
      render: (_, record) => {
        if (
          storageUser?.role === "system_admin" ||
          storageUser?.role === "dept_user"
        ) {
          return [
            <Link to={`/backend/projects/view/${record.id}`} key="1">
              <Tooltip title="รายละเอียด">
                <ContainerOutlined />
              </Tooltip>
            </Link>,
            <Link to={`/backend/projects/${record.id}`} key="2">
              <Tooltip title="แก้ไข">
                <EditOutlined />
              </Tooltip>
            </Link>,
          ]
        } else {
          return (
            <Link to={`/backend/projects/view/${record.id}`} key="view">
              <Tooltip title="รายละเอียด">
                <ContainerOutlined />
              </Tooltip>
            </Link>
          )
        }
      },
    },
  ]

  async function fetchCollection(params) {
    let result = []
    let list = []
    try {
      if (isBrowser) {
        const q = query(collection(db, "projects"))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach(doc => {
          const data = doc.data()
          if (data?.finished !== true) {
            const projectProgress = data?.projectProgress
              ? data?.projectProgress
              : 0
            const projectStatus = data?.projectStatus ? data?.projectStatus : 0
            const append = {
              id: doc?.id,
              projectName: data?.projectName,
              projectCode: data?.projectCode,
              projectStatus: projectStatus + "%",
              projectProgress: projectProgress + "%",
              mainDate:
                data?.mainStartDate && data?.mainEndDate
                  ? moment(data?.mainStartDate.toDate()).format(dateFormat) +
                    " - " +
                    moment(data?.mainEndDate.toDate()).format(dateFormat)
                  : "",
              subcontractDate:
                data?.subcontractStartDate && data?.subcontractEndDate
                  ? moment(data?.subcontractStartDate.toDate()).format(
                      dateFormat
                    ) +
                    " - " +
                    moment(data?.subcontractEndDate.toDate()).format(dateFormat)
                  : "",
            }
            list.push(append)
          }
        })
        const fuse = new Fuse(list, fuseOptions)
        if (fuse) {
          let fuseData = null
          if (params?.projectCode || params?.projectName) {
            if (
              params?.projectCode?.length > 0 &&
              params?.projectName?.length > 0
            ) {
              fuseData = fuse.search({
                $and: [
                  { projectCode: params.projectCode },
                  { projectName: params.projectName },
                ],
              })
            } else {
              if (params?.projectCode?.length > 0) {
                fuseData = fuse.search({ projectCode: params.projectCode })
              }
              if (params?.projectName?.length > 0) {
                fuseData = fuse.search({ projectName: params.projectName })
              }
            }
            if (fuseData) {
              fuseData.map(row => {
                const {
                  id,
                  projectCode,
                  projectName,
                  projectStatus,
                  projectProgress,
                  mainDate,
                  subcontractDate,
                } = row.item
                const append = {
                  id: id,
                  projectCode: projectCode,
                  projectName: projectName,
                  projectStatus: projectStatus,
                  projectProgress: projectProgress,
                  mainDate: mainDate,
                  subcontractDate: subcontractDate,
                }
                result.push(append)
                return null
              })
            }
          } else {
            result = list
          }
        }
      }
    } catch (error) {
      console.log("error ", error)
      notification["error"]({
        message: error.code || error.name || error.status,
        description: error.error_description || error.message,
        duration: 0,
      })
    }
    return result
  }

  const handleTabChange = key => {
    if (key === "progressing") {
      setActiveKey(key)
    } else if (key === "finished") {
      navigate("/backend/projects/finished")
    } else {
      navigate("/backend/projects/all")
    }
  }

  return (
    <ProTable
      columns={columns}
      request={async params => {
        const dataSource = await fetchCollection(params)
        return {
          data: dataSource,
          success: true,
        }
      }}
      rowKey="id"
      search={{
        labelWidth: 120,
      }}
      toolbar={{
        multipleLine: true,
        menu: {
          type: "tab",
          activeKey: activeKey,
          items: [
            {
              key: "all",
              label: "All",
            },
            {
              key: "progressing",
              label: "Progressing",
            },
            {
              key: "finished",
              label: "Finished",
            },
          ],
          onChange: key => {
            handleTabChange(key)
          },
        },
        actions:
          storageUser?.role === "system_admin" ||
          storageUser?.role === "dept_user"
            ? [
                <Link to="/backend/projects/create">
                  <Button key="button" icon={<PlusOutlined />} type="primary">
                    Create
                  </Button>
                </Link>,
              ]
            : null,
      }}
      pagination={{
        pageSize: 20,
        showSizeChanger: false,
      }}
      dateFormatter="string"
    />
  )
}

export default ProjectsProgressing
