import React, { useEffect, useState } from "react"
import app from "gatsby-plugin-firebase-v9.0"
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth"
import {
  getFirestore,
  doc,
  addDoc,
  collection,
  getDoc,
  setDoc,
  Timestamp,
} from "firebase/firestore"
import { getStorage, ref, getDownloadURL } from "firebase/storage"
import { Link, navigate } from "gatsby"
import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Form,
  Modal,
  message,
  notification,
  Row,
  Space,
} from "antd"
import {
  DiffOutlined,
  DownloadOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  FileSearchOutlined,
  ProjectOutlined,
} from "@ant-design/icons"
import { CSVLink } from "react-csv"
import { dateFormatDisplay, numberWithCommas } from "../../../../utils/helper"
import moment from "moment"
import styled from "styled-components"

const { confirm } = Modal
const { Panel } = Collapse

const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  align-item: center;
  justify-content: flex-end;
  margin-bottom: 24px;
`
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-item: center;
  justify-content: center;
  margin-top: 30px;
`
const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  align-item: center;
  justify-content: flex-end;
`
const DateGroupWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-item: center;
  p {
    min-width: 320px;
    margin: 5px 0;
    font-family: "Montserrat", "IBM Plex Sans Thai", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.45);
    border-right: none;
    &:last-child {
      margin: 5px 0;
      border-right: none;
    }
    span {
      color: rgba(0, 0, 0, 0.85);
      &.extend {
        color: #f5222d;
      }
    }
  }

  @media only screen and (min-width: 576px) {
    p {
      margin: 5px 30px 5px 0;
      border-right: 1px solid #434343;
    }
  }
`
const ProjectDescription = styled.div`
  background: #fafafa;
  border-radius: 2px;
  padding: 20px 30px;
  .title {
    font-family: "Montserrat", "IBM Plex Sans Thai", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #434343;
  }
  .item {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .text {
      font-family: "Montserrat", "IBM Plex Sans Thai", sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 10px;
    }
    .value {
      font-family: "Montserrat", "IBM Plex Sans Thai", sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 10px;
    }
  }
`
const UploadsList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .item {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px 5px 15px;
    margin-top: 12px;
    margin-right: 20px;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    .file {
      margin-right: 10px;
      .name {
        font-family: "Montserrat", "IBM Plex Sans Thai", sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .download {
      text-decoration: none;
    }
  }
`

const ProjectViewID = ({ id }) => {
  const auth = getAuth(app)
  const db = getFirestore(app)
  const storage = getStorage(app)
  const isBrowser = typeof window !== "undefined"
  const storageUser = JSON.parse(localStorage.getItem("firebaseLocalStorage"))

  const [canProcess, setCanProcess] = useState(false)
  const [finished, setFinished] = useState(false)
  const [projectCode, setProjectCode] = useState("")
  const [projectName, setProjectName] = useState("")
  const [projectDetails, setProjectDetails] = useState("")
  const [mainStartDate, setMainStartDate] = useState("")
  const [mainEndDate, setMainEndDate] = useState("")
  const [mainExtendDate, setMainExtendDate] = useState("")
  const [subcontractStartDate, setSubcontractStartDate] = useState("")
  const [subcontractEndDate, setSubcontractEndDate] = useState("")
  const [subcontractExtendDate, setSubcontractExtendDate] = useState("")
  const [customer, setCustomer] = useState("")
  const [vender, setVender] = useState("")
  const [mainBudget, setMainBudget] = useState("")
  const [projectStatus, setProjectStatus] = useState(0)
  const [projectProgress, setProjectProgress] = useState(0)

  // PS
  const [fileListPSMain, setFileListPSMain] = useState("")
  const [downloadPS, setDownloadPS] = useState("")
  const [fileListPSSub, setFileListPSSub] = useState([])
  const [fileListPSMain2, setFileListPSMain2] = useState("")
  const [downloadPS2, setDownloadPS2] = useState("")
  const [fileListPSSub2, setFileListPSSub2] = useState([])
  const [fileListPSMain3, setFileListPSMain3] = useState("")
  const [downloadPS3, setDownloadPS3] = useState("")
  const [fileListPSSub3, setFileListPSSub3] = useState([])
  const [fileListPSMain4, setFileListPSMain4] = useState("")
  const [downloadPS4, setDownloadPS4] = useState("")
  const [fileListPSSub4, setFileListPSSub4] = useState([])
  const [fileListPSMain5, setFileListPSMain5] = useState("")
  const [downloadPS5, setDownloadPS5] = useState("")
  const [fileListPSSub5, setFileListPSSub5] = useState([])
  const [fileListPSMain6, setFileListPSMain6] = useState("")
  const [downloadPS6, setDownloadPS6] = useState("")
  const [fileListPSSub6, setFileListPSSub6] = useState([])

  // AC
  const [fileListACMain, setFileListACMain] = useState("")
  const [downloadAC, setDownloadAC] = useState("")
  const [fileListACSub, setFileListACSub] = useState([])
  const [fileListACMain2, setFileListACMain2] = useState("")
  const [downloadAC2, setDownloadAC2] = useState("")
  const [fileListACSub2, setFileListACSub2] = useState([])
  const [fileListACMain3, setFileListACMain3] = useState("")
  const [downloadAC3, setDownloadAC3] = useState("")
  const [fileListACSub3, setFileListACSub3] = useState([])
  const [fileListACMain4, setFileListACMain4] = useState("")
  const [downloadAC4, setDownloadAC4] = useState("")
  const [fileListACSub4, setFileListACSub4] = useState([])
  const [fileListACMain5, setFileListACMain5] = useState("")
  const [downloadAC5, setDownloadAC5] = useState("")
  const [fileListACSub5, setFileListACSub5] = useState([])
  const [fileListACMain6, setFileListACMain6] = useState("")
  const [downloadAC6, setDownloadAC6] = useState("")
  const [fileListACSub6, setFileListACSub6] = useState([])

  // VP
  const [fileListVPMain, setFileListVPMain] = useState("")
  const [downloadVP, setDownloadVP] = useState("")
  const [fileListVPSub, setFileListVPSub] = useState([])
  const [fileListVPMain2, setFileListVPMain2] = useState("")
  const [downloadVP2, setDownloadVP2] = useState("")
  const [fileListVPSub2, setFileListVPSub2] = useState([])
  const [fileListVPMain3, setFileListVPMain3] = useState("")
  const [downloadVP3, setDownloadVP3] = useState("")
  const [fileListVPSub3, setFileListVPSub3] = useState([])
  const [fileListVPMain4, setFileListVPMain4] = useState("")
  const [downloadVP4, setDownloadVP4] = useState("")
  const [fileListVPSub4, setFileListVPSub4] = useState([])
  const [fileListVPMain5, setFileListVPMain5] = useState("")
  const [downloadVP5, setDownloadVP5] = useState("")
  const [fileListVPSub5, setFileListVPSub5] = useState([])
  const [fileListVPMain6, setFileListVPMain6] = useState("")
  const [downloadVP6, setDownloadVP6] = useState("")
  const [fileListVPSub6, setFileListVPSub6] = useState([])
  const [fileListVPMain7, setFileListVPMain7] = useState("")
  const [downloadVP7, setDownloadVP7] = useState("")
  const [fileListVPSub7, setFileListVPSub7] = useState([])

  // EC
  const [fileListECMain, setFileListECMain] = useState("")
  const [downloadEC, setDownloadEC] = useState("")
  const [fileListECSub, setFileListECSub] = useState([])
  const [fileListECMain2, setFileListECMain2] = useState("")
  const [downloadEC2, setDownloadEC2] = useState("")
  const [fileListECSub2, setFileListECSub2] = useState([])
  const [fileListECMain3, setFileListECMain3] = useState("")
  const [downloadEC3, setDownloadEC3] = useState("")
  const [fileListECSub3, setFileListECSub3] = useState([])
  const [fileListECMain4, setFileListECMain4] = useState("")
  const [downloadEC4, setDownloadEC4] = useState("")
  const [fileListECSub4, setFileListECSub4] = useState([])
  const [fileListECMain5, setFileListECMain5] = useState("")
  const [downloadEC5, setDownloadEC5] = useState("")
  const [fileListECSub5, setFileListECSub5] = useState([])
  const [fileListECMain6, setFileListECMain6] = useState("")
  const [downloadEC6, setDownloadEC6] = useState("")
  const [fileListECSub6, setFileListECSub6] = useState([])
  const [fileListECMain7, setFileListECMain7] = useState("")
  const [downloadEC7, setDownloadEC7] = useState("")
  const [fileListECSub7, setFileListECSub7] = useState([])
  const [fileListECMain8, setFileListECMain8] = useState("")
  const [downloadEC8, setDownloadEC8] = useState("")
  const [fileListECSub8, setFileListECSub8] = useState([])
  const [fileListECMain9, setFileListECMain9] = useState("")
  const [downloadEC9, setDownloadEC9] = useState("")
  const [fileListECSub9, setFileListECSub9] = useState([])
  const [fileListECMain10, setFileListECMain10] = useState("")
  const [downloadEC10, setDownloadEC10] = useState("")
  const [fileListECSub10, setFileListECSub10] = useState([])
  const [fileListECMain11, setFileListECMain11] = useState("")
  const [downloadEC11, setDownloadEC11] = useState("")
  const [fileListECSub11, setFileListECSub11] = useState([])

  // TC
  const [fileListTCMain, setFileListTCMain] = useState("")
  const [downloadTC, setDownloadTC] = useState("")
  const [fileListTCSub, setFileListTCSub] = useState([])
  const [fileListTCMain2, setFileListTCMain2] = useState("")
  const [downloadTC2, setDownloadTC2] = useState("")
  const [fileListTCSub2, setFileListTCSub2] = useState([])
  const [fileListTCMain3, setFileListTCMain3] = useState("")
  const [downloadTC3, setDownloadTC3] = useState("")
  const [fileListTCSub3, setFileListTCSub3] = useState([])
  const [fileListTCMain4, setFileListTCMain4] = useState("")
  const [downloadTC4, setDownloadTC4] = useState("")
  const [fileListTCSub4, setFileListTCSub4] = useState([])

  // IA
  const [fileListIAMain, setFileListIAMain] = useState("")
  const [downloadIA, setDownloadIA] = useState("")
  const [fileListIASub, setFileListIASub] = useState([])
  const [fileListIAMain2, setFileListIAMain2] = useState("")
  const [downloadIA2, setDownloadIA2] = useState("")
  const [fileListIASub2, setFileListIASub2] = useState([])
  const [fileListIAMain3, setFileListIAMain3] = useState("")
  const [downloadIA3, setDownloadIA3] = useState("")
  const [fileListIASub3, setFileListIASub3] = useState([])

  // EB
  const [fileListEBMain3, setFileListEBMain3] = useState("")
  const [downloadEB3, setDownloadEB3] = useState("")
  const [fileListEBSub3, setFileListEBSub3] = useState([])
  const [fileListEBMain4, setFileListEBMain4] = useState("")
  const [downloadEB4, setDownloadEB4] = useState("")
  const [fileListEBSub4, setFileListEBSub4] = useState([])

  // Process
  const [exportData, setExportData] = useState([])
  let mainExtendDateProcess = null
  let subcontractExtendDateProcess = null

  useEffect(() => {
    if (isBrowser) {
      onAuthStateChanged(auth, user => {
        if (user) {
          if (user?.disabled || user?.disabled === `true`) {
            logoutUser()
            return null
          } else {
            fetchUser(user.uid)
          }
        } else {
          localStorage.removeItem("firebaseLocalStorage")
          navigate("/auth/signin")
          return null
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])

  const logoutUser = async () => {
    if (isBrowser) {
      signOut(auth)
        .then(() => {
          localStorage.removeItem("firebaseLocalStorage")
          navigate("/auth/signin")
        })
        .catch(error => {
          console.log("Failed to logout user: %o", error)
        })
    }
  }

  async function fetchUser(uid) {
    const docRef = doc(db, "users", uid)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      const data = docSnap.data()
      const role = data?.role ? data?.role : null
      const department = data?.department ? data?.department : null
      const storageUser = {
        uid: uid,
        email: data?.email,
        firstName: data?.firstName,
        lastName: data?.lastName,
        role: role,
        department: department,
      }
      localStorage.setItem("firebaseLocalStorage", JSON.stringify(storageUser))
      if (role && role !== "subscriber") {
        if (role === "system_admin" || role === "dept_user") {
          setCanProcess(true)
        } else {
          setCanProcess(false)
        }
        fetchDocument()
      } else {
        await logoutUser()
        return null
      }
    } else {
      await logoutUser()
      return null
    }
  }

  async function fetchDocument() {
    try {
      const docRef = doc(db, "projects", id)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        const data = docSnap.data()
        setFinished(data?.finished)
        setProjectCode(data?.projectCode)
        setProjectName(data?.projectName)
        setProjectDetails(data?.projectDetails || ``)
        if (data?.mainStartDate) {
          setMainStartDate(
            moment(data?.mainStartDate.toDate()).format(dateFormatDisplay)
          )
        }
        if (data?.mainEndDate) {
          setMainEndDate(
            moment(data?.mainEndDate.toDate()).format(dateFormatDisplay)
          )
        }
        if (data?.mainExtendDate) {
          setMainExtendDate(
            moment(data?.mainExtendDate.toDate()).format(dateFormatDisplay)
          )
        }
        if (data?.subcontractStartDate) {
          setSubcontractStartDate(
            moment(data?.subcontractStartDate.toDate()).format(
              dateFormatDisplay
            )
          )
        }
        if (data?.subcontractEndDate) {
          setSubcontractEndDate(
            moment(data?.subcontractEndDate.toDate()).format(dateFormatDisplay)
          )
        }
        if (data?.subcontractExtendDate) {
          setSubcontractExtendDate(
            moment(data?.subcontractExtendDate.toDate()).format(
              dateFormatDisplay
            )
          )
        }
        setCustomer(data?.customer || ``)
        setVender(data?.vender || ``)
        setMainBudget(
          data?.mainBudget ? numberWithCommas(data?.mainBudget) : `0.00`
        )
        setProjectStatus(data?.projectStatus ? data?.projectStatus : 0)
        setProjectProgress(data?.projectProgress ? data?.projectProgress : 0)

        // Export CSV
        const appendExport = {
          ชื่อโครงการ: data?.projectCode,
          "Project Name": data?.projectName,
          "Project Details": data?.projectDetails,
          "Main Start Date": data?.mainStartDate
            ? moment(data?.mainStartDate.toDate()).format(dateFormatDisplay)
            : "",
          "Main End Date": data?.mainEndDate
            ? moment(data?.mainEndDate.toDate()).format(dateFormatDisplay)
            : "",
          Customer: data?.customer,
          "Main Budget": data?.mainBudget
            ? numberWithCommas(data?.mainBudget) + "฿"
            : `0.00`,
          "Main Period": data?.mainPeriod,
          "Main Current Period": data?.mainCurrentPeriod,
          "Main Amounts": data?.mainAmounts
            ? numberWithCommas(data?.mainAmounts) + "฿"
            : `0.00`,
          "Main Charged Amounts": data?.mainChargedAmounts
            ? numberWithCommas(data?.mainChargedAmounts) + "฿"
            : `0.00`,
          "Main Balance": data?.mainBalance
            ? numberWithCommas(data?.mainBalance) + "฿"
            : `0.00`,
          "Subcontract Start Date": data?.subcontractStartDate
            ? moment(data?.subcontractStartDate.toDate()).format(
                dateFormatDisplay
              )
            : "",
          "Subcontract End Date": data?.subcontractEndDate
            ? moment(data?.subcontractEndDate.toDate()).format(
                dateFormatDisplay
              )
            : "",
          Vender: data?.vender,
          "Subcontract Budget": data?.subcontractBudget
            ? numberWithCommas(data?.subcontractBudget) + "฿"
            : `0.00`,
          "Subcontract Period": data?.subcontractPeriod,
          "Subcontract Current Period": data?.subcontractCurrentPeriod,
          "Subcontract Amounts": data?.subcontractAmounts
            ? numberWithCommas(data?.subcontractAmounts) + "฿"
            : `0.00`,
          "Subcontract Charged Amounts": data?.subcontractChargedAmounts
            ? numberWithCommas(data?.subcontractChargedAmounts) + "฿"
            : `0.00`,
          "Subcontract Balance": data?.subcontractBalance
            ? numberWithCommas(data?.subcontractBalance) + "฿"
            : `0.00`,
          "Project Progress": data?.projectProgress
            ? data?.projectProgress + "%"
            : "",
          "Project Status": data?.projectStatus
            ? data?.projectStatus + "%"
            : "",
        }
        const arrayExport = []
        arrayExport.push(appendExport)
        setExportData(arrayExport)

        // PS 1
        const psDocumentMain = data?.psDocument?.main
          ? data?.psDocument?.main
          : ""
        if (psDocumentMain?.length > 0) {
          setDownloadPS(`${id}/${psDocumentMain}`)
          setFileListPSMain(psDocumentMain)
        }
        const psDocumentSub = data?.psDocument?.sub ? data?.psDocument?.sub : []
        setFileListPSSub(psDocumentSub)
        // PS 2
        const psDocumentMain2 = data?.psDocument?.main2
          ? data?.psDocument?.main2
          : ""
        if (psDocumentMain2?.length > 0) {
          setDownloadPS2(`${id}/${psDocumentMain2}`)
          setFileListPSMain2(psDocumentMain2)
        }
        const psDocumentSub2 = data?.psDocument?.sub2
          ? data?.psDocument?.sub2
          : []
        setFileListPSSub2(psDocumentSub2)
        // PS 3
        const psDocumentMain3 = data?.psDocument?.main3
          ? data?.psDocument?.main3
          : ""
        if (psDocumentMain3?.length > 0) {
          setDownloadPS3(`${id}/${psDocumentMain3}`)
          setFileListPSMain3(psDocumentMain3)
        }
        const psDocumentSub3 = data?.psDocument?.sub3
          ? data?.psDocument?.sub3
          : []
        setFileListPSSub3(psDocumentSub3)
        // PS 4
        const psDocumentMain4 = data?.psDocument?.main4
          ? data?.psDocument?.main4
          : ""
        if (psDocumentMain4?.length > 0) {
          setDownloadPS4(`${id}/${psDocumentMain4}`)
          setFileListPSMain4(psDocumentMain4)
        }
        const psDocumentSub4 = data?.psDocument?.sub4
          ? data?.psDocument?.sub4
          : []
        setFileListPSSub4(psDocumentSub4)
        // PS 5
        const psDocumentMain5 = data?.psDocument?.main5
          ? data?.psDocument?.main5
          : ""
        if (psDocumentMain5?.length > 0) {
          setDownloadPS5(`${id}/${psDocumentMain5}`)
          setFileListPSMain5(psDocumentMain5)
        }
        const psDocumentSub5 = data?.psDocument?.sub5
          ? data?.psDocument?.sub5
          : []
        setFileListPSSub5(psDocumentSub5)
        // PS 6
        const psDocumentMain6 = data?.psDocument?.main6
          ? data?.psDocument?.main6
          : ""
        if (psDocumentMain6?.length > 0) {
          setDownloadPS6(`${id}/${psDocumentMain6}`)
          setFileListPSMain6(psDocumentMain6)
        }
        const psDocumentSub6 = data?.psDocument?.sub6
          ? data?.psDocument?.sub6
          : []
        setFileListPSSub6(psDocumentSub6)

        // AC 1
        const acDocumentMain = data?.acDocument?.main
          ? data?.acDocument?.main
          : ""
        if (acDocumentMain?.length > 0) {
          setDownloadAC(`${id}/${acDocumentMain}`)
          setFileListACMain(acDocumentMain)
        }
        const acDocumentSub = data?.acDocument?.sub ? data?.acDocument?.sub : []
        setFileListACSub(acDocumentSub)
        // AC 2
        const acDocumentMain2 = data?.acDocument?.main2
          ? data?.acDocument?.main2
          : ""
        if (acDocumentMain2?.length > 0) {
          setDownloadAC2(`${id}/${acDocumentMain2}`)
          setFileListACMain2(acDocumentMain2)
        }
        const acDocumentSub2 = data?.acDocument?.sub2
          ? data?.acDocument?.sub2
          : []
        setFileListACSub2(acDocumentSub2)
        // AC 3
        const acDocumentMain3 = data?.acDocument?.main3
          ? data?.acDocument?.main3
          : ""
        if (acDocumentMain3?.length > 0) {
          setDownloadAC3(`${id}/${acDocumentMain3}`)
          setFileListACMain3(acDocumentMain3)
        }
        const acDocumentSub3 = data?.acDocument?.sub3
          ? data?.acDocument?.sub3
          : []
        setFileListACSub3(acDocumentSub3)
        // AC 4
        const acDocumentMain4 = data?.acDocument?.main4
          ? data?.acDocument?.main4
          : ""
        if (acDocumentMain4?.length > 0) {
          setDownloadAC4(`${id}/${acDocumentMain4}`)
          setFileListACMain4(acDocumentMain4)
        }
        const acDocumentSub4 = data?.acDocument?.sub4
          ? data?.acDocument?.sub4
          : []
        setFileListACSub4(acDocumentSub4)
        // AC 5
        const acDocumentMain5 = data?.acDocument?.main5
          ? data?.acDocument?.main5
          : ""
        if (acDocumentMain5?.length > 0) {
          setDownloadAC5(`${id}/${acDocumentMain5}`)
          setFileListACMain5(acDocumentMain5)
        }
        const acDocumentSub5 = data?.acDocument?.sub5
          ? data?.acDocument?.sub5
          : []
        setFileListACSub5(acDocumentSub5)
        // AC 6
        const acDocumentMain6 = data?.acDocument?.main6
          ? data?.acDocument?.main6
          : ""
        if (acDocumentMain6?.length > 0) {
          setDownloadAC6(`${id}/${acDocumentMain6}`)
          setFileListACMain6(acDocumentMain6)
        }
        const acDocumentSub6 = data?.acDocument?.sub6
          ? data?.acDocument?.sub6
          : []
        setFileListACSub6(acDocumentSub6)

        // VP 1
        const vpDocumentMain = data?.vpDocument?.main
          ? data?.vpDocument?.main
          : ""
        if (vpDocumentMain?.length > 0) {
          setDownloadVP(`${id}/${vpDocumentMain}`)
          setFileListVPMain(vpDocumentMain)
        }
        const vpDocumentSub = data?.vpDocument?.sub ? data?.vpDocument?.sub : []
        setFileListVPSub(vpDocumentSub)
        // VP 2
        const vpDocumentMain2 = data?.vpDocument?.main2
          ? data?.vpDocument?.main2
          : ""
        if (vpDocumentMain2?.length > 0) {
          setDownloadVP2(`${id}/${vpDocumentMain2}`)
          setFileListVPMain2(vpDocumentMain2)
        }
        const vpDocumentSub2 = data?.vpDocument?.sub2
          ? data?.vpDocument?.sub2
          : []
        setFileListVPSub2(vpDocumentSub2)
        // VP 3
        const vpDocumentMain3 = data?.vpDocument?.main3
          ? data?.vpDocument?.main3
          : ""
        if (vpDocumentMain3?.length > 0) {
          setDownloadVP3(`${id}/${vpDocumentMain3}`)
          setFileListVPMain3(vpDocumentMain3)
        }
        const vpDocumentSub3 = data?.vpDocument?.sub3
          ? data?.vpDocument?.sub3
          : []
        setFileListVPSub3(vpDocumentSub3)
        // VP 4
        const vpDocumentMain4 = data?.vpDocument?.main4
          ? data?.vpDocument?.main4
          : ""
        if (vpDocumentMain4?.length > 0) {
          setDownloadVP4(`${id}/${vpDocumentMain4}`)
          setFileListVPMain4(vpDocumentMain4)
        }
        const vpDocumentSub4 = data?.vpDocument?.sub4
          ? data?.vpDocument?.sub4
          : []
        setFileListVPSub4(vpDocumentSub4)
        // VP 5
        const vpDocumentMain5 = data?.vpDocument?.main5
          ? data?.vpDocument?.main5
          : ""
        if (vpDocumentMain5?.length > 0) {
          setDownloadVP5(`${id}/${vpDocumentMain5}`)
          setFileListVPMain5(vpDocumentMain5)
        }
        const vpDocumentSub5 = data?.vpDocument?.sub5
          ? data?.vpDocument?.sub5
          : []
        setFileListVPSub5(vpDocumentSub5)
        // VP 6
        const vpDocumentMain6 = data?.vpDocument?.main6
          ? data?.vpDocument?.main6
          : ""
        if (vpDocumentMain6?.length > 0) {
          setDownloadVP6(`${id}/${vpDocumentMain6}`)
          setFileListVPMain6(vpDocumentMain6)
        }
        const vpDocumentSub6 = data?.vpDocument?.sub6
          ? data?.vpDocument?.sub6
          : []
        setFileListVPSub6(vpDocumentSub6)
        // VP 7
        const vpDocumentMain7 = data?.vpDocument?.main7
          ? data?.vpDocument?.main7
          : ""
        if (vpDocumentMain7?.length > 0) {
          setDownloadVP7(`${id}/${vpDocumentMain7}`)
          setFileListVPMain7(vpDocumentMain7)
        }
        const vpDocumentSub7 = data?.vpDocument?.sub7
          ? data?.vpDocument?.sub7
          : []
        setFileListVPSub7(vpDocumentSub7)

        // EC 1
        const ecDocumentMain = data?.ecDocument?.main
          ? data?.ecDocument?.main
          : ""
        if (ecDocumentMain?.length > 0) {
          setDownloadEC(`${id}/${ecDocumentMain}`)
          setFileListECMain(ecDocumentMain)
        }
        const ecDocumentSub = data?.ecDocument?.sub ? data?.ecDocument?.sub : []
        setFileListECSub(ecDocumentSub)
        // EC 2
        const ecDocumentMain2 = data?.ecDocument?.main2
          ? data?.ecDocument?.main2
          : ""
        if (ecDocumentMain2?.length > 0) {
          setDownloadEC2(`${id}/${ecDocumentMain2}`)
          setFileListECMain2(ecDocumentMain2)
        }
        const ecDocumentSub2 = data?.ecDocument?.sub2
          ? data?.ecDocument?.sub2
          : []
        setFileListECSub2(ecDocumentSub2)
        // EC 3
        const ecDocumentMain3 = data?.ecDocument?.main3
          ? data?.ecDocument?.main3
          : ""
        if (ecDocumentMain3?.length > 0) {
          setDownloadEC3(`${id}/${ecDocumentMain3}`)
          setFileListECMain3(ecDocumentMain3)
        }
        const ecDocumentSub3 = data?.ecDocument?.sub3
          ? data?.ecDocument?.sub3
          : []
        setFileListECSub3(ecDocumentSub3)
        // EC 4
        const ecDocumentMain4 = data?.ecDocument?.main4
          ? data?.ecDocument?.main4
          : ""
        if (ecDocumentMain4?.length > 0) {
          setDownloadEC4(`${id}/${ecDocumentMain4}`)
          setFileListECMain4(ecDocumentMain4)
        }
        const ecDocumentSub4 = data?.ecDocument?.sub4
          ? data?.ecDocument?.sub4
          : []
        setFileListECSub4(ecDocumentSub4)
        // EC 5
        const ecDocumentMain5 = data?.ecDocument?.main5
          ? data?.ecDocument?.main5
          : ""
        if (ecDocumentMain5?.length > 0) {
          setDownloadEC5(`${id}/${ecDocumentMain5}`)
          setFileListECMain5(ecDocumentMain5)
        }
        const ecDocumentSub5 = data?.ecDocument?.sub5
          ? data?.ecDocument?.sub5
          : []
        setFileListECSub5(ecDocumentSub5)
        // EC 6
        const ecDocumentMain6 = data?.ecDocument?.main6
          ? data?.ecDocument?.main6
          : ""
        if (ecDocumentMain6?.length > 0) {
          setDownloadEC6(`${id}/${ecDocumentMain6}`)
          setFileListECMain6(ecDocumentMain6)
        }
        const ecDocumentSub6 = data?.ecDocument?.sub6
          ? data?.ecDocument?.sub6
          : []
        setFileListECSub6(ecDocumentSub6)
        // EC 7
        const ecDocumentMain7 = data?.ecDocument?.main7
          ? data?.ecDocument?.main7
          : ""
        if (ecDocumentMain7?.length > 0) {
          setDownloadEC7(`${id}/${ecDocumentMain7}`)
          setFileListECMain7(ecDocumentMain7)
        }
        const ecDocumentSub7 = data?.ecDocument?.sub7
          ? data?.ecDocument?.sub7
          : []
        setFileListECSub7(ecDocumentSub7)
        // EC 8
        const ecDocumentMain8 = data?.ecDocument?.main8
          ? data?.ecDocument?.main8
          : ""
        if (ecDocumentMain8?.length > 0) {
          setDownloadEC8(`${id}/${ecDocumentMain8}`)
          setFileListECMain8(ecDocumentMain8)
        }
        const ecDocumentSub8 = data?.ecDocument?.sub8
          ? data?.ecDocument?.sub8
          : []
        setFileListECSub8(ecDocumentSub8)
        // EC 9
        const ecDocumentMain9 = data?.ecDocument?.main9
          ? data?.ecDocument?.main9
          : ""
        if (ecDocumentMain9?.length > 0) {
          setDownloadEC9(`${id}/${ecDocumentMain9}`)
          setFileListECMain9(ecDocumentMain9)
        }
        const ecDocumentSub9 = data?.ecDocument?.sub9
          ? data?.ecDocument?.sub9
          : []
        setFileListECSub9(ecDocumentSub9)
        // EC 10
        const ecDocumentMain10 = data?.ecDocument?.main10
          ? data?.ecDocument?.main10
          : ""
        if (ecDocumentMain10?.length > 0) {
          setDownloadEC10(`${id}/${ecDocumentMain10}`)
          setFileListECMain10(ecDocumentMain10)
        }
        const ecDocumentSub10 = data?.ecDocument?.sub10
          ? data?.ecDocument?.sub10
          : []
        setFileListECSub10(ecDocumentSub10)
        // EC 11
        const ecDocumentMain11 = data?.ecDocument?.main11
          ? data?.ecDocument?.main11
          : ""
        if (ecDocumentMain11?.length > 0) {
          setDownloadEC11(`${id}/${ecDocumentMain11}`)
          setFileListECMain11(ecDocumentMain11)
        }
        const ecDocumentSub11 = data?.ecDocument?.sub11
          ? data?.ecDocument?.sub11
          : []
        setFileListECSub11(ecDocumentSub11)

        // TC 1
        const tcDocumentMain = data?.tcDocument?.main
          ? data?.tcDocument?.main
          : ""
        if (tcDocumentMain?.length > 0) {
          setDownloadTC(`${id}/${tcDocumentMain}`)
          setFileListTCMain(tcDocumentMain)
        }
        const tcDocumentSub = data?.tcDocument?.sub ? data?.tcDocument?.sub : []
        setFileListTCSub(tcDocumentSub)
        // TC 2
        const tcDocumentMain2 = data?.tcDocument?.main2
          ? data?.tcDocument?.main2
          : ""
        if (tcDocumentMain2?.length > 0) {
          setDownloadTC2(`${id}/${tcDocumentMain2}`)
          setFileListTCMain2(tcDocumentMain2)
        }
        const tcDocumentSub2 = data?.tcDocument?.sub2
          ? data?.tcDocument?.sub2
          : []
        setFileListTCSub2(tcDocumentSub2)
        // TC 3
        const tcDocumentMain3 = data?.tcDocument?.main3
          ? data?.tcDocument?.main3
          : ""
        if (tcDocumentMain3?.length > 0) {
          setDownloadTC3(`${id}/${tcDocumentMain3}`)
          setFileListTCMain3(tcDocumentMain3)
        }
        const tcDocumentSub3 = data?.tcDocument?.sub3
          ? data?.tcDocument?.sub3
          : []
        setFileListTCSub3(tcDocumentSub3)
        // TC 4
        const tcDocumentMain4 = data?.tcDocument?.main4
          ? data?.tcDocument?.main4
          : ""
        if (tcDocumentMain4?.length > 0) {
          setDownloadTC4(`${id}/${tcDocumentMain4}`)
          setFileListTCMain4(tcDocumentMain4)
        }
        const tcDocumentSub4 = data?.tcDocument?.sub4
          ? data?.tcDocument?.sub4
          : []
        setFileListTCSub4(tcDocumentSub4)

        // IA 1
        const iaDocumentMain = data?.iaDocument?.main
          ? data?.iaDocument?.main
          : ""
        if (iaDocumentMain?.length > 0) {
          setDownloadIA(`${id}/${iaDocumentMain}`)
          setFileListIAMain(iaDocumentMain)
        }
        const iaDocumentSub = data?.iaDocument?.sub ? data?.iaDocument?.sub : []
        setFileListIASub(iaDocumentSub)
        // IA 2
        const iaDocumentMain2 = data?.iaDocument?.main2
          ? data?.iaDocument?.main2
          : ""
        if (iaDocumentMain2?.length > 0) {
          setDownloadIA2(`${id}/${iaDocumentMain2}`)
          setFileListIAMain2(iaDocumentMain2)
        }
        const iaDocumentSub2 = data?.iaDocument?.sub2
          ? data?.iaDocument?.sub2
          : []
        setFileListIASub2(iaDocumentSub2)
        // IA 3
        const iaDocumentMain3 = data?.iaDocument?.main3
          ? data?.iaDocument?.main3
          : ""
        if (iaDocumentMain3?.length > 0) {
          setDownloadIA3(`${id}/${iaDocumentMain3}`)
          setFileListIAMain3(iaDocumentMain3)
        }
        const iaDocumentSub3 = data?.iaDocument?.sub3
          ? data?.iaDocument?.sub3
          : []
        setFileListIASub3(iaDocumentSub3)

        // EB 3
        const ebDocumentMain3 = data?.ebDocument?.main3
          ? data?.ebDocument?.main3
          : ""
        if (ebDocumentMain3?.length > 0) {
          setDownloadEB3(`${id}/${ebDocumentMain3}`)
          setFileListEBMain3(ebDocumentMain3)
        }
        const ebDocumentSub3 = data?.ebDocument?.sub3
          ? data?.ebDocument?.sub3
          : []
        setFileListEBSub3(ebDocumentSub3)
        // EB 4
        const ebDocumentMain4 = data?.ebDocument?.main4
          ? data?.ebDocument?.main4
          : ""
        if (ebDocumentMain4?.length > 0) {
          setDownloadEB4(`${id}/${ebDocumentMain4}`)
          setFileListEBMain4(ebDocumentMain4)
        }
        const ebDocumentSub4 = data?.ebDocument?.sub4
          ? data?.ebDocument?.sub4
          : []
        setFileListEBSub4(ebDocumentSub4)
      } else {
        navigate("/backend/projects/all")
      }
    } catch (error) {
      console.log("error ", error)
      notification["error"]({
        message: error.code || error.name || error.status,
        description: error.error_description || error.message,
        duration: 0,
      })
    }
  }

  const handleProjectDone = async () => {
    try {
      if (canProcess) {
        const isFinished = !finished
        const docRef = doc(db, "projects", id)
        const dataProject = {
          finished: isFinished,
        }
        await setDoc(docRef, dataProject, { merge: true })
        const dataLog = {
          pid: id,
          type: "Projects",
          action: "Done",
          by: storageUser?.uid,
          createdAt: Timestamp.now(),
        }
        await addDoc(collection(db, "logs"), dataLog)
        message.success(`Updated Project Success`)
        navigate("/backend/projects/all")
      }
    } catch (error) {
      console.log("error ", error)
      notification["error"]({
        message: error.code || error.name || error.status,
        description: error.error_description || error.message,
        duration: 0,
      })
    }
  }

  const handleProjectMainExtend = async () => {
    try {
      if (canProcess) {
        const docRef = doc(db, "projects", id)
        const dataProject = {
          mainExtendDate: mainExtendDateProcess
            ? moment(mainExtendDateProcess).toDate()
            : "",
        }
        await setDoc(docRef, dataProject, { merge: true })
        const dataLog = {
          pid: id,
          type: "Projects",
          action: "Extend",
          by: storageUser?.uid,
          createdAt: Timestamp.now(),
        }
        await addDoc(collection(db, "logs"), dataLog)
        message.success(`Updated Success`)
        navigate("/backend/projects/all")
      }
    } catch (error) {
      console.log("error ", error)
      notification["error"]({
        message: error.code || error.name || error.status,
        description: error.error_description || error.message,
        duration: 0,
      })
    }
  }
  const onChangeExtendMain = date => {
    mainExtendDateProcess = date
  }
  const confirmExtendMain = () => {
    confirm({
      title: "ขยายสัญญาหลัก",
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <p>คุณแน่ใจว่าต้องการ ขยายสัญญาหลัก โครงการนี้?</p>
          <DatePicker onChange={onChangeExtendMain} />
        </>
      ),
      onOk() {
        handleProjectMainExtend()
      },
    })
  }

  const handleProjectSubcontractExtend = async () => {
    try {
      if (canProcess) {
        const docRef = doc(db, "projects", id)
        const dataProject = {
          subcontractExtendDate: subcontractExtendDateProcess
            ? moment(subcontractExtendDateProcess).toDate()
            : "",
        }
        await setDoc(docRef, dataProject, { merge: true })
        const dataLog = {
          pid: id,
          type: "Projects",
          action: "Extend",
          by: storageUser?.uid,
          createdAt: Timestamp.now(),
        }
        await addDoc(collection(db, "logs"), dataLog)
        message.success(`Updated Success`)
        navigate("/backend/projects/all")
      }
    } catch (error) {
      console.log("error ", error)
      notification["error"]({
        message: error.code || error.name || error.status,
        description: error.error_description || error.message,
        duration: 0,
      })
    }
  }
  const onChangeExtendSubcontract = date => {
    subcontractExtendDateProcess = date
  }
  const confirmExtendSubcontract = () => {
    confirm({
      title: "ขยายสัญญาช่วง",
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <p>คุณแน่ใจว่าต้องการ ขยายสัญญาช่วง โครงการนี้?</p>
          <DatePicker onChange={onChangeExtendSubcontract} />
        </>
      ),
      onOk() {
        handleProjectSubcontractExtend()
      },
    })
  }

  const handlePreviewDocument = file => {
    if (file && file.length > 0) {
      const storageRef = ref(storage, file)
      getDownloadURL(storageRef)
        .then(url => {
          const splitUrl = url.split(".").pop()
          const extension = splitUrl.split("?", 1)
          const valid =
            extension && extension.length === 1 ? extension[0] : null
          if (valid && valid !== "dwg" && valid !== "dxf" && valid !== "dwf") {
            if (
              valid !== "png" &&
              valid !== "jpg" &&
              valid !== "jpeg" &&
              valid !== "svg"
            ) {
              const encodeURI = encodeURIComponent(url)
              window.open(
                `https://docs.google.com/viewer?url=${encodeURI}`,
                "",
                "fullscreen=yes"
              )
            } else {
              Modal.info({
                title: storageRef.name,
                content: <img src={url} alt="" />,
                onOk() {},
                centered: true,
                closable: true,
                className: "full--width",
              })
            }
          } else {
            message.info("Can not preview! File type is unsupported.")
          }
        })
        .catch(error => {
          notification["error"]({
            message: error.code || error.name || error.status,
            description: error.error_description || error.message,
            duration: 0,
          })
        })
    }
  }
  const handleDownloadDocument = file => {
    if (file && file.length > 0) {
      const storageRef = ref(storage, file)
      getDownloadURL(storageRef)
        .then(url => {
          const link = document.createElement("a")
          link.download = storageRef.name
          link.href = url
          link.target = "_blank"
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .catch(error => {
          notification["error"]({
            message: error.code || error.name || error.status,
            description: error.error_description || error.message,
            duration: 0,
          })
        })
    }
  }

  return (
    <>
      {canProcess ? (
        <TopWrapper>
          <Space wrap>
            <Button
              onClick={confirmExtendMain}
              style={{
                background: "#597EF7",
                borderColor: "#597EF7",
                color: "#fff",
              }}
            >
              <DiffOutlined />
              &nbsp;ขยายสัญญาหลัก
            </Button>
            <Button
              onClick={confirmExtendSubcontract}
              style={{
                background: "#FAAD14",
                borderColor: "#FAAD14",
                color: "#fff",
              }}
            >
              <DiffOutlined />
              &nbsp;ขยายสัญญาช่วง
            </Button>
            {finished ? (
              <Button
                onClick={handleProjectDone}
                style={{
                  background: "#7CB305",
                  borderColor: "#7CB305",
                  color: "#fff",
                }}
              >
                <ProjectOutlined />
                &nbsp;Set Project back to Progressing!
              </Button>
            ) : (
              <Button
                onClick={handleProjectDone}
                style={{
                  background: "#7CB305",
                  borderColor: "#7CB305",
                  color: "#fff",
                }}
              >
                <CheckCircleOutlined />
                &nbsp;Set Project Done.
              </Button>
            )}
          </Space>
        </TopWrapper>
      ) : null}
      <Card bordered={false}>
        <Row gutter={[24, 24]}>
          <Col xs={24} md={12} lg={16}>
            <h2 className="projectName">{projectName}</h2>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <ButtonGroup>
              <Space>
                {exportData && exportData.length > 0 ? (
                  <CSVLink
                    data={exportData}
                    filename={`${projectCode}-${projectName}`}
                  >
                    <Button>
                      Export CSV&nbsp;&nbsp;
                      <DownloadOutlined />
                    </Button>
                  </CSVLink>
                ) : null}
                {canProcess ? (
                  <Link to={`/backend/projects/${id}`}>
                    <Button type="primary">
                      <EditOutlined />
                      &nbsp;Edit
                    </Button>
                  </Link>
                ) : null}
              </Space>
            </ButtonGroup>
          </Col>
          <Col span={24}>
            <DateGroupWrapper key="main">
              <p>
                เริ่มสัญญาหลัก: <span>{mainStartDate}</span>
              </p>
              <p>
                สิ้นสุดสัญญาหลัก: <span>{mainEndDate}</span>
              </p>
              {mainExtendDate ? (
                <p>
                  ขยายสัญญาหลัก:{" "}
                  <span className="extend">{mainExtendDate}</span>
                </p>
              ) : null}
            </DateGroupWrapper>
            {subcontractStartDate && subcontractEndDate ? (
              <DateGroupWrapper key="subcontract">
                <p>
                  เริ่มสัญญาช่วง: <span>{subcontractStartDate}</span>
                </p>
                <p>
                  สิ้นสุดสัญญาช่วง: <span>{subcontractEndDate}</span>
                </p>
                {subcontractExtendDate ? (
                  <p>
                    ขยายสัญญาช่วง:{" "}
                    <span className="extend">{subcontractExtendDate}</span>
                  </p>
                ) : null}
              </DateGroupWrapper>
            ) : null}
          </Col>
          <Col xs={24} md={12} xl={16}>
            <p className="projectDetails">{projectDetails}</p>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <ProjectDescription>
              <p className="title">รายละเอียดโครงการ</p>
              <div className="item">
                <p className="text">ชื่อโครงการ:</p>
                <p className="value">{projectCode}</p>
              </div>
              <div className="item">
                <p className="text">เริ่มสัญญาหลัก:</p>
                <p className="value">{mainStartDate}</p>
              </div>
              <div className="item">
                <p className="text">สิ้นสุดสัญญาหลัก:</p>
                <p className="value">{mainEndDate}</p>
              </div>
              <div className="item">
                <p className="text">ลูกค้า:</p>
                <p className="value">{customer}</p>
              </div>
              <div className="item">
                <p className="text">วงเงินตามสัญญาหลัก:</p>
                <p className="value">{`${mainBudget}฿`}</p>
              </div>
              <div className="item">
                <p className="text">บริษัทผู้รับจ้าง:</p>
                <p className="value">{vender}</p>
              </div>
              <div className="item">
                <p className="text">ความก้าวหน้างานติดตั้ง:</p>
                <p className="value">{`${projectStatus}%`}</p>
              </div>
              <div className="item">
                <p className="text">สถานะการจัดเก็บเอกสาร:</p>
                <p className="value">{`${projectProgress}%`}</p>
              </div>
            </ProjectDescription>
          </Col>
        </Row>
      </Card>
      <Divider>Documents Upload</Divider>
      <Card bordered={false}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Collapse
              bordered={false}
              defaultActiveKey={["1"]}
              expandIconPosition="end"
            >
              <Panel header="1. Proposal - ข้อเสนอโครงการ" key="1">
                <UploadsList>
                  {downloadPS ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListPSMain}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadPS)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadPS)}
                      />
                    </div>
                  ) : null}
                  {fileListPSSub &&
                    fileListPSSub.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadPS2 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListPSMain2}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadPS2)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadPS2)}
                      />
                    </div>
                  ) : null}
                  {fileListPSSub2 &&
                    fileListPSSub2.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadPS3 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListPSMain3}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadPS3)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadPS3)}
                      />
                    </div>
                  ) : null}
                  {fileListPSSub3 &&
                    fileListPSSub3.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadPS4 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListPSMain4}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadPS4)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadPS4)}
                      />
                    </div>
                  ) : null}
                  {fileListPSSub4 &&
                    fileListPSSub4.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadPS5 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListPSMain5}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadPS5)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadPS5)}
                      />
                    </div>
                  ) : null}
                  {fileListPSSub5 &&
                    fileListPSSub5.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadPS6 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListPSMain6}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadPS6)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadPS6)}
                      />
                    </div>
                  ) : null}
                  {fileListPSSub6 &&
                    fileListPSSub6.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                </UploadsList>
              </Panel>
              <Panel
                header="2. Approval and Contract - การอนุมัติให้บริการและการทำสัญญาให้บริการ"
                key="2"
              >
                <UploadsList>
                  {downloadAC ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListACMain}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadAC)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadAC)}
                      />
                    </div>
                  ) : null}
                  {fileListACSub &&
                    fileListACSub.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadAC2 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListACMain2}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadAC2)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadAC2)}
                      />
                    </div>
                  ) : null}
                  {fileListACSub2 &&
                    fileListACSub2.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadAC3 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListACMain3}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadAC3)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadAC3)}
                      />
                    </div>
                  ) : null}
                  {fileListACSub3 &&
                    fileListACSub3.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadAC4 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListACMain4}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadAC4)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadAC4)}
                      />
                    </div>
                  ) : null}
                  {fileListACSub4 &&
                    fileListACSub4.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadAC5 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListACMain5}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadAC5)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadAC5)}
                      />
                    </div>
                  ) : null}
                  {fileListACSub5 &&
                    fileListACSub5.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadAC6 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListACMain6}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadAC6)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadAC6)}
                      />
                    </div>
                  ) : null}
                  {fileListACSub6 &&
                    fileListACSub6.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                </UploadsList>
              </Panel>
              <Panel
                header="3. Vender Procument - การจัดหา Vender & ทำสัญญาจ้างช่วง"
                key="3"
              >
                <UploadsList>
                  {downloadVP ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListVPMain}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadVP)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadVP)}
                      />
                    </div>
                  ) : null}
                  {fileListVPSub &&
                    fileListVPSub.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadVP2 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListVPMain2}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadVP2)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadVP2)}
                      />
                    </div>
                  ) : null}
                  {fileListVPSub2 &&
                    fileListVPSub2.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadVP3 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListVPMain3}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadVP3)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadVP3)}
                      />
                    </div>
                  ) : null}
                  {fileListVPSub3 &&
                    fileListVPSub3.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadVP4 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListVPMain4}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadVP4)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadVP4)}
                      />
                    </div>
                  ) : null}
                  {fileListVPSub4 &&
                    fileListVPSub4.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadVP5 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListVPMain5}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadVP5)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadVP5)}
                      />
                    </div>
                  ) : null}
                  {fileListVPSub5 &&
                    fileListVPSub5.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadVP6 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListVPMain6}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadVP6)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadVP6)}
                      />
                    </div>
                  ) : null}
                  {fileListVPSub6 &&
                    fileListVPSub6.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadVP7 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListVPMain7}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadVP7)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadVP7)}
                      />
                    </div>
                  ) : null}
                  {fileListVPSub7 &&
                    fileListVPSub7.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                </UploadsList>
              </Panel>
              <Panel
                header="4. Engineering and Construction - งานวิศวกรรมและการก่อสร้าง"
                key="4"
              >
                <UploadsList>
                  {downloadEC ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListECMain}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadEC)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadEC)}
                      />
                    </div>
                  ) : null}
                  {fileListECSub &&
                    fileListECSub.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadEC2 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListECMain2}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadEC2)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadEC2)}
                      />
                    </div>
                  ) : null}
                  {fileListECSub2 &&
                    fileListECSub2.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadEC3 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListECMain3}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadEC3)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadEC3)}
                      />
                    </div>
                  ) : null}
                  {fileListECSub3 &&
                    fileListECSub3.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadEC4 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListECMain4}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadEC4)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadEC4)}
                      />
                    </div>
                  ) : null}
                  {fileListECSub4 &&
                    fileListECSub4.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadEC5 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListECMain5}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadEC5)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadEC5)}
                      />
                    </div>
                  ) : null}
                  {fileListECSub5 &&
                    fileListECSub5.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadEC6 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListECMain6}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadEC6)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadEC6)}
                      />
                    </div>
                  ) : null}
                  {fileListECSub6 &&
                    fileListECSub6.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadEC7 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListECMain7}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadEC7)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadEC7)}
                      />
                    </div>
                  ) : null}
                  {fileListECSub7 &&
                    fileListECSub7.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadEC8 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListECMain8}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadEC8)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadEC8)}
                      />
                    </div>
                  ) : null}
                  {fileListECSub8 &&
                    fileListECSub8.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadEC9 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListECMain9}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadEC9)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadEC9)}
                      />
                    </div>
                  ) : null}
                  {fileListECSub9 &&
                    fileListECSub9.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadEC10 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListECMain10}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadEC10)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadEC10)}
                      />
                    </div>
                  ) : null}
                  {fileListECSub10 &&
                    fileListECSub10.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadEC11 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListECMain11}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadEC11)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadEC11)}
                      />
                    </div>
                  ) : null}
                  {fileListECSub11 &&
                    fileListECSub11.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                </UploadsList>
              </Panel>
              <Panel header="5. Test and Commissioning - การทดสอบระบบ" key="5">
                <UploadsList>
                  {downloadTC ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListTCMain}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadTC)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadTC)}
                      />
                    </div>
                  ) : null}
                  {fileListTCSub &&
                    fileListTCSub.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadTC2 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListTCMain2}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadTC2)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadTC2)}
                      />
                    </div>
                  ) : null}
                  {fileListTCSub2 &&
                    fileListTCSub2.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadTC3 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListTCMain3}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadTC3)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadTC3)}
                      />
                    </div>
                  ) : null}
                  {fileListTCSub3 &&
                    fileListTCSub3.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadTC4 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListTCMain4}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadTC4)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadTC4)}
                      />
                    </div>
                  ) : null}
                  {fileListTCSub4 &&
                    fileListTCSub4.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                </UploadsList>
              </Panel>
              <Panel
                header="6. Inspection and Approval - การตรวจรับงาน"
                key="6"
              >
                <UploadsList>
                  {downloadIA ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListIAMain}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadIA)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadIA)}
                      />
                    </div>
                  ) : null}
                  {fileListIASub &&
                    fileListIASub.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadIA2 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListIAMain2}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadIA2)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadIA2)}
                      />
                    </div>
                  ) : null}
                  {fileListIASub2 &&
                    fileListIASub2.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadIA3 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListIAMain3}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadIA3)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadIA3)}
                      />
                    </div>
                  ) : null}
                  {fileListIASub3 &&
                    fileListIASub3.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                </UploadsList>
              </Panel>
              <Panel
                header="7. Energy Management Billing - การจัดเก็บเงินค่าบริการ"
                key="7"
              >
                <UploadsList>
                  {downloadEB3 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListEBMain3}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadEB3)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadEB3)}
                      />
                    </div>
                  ) : null}
                  {fileListEBSub3 &&
                    fileListEBSub3.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {downloadEB4 ? (
                    <div className="item">
                      <div className="file">
                        <span className="name">{fileListEBMain4}</span>
                      </div>
                      <Button
                        type="text"
                        icon={<FileSearchOutlined />}
                        onClick={() => handlePreviewDocument(downloadEB4)}
                      />
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownloadDocument(downloadEB4)}
                      />
                    </div>
                  ) : null}
                  {fileListEBSub4 &&
                    fileListEBSub4.map((sub, index) => {
                      if (sub?.file) {
                        const file = `${id}/${sub.file}`
                        return (
                          <div className="item" key={index}>
                            <div className="file">
                              <span className="name">{sub.file}</span>
                            </div>
                            <Button
                              type="text"
                              icon={<FileSearchOutlined />}
                              onClick={() => handlePreviewDocument(file)}
                            />
                            <Button
                              type="text"
                              icon={<DownloadOutlined />}
                              onClick={() => handleDownloadDocument(file)}
                            />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                </UploadsList>
              </Panel>
            </Collapse>
          </Col>
          <Col span={24}>
            <Form.Item>
              <ButtonWrapper>
                <Link to="/backend/projects/all">
                  <Button type="secondary">Back to All Projects</Button>
                </Link>
              </ButtonWrapper>
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default ProjectViewID
