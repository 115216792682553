import React, { useState } from "react"
import app from "gatsby-plugin-firebase-v9.0"
import { getFirestore, collection, query, getDocs } from "firebase/firestore"
import { Link, navigate } from "gatsby"
import Fuse from "fuse.js"
import ProTable from "@ant-design/pro-table"
import { dateFormat } from "../../../utils/helper"
import moment from "moment"
import { AutoComplete, Button, Input, notification, Tooltip } from "antd"
import {
  ContainerOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons"
import styled from "styled-components"

const CompleteWrapper = styled.p`
  font-family: "Montserrat", "IBM Plex Sans Thai", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 0;
  strong {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }
`

const ProjectsPage = () => {
  const db = getFirestore(app)
  const isBrowser = typeof window !== "undefined"
  const storageUser = JSON.parse(localStorage.getItem("firebaseLocalStorage"))
  const [activeKey, setActiveKey] = useState("all")
  const [projectsSource, setProjectsSource] = useState([])

  const fuseOptions = {
    keys: ["projectCode", "projectName"],
  }
  const docOptions = {
    useExtendedSearch: true,
    keys: [
      "psDocument.main",
      "psDocument.main2",
      "psDocument.main3",
      "psDocument.main4",
      "psDocument.main5",
      "psDocument.main6",
      "psDocument.sub11",
      "psDocument.sub12",
      "psDocument.sub13",
      "psDocument.sub21",
      "psDocument.sub22",
      "psDocument.sub23",
      "psDocument.sub31",
      "psDocument.sub32",
      "psDocument.sub33",
      "psDocument.sub41",
      "psDocument.sub42",
      "psDocument.sub43",
      "psDocument.sub51",
      "psDocument.sub52",
      "psDocument.sub53",
      "psDocument.sub61",
      "psDocument.sub62",
      "psDocument.sub63",
      "acDocument.main",
      "acDocument.main2",
      "acDocument.main3",
      "acDocument.main4",
      "acDocument.main5",
      "acDocument.main6",
      "acDocument.sub11",
      "acDocument.sub12",
      "acDocument.sub13",
      "acDocument.sub21",
      "acDocument.sub22",
      "acDocument.sub23",
      "acDocument.sub31",
      "acDocument.sub32",
      "acDocument.sub33",
      "acDocument.sub41",
      "acDocument.sub42",
      "acDocument.sub43",
      "acDocument.sub51",
      "acDocument.sub52",
      "acDocument.sub53",
      "acDocument.sub61",
      "acDocument.sub62",
      "acDocument.sub63",
      "vpDocument.main",
      "vpDocument.main2",
      "vpDocument.main3",
      "vpDocument.main4",
      "vpDocument.main5",
      "vpDocument.main6",
      "vpDocument.main7",
      "vpDocument.sub11",
      "vpDocument.sub12",
      "vpDocument.sub13",
      "vpDocument.sub21",
      "vpDocument.sub22",
      "vpDocument.sub23",
      "vpDocument.sub31",
      "vpDocument.sub32",
      "vpDocument.sub33",
      "vpDocument.sub41",
      "vpDocument.sub42",
      "vpDocument.sub43",
      "vpDocument.sub51",
      "vpDocument.sub52",
      "vpDocument.sub53",
      "vpDocument.sub61",
      "vpDocument.sub62",
      "vpDocument.sub63",
      "vpDocument.sub71",
      "vpDocument.sub72",
      "vpDocument.sub73",
      "ecDocument.main",
      "ecDocument.main2",
      "ecDocument.main3",
      "ecDocument.main4",
      "ecDocument.main5",
      "ecDocument.main6",
      "ecDocument.main7",
      "ecDocument.main8",
      "ecDocument.main9",
      "ecDocument.main10",
      "ecDocument.main11",
      "ecDocument.sub11",
      "ecDocument.sub12",
      "ecDocument.sub13",
      "ecDocument.sub21",
      "ecDocument.sub22",
      "ecDocument.sub23",
      "ecDocument.sub31",
      "ecDocument.sub32",
      "ecDocument.sub33",
      "ecDocument.sub41",
      "ecDocument.sub42",
      "ecDocument.sub43",
      "ecDocument.sub51",
      "ecDocument.sub52",
      "ecDocument.sub53",
      "ecDocument.sub61",
      "ecDocument.sub62",
      "ecDocument.sub63",
      "ecDocument.sub71",
      "ecDocument.sub72",
      "ecDocument.sub73",
      "ecDocument.sub81",
      "ecDocument.sub82",
      "ecDocument.sub83",
      "ecDocument.sub91",
      "ecDocument.sub92",
      "ecDocument.sub93",
      "ecDocument.sub101",
      "ecDocument.sub102",
      "ecDocument.sub103",
      "ecDocument.sub111",
      "ecDocument.sub112",
      "ecDocument.sub113",
      "tcDocument.main",
      "tcDocument.main2",
      "tcDocument.main3",
      "tcDocument.main4",
      "tcDocument.sub11",
      "tcDocument.sub12",
      "tcDocument.sub13",
      "tcDocument.sub21",
      "tcDocument.sub22",
      "tcDocument.sub23",
      "tcDocument.sub31",
      "tcDocument.sub32",
      "tcDocument.sub33",
      "tcDocument.sub41",
      "tcDocument.sub42",
      "tcDocument.sub43",
      "iaDocument.main",
      "iaDocument.main2",
      "iaDocument.main3",
      "iaDocument.sub11",
      "iaDocument.sub12",
      "iaDocument.sub13",
      "iaDocument.sub21",
      "iaDocument.sub22",
      "iaDocument.sub23",
      "iaDocument.sub31",
      "iaDocument.sub32",
      "iaDocument.sub33",
      "ebDocument.main3",
      "ebDocument.main4",
      "ebDocument.sub31",
      "ebDocument.sub32",
      "ebDocument.sub33",
      "ebDocument.sub41",
      "ebDocument.sub42",
      "ebDocument.sub43",
    ],
  }

  const columns = [
    {
      title: "รหัสโครงการ",
      dataIndex: "projectCode",
      filters: true,
      sorter: true,
    },
    {
      title: "ชื่อโครงการ",
      dataIndex: "projectName",
      filters: true,
      sorter: true,
    },
    {
      title: "ความก้าวหน้างานติดตั้ง",
      dataIndex: "projectStatus",
      hideInSearch: true,
    },
    {
      title: "สถานะการจัดเก็บเอกสาร",
      dataIndex: "projectProgress",
      hideInSearch: true,
    },
    {
      title: "สัญญาหลัก",
      dataIndex: "mainDate",
      hideInSearch: true,
    },
    {
      title: "สัญญาช่วง",
      dataIndex: "subcontractDate",
      hideInSearch: true,
    },
    {
      title: "Manage",
      valueType: "option",
      width: 80,
      align: "center",
      fixed: "right",
      render: (_, record) => {
        if (
          storageUser?.role === "system_admin" ||
          storageUser?.role === "dept_user"
        ) {
          return [
            <Link to={`/backend/projects/view/${record.id}`} key="1">
              <Tooltip title="รายละเอียด">
                <ContainerOutlined />
              </Tooltip>
            </Link>,
            <Link to={`/backend/projects/${record.id}`} key="2">
              <Tooltip title="แก้ไข">
                <EditOutlined />
              </Tooltip>
            </Link>,
          ]
        } else {
          return (
            <Link to={`/backend/projects/view/${record.id}`} key="view">
              <Tooltip title="รายละเอียด">
                <ContainerOutlined />
              </Tooltip>
            </Link>
          )
        }
      },
    },
  ]

  async function fetchCollection(params) {
    let result = []
    let list = []
    try {
      if (isBrowser) {
        const q = query(collection(db, "projects"))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach(doc => {
          const data = doc.data()
          const projectStatus = data?.projectStatus ? data?.projectStatus : 0
          const projectProgress = data?.projectProgress
            ? data?.projectProgress
            : 0
          const append = {
            id: doc?.id,
            projectCode: data?.projectCode,
            projectName: data?.projectName,
            projectStatus: projectStatus + "%",
            projectProgress: projectProgress + "%",
            mainDate:
              data?.mainStartDate && data?.mainEndDate
                ? moment(data?.mainStartDate.toDate()).format(dateFormat) +
                  " - " +
                  moment(data?.mainEndDate.toDate()).format(dateFormat)
                : "",
            subcontractDate:
              data?.subcontractStartDate && data?.subcontractEndDate
                ? moment(data?.subcontractStartDate.toDate()).format(
                    dateFormat
                  ) +
                  " - " +
                  moment(data?.subcontractEndDate.toDate()).format(dateFormat)
                : "",
          }
          list.push(append)
        })
        const fuse = new Fuse(list, fuseOptions)
        if (fuse) {
          let fuseData = null
          if (params?.projectCode || params?.projectName) {
            if (
              params?.projectCode?.length > 0 &&
              params?.projectName?.length > 0
            ) {
              fuseData = fuse.search({
                $and: [
                  { projectCode: params.projectCode },
                  { projectName: params.projectName },
                ],
              })
            } else {
              if (params?.projectCode?.length > 0) {
                fuseData = fuse.search({ projectCode: params.projectCode })
              }
              if (params?.projectName?.length > 0) {
                fuseData = fuse.search({ projectName: params.projectName })
              }
            }
            if (fuseData) {
              fuseData.map(row => {
                const {
                  id,
                  projectCode,
                  projectName,
                  projectStatus,
                  projectProgress,
                  mainDate,
                  subcontractDate,
                } = row.item
                const append = {
                  id: id,
                  projectCode: projectCode,
                  projectName: projectName,
                  projectStatus: projectStatus,
                  projectProgress: projectProgress,
                  mainDate: mainDate,
                  subcontractDate: subcontractDate,
                }
                result.push(append)
                return null
              })
            }
          } else {
            result = list
          }
        }
      }
    } catch (error) {
      console.log("error ", error)
      notification["error"]({
        message: error.code || error.name || error.status,
        description: error.error_description || error.message,
        duration: 0,
      })
    }
    return result
  }

  const handleTabChange = key => {
    if (key === "progressing") {
      navigate("/backend/projects/progressing")
    } else if (key === "finished") {
      navigate("/backend/projects/finished")
    } else {
      setActiveKey(key)
    }
  }

  async function searchDocuments(value) {
    if (value && value.length > 0) {
      try {
        if (isBrowser) {
          let list = []
          const q = query(collection(db, "projects"))
          const querySnapshot = await getDocs(q)
          querySnapshot.forEach(doc => {
            const data = doc.data()
            let append = {
              id: doc?.id,
              projectCode: data?.projectCode,
              projectName: data?.projectName,
              psDocument: {
                main: data?.psDocument?.main,
                main2: data?.psDocument?.main2,
                main3: data?.psDocument?.main3,
                main4: data?.psDocument?.main4,
                main5: data?.psDocument?.main5,
                main6: data?.psDocument?.main6,
                sub11:
                  data?.psDocument?.sub?.length > 0
                    ? data.psDocument.sub[0].file
                    : "",
                sub12:
                  data?.psDocument?.sub?.length > 1
                    ? data.psDocument.sub[1].file
                    : "",
                sub13:
                  data?.psDocument?.sub?.length > 2
                    ? data.psDocument.sub[2].file
                    : "",
                sub21:
                  data?.psDocument?.sub2?.length > 0
                    ? data.psDocument.sub2[0].file
                    : "",
                sub22:
                  data?.psDocument?.sub2?.length > 1
                    ? data.psDocument.sub2[1].file
                    : "",
                sub23:
                  data?.psDocument?.sub2?.length > 2
                    ? data.psDocument.sub2[2].file
                    : "",
                sub31:
                  data?.psDocument?.sub3?.length > 0
                    ? data.psDocument.sub3[0].file
                    : "",
                sub32:
                  data?.psDocument?.sub3?.length > 1
                    ? data.psDocument.sub3[1].file
                    : "",
                sub33:
                  data?.psDocument?.sub3?.length > 2
                    ? data.psDocument.sub3[2].file
                    : "",
                sub41:
                  data?.psDocument?.sub4?.length > 0
                    ? data.psDocument.sub4[0].file
                    : "",
                sub42:
                  data?.psDocument?.sub4?.length > 1
                    ? data.psDocument.sub4[1].file
                    : "",
                sub43:
                  data?.psDocument?.sub4?.length > 2
                    ? data.psDocument.sub4[2].file
                    : "",
                sub51:
                  data?.psDocument?.sub5?.length > 0
                    ? data.psDocument.sub5[0].file
                    : "",
                sub52:
                  data?.psDocument?.sub5?.length > 1
                    ? data.psDocument.sub5[1].file
                    : "",
                sub53:
                  data?.psDocument?.sub5?.length > 2
                    ? data.psDocument.sub5[2].file
                    : "",
                sub61:
                  data?.psDocument?.sub6?.length > 0
                    ? data.psDocument.sub6[0].file
                    : "",
                sub62:
                  data?.psDocument?.sub6?.length > 1
                    ? data.psDocument.sub6[1].file
                    : "",
                sub63:
                  data?.psDocument?.sub6?.length > 2
                    ? data.psDocument.sub6[2].file
                    : "",
              },
              acDocument: {
                main: data?.acDocument?.main,
                main2: data?.acDocument?.main2,
                main3: data?.acDocument?.main3,
                main4: data?.acDocument?.main4,
                main5: data?.acDocument?.main5,
                main6: data?.acDocument?.main6,
                sub11:
                  data?.acDocument?.sub?.length > 0
                    ? data.acDocument.sub[0].file
                    : "",
                sub12:
                  data?.acDocument?.sub?.length > 1
                    ? data.acDocument.sub[1].file
                    : "",
                sub13:
                  data?.acDocument?.sub?.length > 2
                    ? data.acDocument.sub[2].file
                    : "",
                sub21:
                  data?.acDocument?.sub2?.length > 0
                    ? data.acDocument.sub2[0].file
                    : "",
                sub22:
                  data?.acDocument?.sub2?.length > 1
                    ? data.acDocument.sub2[1].file
                    : "",
                sub23:
                  data?.acDocument?.sub2?.length > 2
                    ? data.acDocument.sub2[2].file
                    : "",
                sub31:
                  data?.acDocument?.sub3?.length > 0
                    ? data.acDocument.sub3[0].file
                    : "",
                sub32:
                  data?.acDocument?.sub3?.length > 1
                    ? data.acDocument.sub3[1].file
                    : "",
                sub33:
                  data?.acDocument?.sub3?.length > 2
                    ? data.acDocument.sub3[2].file
                    : "",
                sub41:
                  data?.acDocument?.sub4?.length > 0
                    ? data.acDocument.sub4[0].file
                    : "",
                sub42:
                  data?.acDocument?.sub4?.length > 1
                    ? data.acDocument.sub4[1].file
                    : "",
                sub43:
                  data?.acDocument?.sub4?.length > 2
                    ? data.acDocument.sub4[2].file
                    : "",
                sub51:
                  data?.acDocument?.sub5?.length > 0
                    ? data.acDocument.sub5[0].file
                    : "",
                sub52:
                  data?.acDocument?.sub5?.length > 1
                    ? data.acDocument.sub5[1].file
                    : "",
                sub53:
                  data?.acDocument?.sub5?.length > 2
                    ? data.acDocument.sub5[2].file
                    : "",
                sub61:
                  data?.acDocument?.sub6?.length > 0
                    ? data.acDocument.sub6[0].file
                    : "",
                sub62:
                  data?.acDocument?.sub6?.length > 1
                    ? data.acDocument.sub6[1].file
                    : "",
                sub63:
                  data?.acDocument?.sub6?.length > 2
                    ? data.acDocument.sub6[2].file
                    : "",
              },
              vpDocument: {
                main: data?.vpDocument?.main,
                main2: data?.vpDocument?.main2,
                main3: data?.vpDocument?.main3,
                main4: data?.vpDocument?.main4,
                main5: data?.vpDocument?.main5,
                main6: data?.vpDocument?.main6,
                main7: data?.vpDocument?.main7,
                sub11:
                  data?.vpDocument?.sub?.length > 0
                    ? data.vpDocument.sub[0].file
                    : "",
                sub12:
                  data?.vpDocument?.sub?.length > 1
                    ? data.vpDocument.sub[1].file
                    : "",
                sub13:
                  data?.vpDocument?.sub?.length > 2
                    ? data.vpDocument.sub[2].file
                    : "",
                sub21:
                  data?.vpDocument?.sub2?.length > 0
                    ? data.vpDocument.sub2[0].file
                    : "",
                sub22:
                  data?.vpDocument?.sub2?.length > 1
                    ? data.vpDocument.sub2[1].file
                    : "",
                sub23:
                  data?.vpDocument?.sub2?.length > 2
                    ? data.vpDocument.sub2[2].file
                    : "",
                sub31:
                  data?.vpDocument?.sub3?.length > 0
                    ? data.vpDocument.sub3[0].file
                    : "",
                sub32:
                  data?.vpDocument?.sub3?.length > 1
                    ? data.vpDocument.sub3[1].file
                    : "",
                sub33:
                  data?.vpDocument?.sub3?.length > 2
                    ? data.vpDocument.sub3[2].file
                    : "",
                sub41:
                  data?.vpDocument?.sub4?.length > 0
                    ? data.vpDocument.sub4[0].file
                    : "",
                sub42:
                  data?.vpDocument?.sub4?.length > 1
                    ? data.vpDocument.sub4[1].file
                    : "",
                sub43:
                  data?.vpDocument?.sub4?.length > 2
                    ? data.vpDocument.sub4[2].file
                    : "",
                sub51:
                  data?.vpDocument?.sub5?.length > 0
                    ? data.vpDocument.sub5[0].file
                    : "",
                sub52:
                  data?.vpDocument?.sub5?.length > 1
                    ? data.vpDocument.sub5[1].file
                    : "",
                sub53:
                  data?.vpDocument?.sub5?.length > 2
                    ? data.vpDocument.sub5[2].file
                    : "",
                sub61:
                  data?.vpDocument?.sub6?.length > 0
                    ? data.vpDocument.sub6[0].file
                    : "",
                sub62:
                  data?.vpDocument?.sub6?.length > 1
                    ? data.vpDocument.sub6[1].file
                    : "",
                sub63:
                  data?.vpDocument?.sub6?.length > 2
                    ? data.vpDocument.sub6[2].file
                    : "",
                sub71:
                  data?.vpDocument?.sub7?.length > 0
                    ? data.vpDocument.sub7[0].file
                    : "",
                sub72:
                  data?.vpDocument?.sub7?.length > 1
                    ? data.vpDocument.sub7[1].file
                    : "",
                sub73:
                  data?.vpDocument?.sub7?.length > 2
                    ? data.vpDocument.sub7[2].file
                    : "",
              },
              ecDocument: {
                main: data?.ecDocument?.main,
                main2: data?.ecDocument?.main2,
                main3: data?.ecDocument?.main3,
                main4: data?.ecDocument?.main4,
                main5: data?.ecDocument?.main5,
                main6: data?.ecDocument?.main6,
                main7: data?.ecDocument?.main7,
                main8: data?.ecDocument?.main8,
                main9: data?.ecDocument?.main9,
                main10: data?.ecDocument?.main10,
                main11: data?.ecDocument?.main11,
                sub11:
                  data?.ecDocument?.sub?.length > 0
                    ? data.ecDocument.sub[0].file
                    : "",
                sub12:
                  data?.ecDocument?.sub?.length > 1
                    ? data.ecDocument.sub[1].file
                    : "",
                sub13:
                  data?.ecDocument?.sub?.length > 2
                    ? data.ecDocument.sub[2].file
                    : "",
                sub21:
                  data?.ecDocument?.sub2?.length > 0
                    ? data.ecDocument.sub2[0].file
                    : "",
                sub22:
                  data?.ecDocument?.sub2?.length > 1
                    ? data.ecDocument.sub2[1].file
                    : "",
                sub23:
                  data?.ecDocument?.sub2?.length > 2
                    ? data.ecDocument.sub2[2].file
                    : "",
                sub31:
                  data?.ecDocument?.sub3?.length > 0
                    ? data.ecDocument.sub3[0].file
                    : "",
                sub32:
                  data?.ecDocument?.sub3?.length > 1
                    ? data.ecDocument.sub3[1].file
                    : "",
                sub33:
                  data?.ecDocument?.sub3?.length > 2
                    ? data.ecDocument.sub3[2].file
                    : "",
                sub41:
                  data?.ecDocument?.sub4?.length > 0
                    ? data.ecDocument.sub4[0].file
                    : "",
                sub42:
                  data?.ecDocument?.sub4?.length > 1
                    ? data.ecDocument.sub4[1].file
                    : "",
                sub43:
                  data?.ecDocument?.sub4?.length > 2
                    ? data.ecDocument.sub4[2].file
                    : "",
                sub51:
                  data?.ecDocument?.sub5?.length > 0
                    ? data.ecDocument.sub5[0].file
                    : "",
                sub52:
                  data?.ecDocument?.sub5?.length > 1
                    ? data.ecDocument.sub5[1].file
                    : "",
                sub53:
                  data?.ecDocument?.sub5?.length > 2
                    ? data.ecDocument.sub5[2].file
                    : "",
                sub61:
                  data?.ecDocument?.sub6?.length > 0
                    ? data.ecDocument.sub6[0].file
                    : "",
                sub62:
                  data?.ecDocument?.sub6?.length > 1
                    ? data.ecDocument.sub6[1].file
                    : "",
                sub63:
                  data?.ecDocument?.sub6?.length > 2
                    ? data.ecDocument.sub6[2].file
                    : "",
                sub71:
                  data?.ecDocument?.sub7?.length > 0
                    ? data.ecDocument.sub7[0].file
                    : "",
                sub72:
                  data?.ecDocument?.sub7?.length > 1
                    ? data.ecDocument.sub7[1].file
                    : "",
                sub73:
                  data?.ecDocument?.sub7?.length > 2
                    ? data.ecDocument.sub7[2].file
                    : "",
                sub81:
                  data?.ecDocument?.sub8?.length > 0
                    ? data.ecDocument.sub8[0].file
                    : "",
                sub82:
                  data?.ecDocument?.sub8?.length > 1
                    ? data.ecDocument.sub8[1].file
                    : "",
                sub83:
                  data?.ecDocument?.sub8?.length > 2
                    ? data.ecDocument.sub8[2].file
                    : "",
                sub91:
                  data?.ecDocument?.sub9?.length > 0
                    ? data.ecDocument.sub9[0].file
                    : "",
                sub92:
                  data?.ecDocument?.sub9?.length > 1
                    ? data.ecDocument.sub9[1].file
                    : "",
                sub93:
                  data?.ecDocument?.sub9?.length > 2
                    ? data.ecDocument.sub9[2].file
                    : "",
                sub101:
                  data?.ecDocument?.sub10?.length > 0
                    ? data.ecDocument.sub10[0].file
                    : "",
                sub102:
                  data?.ecDocument?.sub10?.length > 1
                    ? data.ecDocument.sub10[1].file
                    : "",
                sub103:
                  data?.ecDocument?.sub10?.length > 2
                    ? data.ecDocument.sub10[2].file
                    : "",
                sub111:
                  data?.ecDocument?.sub11?.length > 0
                    ? data.ecDocument.sub11[0].file
                    : "",
                sub112:
                  data?.ecDocument?.sub11?.length > 1
                    ? data.ecDocument.sub11[1].file
                    : "",
                sub113:
                  data?.ecDocument?.sub11?.length > 2
                    ? data.ecDocument.sub11[2].file
                    : "",
              },
              tcDocument: {
                main: data?.tcDocument?.main,
                main2: data?.tcDocument?.main2,
                main3: data?.tcDocument?.main3,
                main4: data?.tcDocument?.main4,
                sub11:
                  data?.tcDocument?.sub?.length > 0
                    ? data.tcDocument.sub[0].file
                    : "",
                sub12:
                  data?.tcDocument?.sub?.length > 1
                    ? data.tcDocument.sub[1].file
                    : "",
                sub13:
                  data?.tcDocument?.sub?.length > 2
                    ? data.tcDocument.sub[2].file
                    : "",
                sub21:
                  data?.tcDocument?.sub2?.length > 0
                    ? data.tcDocument.sub2[0].file
                    : "",
                sub22:
                  data?.tcDocument?.sub2?.length > 1
                    ? data.tcDocument.sub2[1].file
                    : "",
                sub23:
                  data?.tcDocument?.sub2?.length > 2
                    ? data.tcDocument.sub2[2].file
                    : "",
                sub31:
                  data?.tcDocument?.sub3?.length > 0
                    ? data.tcDocument.sub3[0].file
                    : "",
                sub32:
                  data?.tcDocument?.sub3?.length > 1
                    ? data.tcDocument.sub3[1].file
                    : "",
                sub33:
                  data?.tcDocument?.sub3?.length > 2
                    ? data.tcDocument.sub3[2].file
                    : "",
                sub41:
                  data?.tcDocument?.sub4?.length > 0
                    ? data.tcDocument.sub4[0].file
                    : "",
                sub42:
                  data?.tcDocument?.sub4?.length > 1
                    ? data.tcDocument.sub4[1].file
                    : "",
                sub43:
                  data?.tcDocument?.sub4?.length > 2
                    ? data.tcDocument.sub4[2].file
                    : "",
              },
              iaDocument: {
                main: data?.iaDocument?.main,
                main2: data?.iaDocument?.main2,
                main3: data?.iaDocument?.main3,
                sub11:
                  data?.iaDocument?.sub?.length > 0
                    ? data.iaDocument.sub[0].file
                    : "",
                sub12:
                  data?.iaDocument?.sub?.length > 1
                    ? data.iaDocument.sub[1].file
                    : "",
                sub13:
                  data?.iaDocument?.sub?.length > 2
                    ? data.iaDocument.sub[2].file
                    : "",
                sub21:
                  data?.iaDocument?.sub2?.length > 0
                    ? data.iaDocument.sub2[0].file
                    : "",
                sub22:
                  data?.iaDocument?.sub2?.length > 1
                    ? data.iaDocument.sub2[1].file
                    : "",
                sub23:
                  data?.iaDocument?.sub2?.length > 2
                    ? data.iaDocument.sub2[2].file
                    : "",
                sub31:
                  data?.iaDocument?.sub3?.length > 0
                    ? data.iaDocument.sub3[0].file
                    : "",
                sub32:
                  data?.iaDocument?.sub3?.length > 1
                    ? data.iaDocument.sub3[1].file
                    : "",
                sub33:
                  data?.iaDocument?.sub3?.length > 2
                    ? data.iaDocument.sub3[2].file
                    : "",
              },
              ebDocument: {
                main3: data?.ebDocument?.main3,
                main4: data?.ebDocument?.main4,
                sub31:
                  data?.ebDocument?.sub3?.length > 0
                    ? data.ebDocument.sub3[0].file
                    : "",
                sub32:
                  data?.ebDocument?.sub3?.length > 1
                    ? data.ebDocument.sub3[1].file
                    : "",
                sub33:
                  data?.ebDocument?.sub3?.length > 2
                    ? data.ebDocument.sub3[2].file
                    : "",
                sub41:
                  data?.ebDocument?.sub4?.length > 0
                    ? data.ebDocument.sub4[0].file
                    : "",
                sub42:
                  data?.ebDocument?.sub4?.length > 1
                    ? data.ebDocument.sub4[1].file
                    : "",
                sub43:
                  data?.ebDocument?.sub4?.length > 2
                    ? data.ebDocument.sub4[2].file
                    : "",
              },
            }
            list.push(append)
          })
          const fuse = new Fuse(list, docOptions)
          if (fuse) {
            const fuseData = fuse.search(`'${value}`)
            if (fuseData && fuseData.length > 0) {
              const result = fuseData.map(row => {
                const { id, projectCode, projectName } = row.item
                const append = {
                  value: id,
                  label: (
                    <CompleteWrapper>
                      <strong>{projectCode}</strong>: {projectName}
                    </CompleteWrapper>
                  ),
                }
                return append
              })
              setProjectsSource(result)
            } else {
              setProjectsSource([])
            }
          } else {
            setProjectsSource([])
          }
        }
      } catch (error) {
        console.log("error ", error)
        setProjectsSource([])
      }
    } else {
      setProjectsSource([])
    }
  }

  const handleDocumentsSearch = async value => {
    searchDocuments(value)
  }
  const onDocumentsSelect = value => {
    if (value && value.length > 0) {
      navigate(`/backend/projects/view/${value}`)
    }
  }

  return (
    <ProTable
      columns={columns}
      request={async params => {
        const dataSource = await fetchCollection(params)
        return {
          data: dataSource,
          success: true,
        }
      }}
      rowKey="id"
      search={{
        labelWidth: 120,
      }}
      toolbar={{
        multipleLine: true,
        menu: {
          type: "tab",
          activeKey: activeKey,
          items: [
            {
              key: "all",
              label: "All",
            },
            {
              key: "progressing",
              label: "Progressing",
            },
            {
              key: "finished",
              label: "Finished",
            },
          ],
          onChange: key => {
            handleTabChange(key)
          },
        },
        actions:
          storageUser?.role === "system_admin" ||
          storageUser?.role === "dept_user"
            ? [
                <AutoComplete
                  dropdownMatchSelectWidth={222}
                  style={{
                    width: 270,
                  }}
                  options={projectsSource}
                  onSelect={onDocumentsSelect}
                  onSearch={handleDocumentsSearch}
                >
                  <Input.Search
                    size="medium"
                    placeholder="Search files here."
                    enterButton
                  />
                </AutoComplete>,
                <Link to="/backend/projects/create">
                  <Button key="button" icon={<PlusOutlined />} type="primary">
                    Create
                  </Button>
                </Link>,
              ]
            : [
                <AutoComplete
                  dropdownMatchSelectWidth={222}
                  style={{
                    width: 270,
                  }}
                  options={projectsSource}
                  onSelect={onDocumentsSelect}
                  onSearch={handleDocumentsSearch}
                >
                  <Input.Search
                    size="medium"
                    placeholder="Search files here."
                    enterButton
                  />
                </AutoComplete>,
              ],
      }}
      pagination={{
        pageSize: 20,
        showSizeChanger: false,
      }}
      dateFormatter="string"
    />
  )
}

export default ProjectsPage
