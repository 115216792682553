import React, { useEffect, useState, useRef } from "react"
import app from "gatsby-plugin-firebase-v9.0"
import {
  getFirestore,
  collection,
  query,
  getDocs,
  getDoc,
  doc,
  addDoc,
  setDoc,
  Timestamp,
} from "firebase/firestore"
import { Link, navigate } from "gatsby"
import Fuse from "fuse.js"
import ProTable from "@ant-design/pro-table"
import { message, notification, Popconfirm, Tooltip } from "antd"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"

const UsersPage = () => {
  const db = getFirestore(app)
  const isBrowser = typeof window !== "undefined"
  const storageUser = JSON.parse(localStorage.getItem("firebaseLocalStorage"))
  const [canProcess, setCanProcess] = useState(false)
  const tableRef = useRef(null)

  const fuseOptions = {
    keys: ["firstName", "lastName", "email", "line", "role"],
  }

  useEffect(() => {
    if (isBrowser) {
      if (storageUser?.role === "system_admin") {
        fetchDocument()
      } else {
        navigate("/backend/dashboard")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function fetchDocument() {
    const docRef = doc(db, "users", storageUser?.uid)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      const data = docSnap.data()
      if (data?.role === "system_admin") {
        setCanProcess(true)
      } else {
        setCanProcess(false)
      }
    } else {
      setCanProcess(false)
    }
  }

  const confirmRemove = async uid => {
    if (canProcess && uid) {
      try {
        const dataUser = {
          deleted: true,
        }
        const docRef = doc(db, "users", uid)
        await setDoc(docRef, dataUser, { merge: true })
        const dataLog = {
          uid: uid,
          type: "Users",
          action: "Delete",
          by: storageUser?.uid,
          createdAt: Timestamp.now(),
        }
        await addDoc(collection(db, "logs"), dataLog)
        message.success("The user was deleted.")
        tableRef.current.reload()
      } catch (error) {
        console.log("error ", error)
        notification["error"]({
          message: error.code || error.name || error.status,
          description: error.error_description || error.message,
          duration: 0,
        })
      }
    }
  }
  const renderRemoveUser = (name, uid) => (
    <Popconfirm
      key="popconfirm"
      placement="topRight"
      title={`Are you sure to remove ${name} ?`}
      okText="Ok"
      cancelText="Cancel"
      onConfirm={() => confirmRemove(uid)}
    >
      <a href="#void">
        <Tooltip title="ลบ">
          <DeleteOutlined />
        </Tooltip>
      </a>
    </Popconfirm>
  )
  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      filters: true,
      sorter: true,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      filters: true,
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      filters: true,
    },
    {
      title: "Line",
      dataIndex: "line",
      filters: true,
    },
    {
      title: "Role",
      dataIndex: "role",
      filters: true,
      onFilter: true,
      ellipsis: true,
      valueType: "select",
      valueEnum: {
        subscriber: {
          text: "Subscriber",
        },
        general_user: {
          text: "General User",
        },
        dept_user: {
          text: "Dept. User",
        },
        system_admin: {
          text: "System Admin",
        },
      },
    },
    {
      title: "Manage",
      valueType: "option",
      width: 80,
      align: "center",
      fixed: "right",
      render: (_, record) => {
        let name = record.email
        if (record.firstName) {
          name = `"${record.firstName} ${record.lastName}"`
        }
        const node = renderRemoveUser(name, record.uid)
        return [
          <Link to={`/backend/users/${record.uid}`} key="1">
            <Tooltip title="แก้ไข">
              <EditOutlined />
            </Tooltip>
          </Link>,
          node,
        ]
      },
    },
  ]

  async function fetchCollection(params) {
    let result = []
    let list = []
    try {
      if (isBrowser) {
        const q = query(collection(db, "users"))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach(doc => {
          const data = doc.data()
          if (data && data?.deleted !== true) {
            const { uid, firstName, lastName, email, line, role } = data
            const append = {
              uid: uid,
              firstName: firstName,
              lastName: lastName,
              email: email,
              line: line,
              role: role,
            }
            list.push(append)
          }
        })
        const fuse = new Fuse(list, fuseOptions)
        if (fuse) {
          if (
            params?.firstName ||
            params?.lastName ||
            params?.email ||
            params?.line ||
            params?.role
          ) {
            let fuseConditions = []
            if (params?.firstName?.length > 0) {
              fuseConditions.push({ firstName: params?.firstName })
            }
            if (params?.lastName?.length > 0) {
              fuseConditions.push({ lastName: params?.lastName })
            }
            if (params?.email?.length > 0) {
              fuseConditions.push({ email: params?.email })
            }
            if (params?.line?.length > 0) {
              fuseConditions.push({ line: params?.line })
            }
            if (params?.role?.length > 0) {
              fuseConditions.push({ role: params?.role })
            }
            const fuseData = fuse.search({
              $and: fuseConditions,
            })
            if (fuseData) {
              fuseData.map(row => {
                const { uid, firstName, lastName, email, line, role } = row.item
                const append = {
                  uid: uid,
                  firstName: firstName,
                  lastName: lastName,
                  email: email,
                  line: line,
                  role: role,
                }
                result.push(append)
                return null
              })
            }
          } else {
            result = list
          }
        }
      }
    } catch (error) {
      console.log("error ", error)
      notification["error"]({
        message: error.code || error.name || error.status,
        description: error.error_description || error.message,
        duration: 0,
      })
    }
    return result
  }

  return (
    <ProTable
      actionRef={tableRef}
      columns={columns}
      request={async params => {
        const dataSource = await fetchCollection(params)
        return {
          data: dataSource,
          success: true,
        }
      }}
      rowKey="uid"
      search={{
        labelWidth: 120,
      }}
      toolbar={{
        title: "Users",
      }}
      pagination={{
        pageSize: 10,
        showSizeChanger: false,
      }}
      dateFormatter="string"
    />
  )
}

export default UsersPage
